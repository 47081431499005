import {useState} from "react";
import {login} from "./api";
import {useNavigate} from "react-router-dom";
import TextEyeIcon from "./components/TextEyeIcon";
import {useTranslation} from "react-i18next";
import {useRefreshUser} from "./hooks";

const Login = () => {

    const [form, setForm] = useState({username: '', password: ''})
    const {t} = useTranslation()
    const {refresh} = useRefreshUser()
    const navigate = useNavigate()

    const [inputType, setInputType] = useState("password")

    const loginAction = async () => {
        const res: any = await login(form)
        if (res.code === 200) {
            refresh()
            setTimeout(()=>{
                navigate('/trade/time-contract')
            },200)
        }
    }

    return <div id="trade-app-root" className="trade-app-root font-primary leading-normal text-primary">
        <div className="fixed top-16 right-4 z-max flex flex-col items-end"></div>
        <div className="relative h-screen flex-center">
            <div className="flex items-center gap-1.5 z-10 absolute top-4 left-6 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="31" viewBox="0 0 29 31" fill="none"
                     className="w-7 h-8">
                    <path
                        d="M5.0273 8.4475C5.98397 8.03737 7.05054 7.84026 8.14296 7.79893V7.78939H21.0885C21.0885 7.78939 21.0885 14.7479 21.0885 18.153C21.0885 18.8467 21.0995 20.1423 20.7632 20.9712C20.427 21.8001 19.8387 22.2393 19.8387 22.2393H21.0885H21.0917C25.1544 22.2393 26.9287 19.9152 27.1937 19.5305C28.1827 17.9218 28.6643 16.2686 28.7677 14.1925C28.9067 11.3788 28.831 2.02187 28.831 0.505562V0.000126779H28.1666C27.7638 0.000126779 24.9682 0.000126497 22.9187 0.0108932C20.8638 0.0108932 19.0268 0.0387392 17.5061 0.0732499C14.106 0.146374 12.4027 0.788591 9.65877 3.11583C7.94903 4.56559 5.04346 7.49371 3.5729 9.14694C3.19152 9.57615 2.85216 9.99582 2.54835 10.4155C3.24646 9.48077 4.01568 8.87988 5.0273 8.44432V8.4475Z"
                        fill="url(#paint0_linear_2280_5766)"></path>
                    <path
                        d="M24.5196 22.5525C23.5629 22.9626 22.4963 23.1597 21.4039 23.2011V23.2106H8.45839C8.45839 23.2106 8.45839 16.2521 8.45839 12.847C8.45839 12.1533 8.44742 10.8577 8.78363 10.0288C9.11985 9.19988 9.70822 8.76074 9.70822 8.76074H8.45839H8.45516C4.39252 8.76074 2.61815 11.0848 2.35313 11.4695C1.36414 13.0782 0.882566 14.7314 0.779142 16.8075C0.640165 19.6212 0.71592 28.9781 0.715921 30.4944V30.9999H1.3803C1.78307 30.9999 4.5787 30.9999 6.62821 30.9891C8.6831 30.9891 10.52 30.9613 12.0408 30.9268C15.4409 30.8536 17.1441 30.2114 19.8881 27.8842C21.5978 26.4344 24.5034 23.5063 25.974 21.8531C26.3554 21.4239 26.6947 21.0042 26.9985 20.5845C26.3004 21.5192 25.5312 22.1201 24.5196 22.5557V22.5525Z"
                        fill="url(#paint1_linear_2280_5766)"></path>
                    <defs>
                        <linearGradient id="paint0_linear_2280_5766" x1="22.3344" y1="24.067" x2="1.51585" y2="-4.21042"
                                        gradientUnits="userSpaceOnUse">
                            <stop offset="0.0350515" stopColor="#00FFA8"></stop>
                            <stop offset="0.765728" stopColor="#32A2E7"></stop>
                        </linearGradient>
                        <linearGradient id="paint1_linear_2280_5766" x1="2.35744" y1="10.0288" x2="14.2868" y2="31.5698"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#3700FF"></stop>
                            <stop offset="1" stopColor="#8779FF"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="106" height="21" viewBox="0 0 106 21" fill="#fff"
                     className="">
                    <g clipPath="url(#clip0_2280_5769)">
                        <path
                            d="M0.146484 20.2162V0.470703H8.3519C9.78695 0.470703 11.1042 0.710983 12.2975 1.19154C13.4909 1.6721 14.5241 2.3534 15.3912 3.23544C16.2582 4.12053 16.9229 5.16377 17.3912 6.36517C17.8594 7.56657 18.089 8.89267 18.089 10.3404C18.089 11.7882 17.8534 13.1173 17.3912 14.334C16.9229 15.5445 16.2552 16.5908 15.3912 17.4637C14.5241 18.3366 13.4909 19.0179 12.2975 19.4924C11.1042 19.9729 9.78695 20.2132 8.3519 20.2132H0.146484V20.2162ZM4.90478 15.9885H8.20991C8.99239 15.9885 9.69329 15.8517 10.3096 15.5779C10.9229 15.3072 11.4546 14.924 11.8927 14.4343C12.3307 13.9446 12.6691 13.3515 12.9138 12.6581C13.1555 11.9646 13.2764 11.189 13.2764 10.3435C13.2764 9.49793 13.1585 8.72843 12.9138 8.02888C12.6721 7.33541 12.3307 6.74232 11.8927 6.25263C11.4546 5.76295 10.9259 5.3858 10.3096 5.10902C9.69631 4.83833 8.99239 4.69842 8.20991 4.69842H4.90478V15.9855V15.9885Z"
                            fill="currentColor"></path>
                        <path d="M51.7751 5.23633H47.2676V20.2128H51.7751V5.23633Z" fill="currentColor"></path>
                        <path
                            d="M56.6046 9.41527H51.4082L51.8463 8.32336C52.5925 6.45891 54.3901 5.23926 56.384 5.23926H58.2783L56.6046 9.41527Z"
                            fill="currentColor"></path>
                        <path
                            d="M44.043 15.2157C43.2303 16.3228 41.9433 17.0436 40.8436 16.7516C39.6654 16.4323 39.2304 15.678 39.2304 13.9656V9.05963H44.4569V5.23948H39.2304V1.46191H34.75V13.5732C34.75 16.4931 35.3905 18.2998 37.2122 19.565C38.753 20.6326 41.3603 20.8607 43.4056 20.0152C45.327 19.2244 46.2364 18.026 46.2364 18.026L44.04 15.2187L44.043 15.2157Z"
                            fill="currentColor"></path>
                        <path
                            d="M68.3273 4.92969V6.48695C67.424 5.51366 65.8742 4.92969 63.9799 4.92969C61.4029 4.92969 57.3848 6.05505 57.3848 12.7129C57.3848 19.3708 61.2337 20.3593 63.844 20.4719C65.6597 20.5479 67.282 19.8848 68.3334 18.8872V20.2194H72.7865V4.92969H68.3304H68.3273ZM65.1159 16.8038C62.9648 16.8038 61.7715 15.3347 61.7715 12.646C61.7715 9.95732 62.9648 8.48827 65.1159 8.48827C67.2669 8.48827 68.4603 9.95732 68.4603 12.646C68.4603 15.3347 67.2669 16.8038 65.1159 16.8038Z"
                            fill="currentColor"></path>
                        <path
                            d="M85.2677 1.45898V6.48966C84.3674 5.51637 82.8205 4.9324 80.9203 4.9324C78.3493 4.9324 74.3281 6.05776 74.3281 12.7157C74.3281 19.3735 78.171 20.362 80.7813 20.4746C82.594 20.5506 84.2133 19.8876 85.2647 18.8899V20.2221H89.7178V1.46203H85.2647L85.2677 1.45898ZM82.0562 16.8004C79.9052 16.8004 78.7118 15.3314 78.7118 12.6427C78.7118 9.95395 79.9052 8.4849 82.0562 8.4849C84.2073 8.4849 85.4006 9.95395 85.4006 12.6427C85.4006 15.3314 84.2073 16.8004 82.0562 16.8004Z"
                            fill="currentColor"></path>
                        <path
                            d="M105.091 13.6983C105.091 13.6983 105.335 12.5546 105.299 11.3928C105.242 9.48879 104.314 4.86872 98.2961 4.81398C92.3928 4.75923 90.7402 9.20593 90.7402 12.798C90.7402 15.6053 92.0514 18.4917 95.0151 19.7783C98.8943 21.4602 103.366 20.1311 105.242 17.1352C104.009 15.7999 102.816 14.6746 102.816 14.6746C102.816 14.6746 101.181 17.415 98.0363 16.7854C96.3082 16.4387 95.13 14.8571 95.13 12.436C95.13 10.015 96.0121 8.49726 98.3505 8.52463C100.686 8.55201 101.115 10.2279 101.181 11.1373C101.269 12.3569 101.066 13.7043 101.066 13.7043H105.088L105.091 13.6983Z"
                            fill="currentColor"></path>
                        <path d="M103.366 11.1611H94.4238V13.6978H103.366V11.1611Z" fill="currentColor"></path>
                        <path
                            d="M33.493 13.6983C33.493 13.6983 33.7347 12.5546 33.7015 11.3928C33.6441 9.48879 32.7166 4.86872 26.6985 4.81398C20.7951 4.75923 19.1426 9.20593 19.1426 12.798C19.1426 15.6053 20.4538 18.4917 23.4205 19.7783C27.2997 21.4602 31.7709 20.1311 33.6471 17.1352C32.4145 15.7999 31.2211 14.6746 31.2211 14.6746C31.2211 14.6746 29.5867 17.415 26.4417 16.7854C24.7136 16.4387 23.5353 14.8571 23.5353 12.436C23.5353 10.015 24.4175 8.49726 26.7559 8.52463C29.0942 8.55201 29.5202 10.2279 29.5867 11.1373C29.6743 12.3569 29.4719 13.7043 29.4719 13.7043H33.493V13.6983Z"
                            fill="currentColor"></path>
                        <path d="M31.7648 11.1611H22.8223V13.6978H31.7648V11.1611Z" fill="currentColor"></path>
                    </g>
                    <defs>
                        <clipPath id="clip0_2280_5769">
                            <rect width="105.157" height="20.0588" fill="white"
                                  transform="translate(0.146484 0.470703)"></rect>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div className="w-full px-6 s768:w-[500px]">
                <div className="text-24 s768:text-36 font-700 text-white text-center mb-10"><span
                    className="mr-2">Join over</span><span
                    style={{
                        backgroundImage: "linear-gradient(90deg, rgb(106, 255, 237) 0%, rgb(235, 184, 255) 40.6%, rgb(149, 54, 255) 100%)",
                        backgroundClip: 'text',
                        color: 'transparent',
                        fontStyle: 'italic'
                    }}>10 million</span>
                    <div>Traders worldwide</div>
                </div>
                <div className="mb-6">
                    <div className="gap-5 flex flex-col">
                        <input
                            onChange={e => {
                                setForm({...form, username: e.target.value})
                            }}
                            className="flex h-12 w-full px-2 rounded-2 placeholder:text-quarterary outline-none shadow-sm focus:ring focus:ring-brand transition-all disabled:cursor-not-allowed disabled:opacity-50 bg-layer1"
                            autoComplete="on" placeholder="Email" value={form.username}/>
                        <div
                            className="h-12 flex items-center px-2 rounded-2 shadow-sm transition-all disabled:opacity-50 has-[:focus]:ring has-[:focus]:ring-brand bg-layer1">
                            <input type={inputType}
                                   onChange={e => {
                                       setForm({...form, password: e.target.value})
                                   }}
                                   className="w-full h-full bg-transparent placeholder:text-quarterary outline-none disabled:cursor-not-allowed"
                                   autoComplete="on" placeholder="Enter your password" value={form.password}/>
                            <TextEyeIcon setInputType={setInputType} inputType={inputType}/>
                        </div>
                        <button
                            onClick={loginAction}
                            className="inline-flex items-center justify-center max-w-full rounded-2 active:enabled:translate-y-[1px] s768:hover:enabled:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-12 px-4 py-3.5 text-14">
                            {t('log_in')}
                        </button>
                        <div className="flex justify-between items-center">
                            <div className="inline-block cursor-pointer text-16 underline">{t('skip')}</div>
                            <a className="underline text-16" href="/auth/reset-password">{t('forgot_password')}</a></div>
                        {/*Forgot password?*/}
                    </div>
                    <div className="flex gap-2 mt-2"><span className="text-16">Don't have an account?</span><a
                        className="cursor-pointer text-brand underline" href="/auth/register">
                        {t('sign_up')}
                        {/*Sign up*/}
                    </a></div>
                </div>
                <div className="mb-6 text-center text-14 s768:text-16">or continue with</div>
                <div className="flex gap-6">
                    <div className="flex-1 flex-center py-3 rounded-2 bg-white cursor-pointer">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                             className="w-9 h-9 p-2 fill-white border-main border rounded-2 cursor-pointer hover:bg-primary">
                            <path
                                d="M24.037 9.64963H23.0897V9.59983H12.5059V14.3998H19.1519C18.1823 17.1939 15.577 19.1997 12.5059 19.1997C8.60928 19.1997 5.45001 15.9759 5.45001 11.9998C5.45001 8.02366 8.60928 4.79992 12.5059 4.79992C14.3046 4.79992 15.9409 5.4923 17.1869 6.62329L20.5131 3.22914C18.4128 1.23178 15.6034 0 12.5059 0C6.01154 0 0.746094 5.37291 0.746094 11.9998C0.746094 18.6267 6.01154 23.9996 12.5059 23.9996C19.0002 23.9996 24.2657 18.6267 24.2657 11.9998C24.2657 11.1952 24.1845 10.4098 24.037 9.64963Z"
                                fill="#FFC107"></path>
                            <path
                                d="M2.10156 6.41449L5.96524 9.30584C7.01069 6.66468 9.54258 4.79992 12.5055 4.79992C14.3041 4.79992 15.9405 5.4923 17.1864 6.62328L20.5127 3.22914C18.4124 1.23178 15.603 0 12.5055 0C7.98852 0 4.07133 2.60215 2.10156 6.41449Z"
                                fill="#FF3D00"></path>
                            <path
                                d="M12.5063 24.0001C15.5438 24.0001 18.3038 22.8139 20.3906 20.8849L16.751 17.7422C15.5306 18.6892 14.0394 19.2014 12.5063 19.2001C9.44755 19.2001 6.85039 17.21 5.87198 14.4326L2.03711 17.4476C3.98336 21.3337 7.93582 24.0001 12.5063 24.0001Z"
                                fill="#4CAF50"></path>
                            <path
                                d="M24.0369 9.64941H23.0897V9.59961H12.5059V14.3995H19.1519C18.6881 15.7294 17.8527 16.8914 16.7488 17.7421L16.7506 17.7409L20.3902 20.8836C20.1327 21.1224 24.2657 17.9995 24.2657 11.9996C24.2657 11.195 24.1845 10.4096 24.0369 9.64941Z"
                                fill="#1976D2"></path>
                        </svg>
                    </div>
                    <div className="flex-1 flex-center py-3 rounded-2 bg-white cursor-pointer">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                             className="w-9 h-9 p-2 fill-white border-main border rounded-2 cursor-pointer hover:bg-primary">
                            <path
                                d="M23.9535 11.9782C23.9535 18.0277 19.4705 23.0289 13.6468 23.8405C13.1023 23.9161 12.5452 23.9555 11.9797 23.9555C11.3269 23.9555 10.6859 23.9035 10.0616 23.8028C4.36043 22.8846 0.00585938 17.9396 0.00585938 11.9782C0.00585938 5.36299 5.36726 0 11.9805 0C18.5937 0 23.9551 5.36299 23.9551 11.9782H23.9535Z"
                                fill="#1C1C1B"></path>
                            <path
                                d="M4.86253 5.28271L10.385 12.6684L4.82812 18.6734H6.07912L10.9447 13.4162L14.8755 18.6734H19.1319L13.299 10.8723L18.4716 5.28271H17.2206L12.7402 10.1245L9.11977 5.28271H4.86336H4.86253ZM6.70168 6.20424H8.65662L17.2911 17.7519H15.3361L6.70168 6.20424Z"
                                fill="white"></path>
                        </svg>
                    </div>
                    <div className="flex-1 flex-center py-3 rounded-2 bg-white cursor-pointer">
                        <svg viewBox="0 0 1152 1024" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             className="w-9 h-9 p-2 fill-white border-main border rounded-2 cursor-pointer hover:bg-primary">
                            <path
                                d="M328.149333 938.666667l148.892445 39.623111v-51.811556l12.16-12.188444h85.077333v103.608889h-91.164444l-112.426667-48.753778L328.177778 938.666667z"
                                fill="#CDBDB2"></path>
                            <path
                                d="M814.321778 938.666667l-145.848889 39.623111v-51.811556l-12.16-12.188444h-85.077333v103.608889h91.164444l112.412444-48.753778L814.321778 938.666667z"
                                fill="#CDBDB2"></path>
                            <path
                                d="M489.201778 825.898667l-12.16 100.579555 15.189333-12.188444h158.008889l18.232889 12.188444-12.16-100.579555L632.007111 810.666667l-121.543111 3.043555-21.262222 12.188445z"
                                fill="#393939"></path>
                            <path
                                d="M407.153778 146.289778l72.931555 170.666666 33.422223 496.753778h118.499555l36.465778-496.753778 66.844444-170.666666H407.153778z"
                                fill="#F89C35"></path>
                            <path
                                d="M88.120889 521.144889L3.043556 768l212.693333-12.188444h136.732444v-106.666667l-6.087111-219.434667-30.378666 24.391111-227.882667 67.043556z"
                                fill="#F89D35"></path>
                            <path
                                d="M249.159111 548.565333L498.318222 554.666667l-27.349333 128-118.499556-30.478223-103.310222-103.623111z"
                                fill="#D87C30"></path>
                            <path d="M249.159111 551.623111l103.310222 97.521778V746.666667l-103.310222-195.043556z"
                                  fill="#EA8D3A"></path>
                            <path
                                d="M352.469333 652.188444L473.998222 682.666667l39.509334 131.043555-27.349334 15.246222-133.688889-79.246222v-97.521778z"
                                fill="#F89D35"></path>
                            <path
                                d="M352.469333 749.710222L328.149333 938.666667l161.052445-112.768-136.732445-76.188445z"
                                fill="#EB8F35"></path>
                            <path
                                d="M498.318222 554.666667l15.189334 259.043555-45.582223-132.565333L498.318222 554.666667z"
                                fill="#EA8E3A"></path>
                            <path d="M212.693333 752.768l139.776-3.057778L328.149333 938.666667l-115.456-185.898667z"
                                  fill="#D87C30"></path>
                            <path
                                d="M69.888 1020.956444L328.163556 938.666667l-115.470223-185.898667L3.043556 768l66.844444 252.956444z"
                                fill="#EB8F35"></path>
                            <path
                                d="M480.085333 316.956444L349.425778 426.666667l-100.266667 121.898666 249.159111 9.144889-18.232889-240.753778z"
                                fill="#E8821E"></path>
                            <path
                                d="M328.149333 938.666667l161.052445-112.768-12.16 97.536v54.855111l-109.383111-21.333334L328.163556 938.666667zM814.321778 938.666667l-158.008889-112.768 12.16 97.536v54.855111l109.368889-21.333334L814.336 938.666667z"
                                fill="#DFCEC3"></path>
                            <path
                                d="M431.459556 609.521778l33.422222 70.101333-118.485334-30.478222 85.063112-39.623111z"
                                fill="#393939"></path>
                            <path d="M66.844444 3.043556l413.240889 313.912888-69.888-170.666666L66.844444 3.043556z"
                                  fill="#E88F35"></path>
                            <path
                                d="M66.844444 3.043556L12.145778 170.666667l30.392889 182.855111-21.276445 12.188444 30.392889 27.434667-24.32 21.333333 33.422222 30.478222-21.262222 18.275556 48.625778 60.956444 227.882667-70.087111s167.111111-134.101333 164.081777-137.144889C477.041778 313.884444 66.844444 3.043556 66.844444 3.043556z"
                                fill="#8E5A30"></path>
                            <path
                                d="M1054.350222 521.144889L1139.441778 768l-212.707556-12.188444h-136.732444v-106.666667l6.087111-219.434667 30.378667 24.391111 227.882666 67.043556z"
                                fill="#F89D35"></path>
                            <path
                                d="M893.312 548.565333L644.152889 554.666667l27.349333 128 118.499556-30.478223 103.310222-103.623111z"
                                fill="#D87C30"></path>
                            <path d="M893.312 551.623111l-103.310222 97.521778V746.666667l103.310222-195.043556z"
                                  fill="#EA8D3A"></path>
                            <path
                                d="M790.001778 652.188444L668.472889 682.666667l-39.509333 131.043555 27.349333 15.246222 133.688889-79.246222v-97.521778z"
                                fill="#F89D35"></path>
                            <path
                                d="M790.001778 749.710222L814.321778 938.666667l-158.008889-109.710223 133.688889-79.246222z"
                                fill="#EB8F35"></path>
                            <path
                                d="M644.152889 554.666667l-15.189333 259.043555 45.582222-132.565333L644.152889 554.666667z"
                                fill="#EA8E3A"></path>
                            <path d="M929.777778 752.768l-139.776-3.057778L814.321778 938.666667l115.456-185.898667z"
                                  fill="#D87C30"></path>
                            <path
                                d="M1072.583111 1020.956444L814.307556 938.666667l115.470222-185.898667L1139.441778 768l-66.844445 252.956444z"
                                fill="#EB8F35"></path>
                            <path
                                d="M662.385778 316.956444L793.045333 426.666667l100.266667 121.898666-249.159111 9.144889 18.232889-240.753778z"
                                fill="#E8821E"></path>
                            <path
                                d="M710.997333 609.521778l-33.422222 70.101333 118.513778-30.478222-85.091556-39.623111z"
                                fill="#393939"></path>
                            <path
                                d="M1075.626667 3.043556L662.385778 316.956444l69.888-170.666666L1075.626667 3.043556z"
                                fill="#E88F35"></path>
                            <path
                                d="M1075.626667 3.043556L1130.325333 170.666667l-30.392889 182.855111 21.276445 12.188444-30.392889 27.434667 24.32 21.333333-33.422222 30.478222 21.262222 18.275556-48.611556 60.956444L826.453333 454.115556s-167.111111-134.101333-164.081777-137.144889C665.429333 313.884444 1075.626667 3.043556 1075.626667 3.043556z"
                                fill="#8E5A30"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Login
