import {Period} from "../util/order";

const Loading = ({period}: { period: Period }) => {
    const times = [0, 140, 280.420, 560]

    const calcIndex = () => {
        let index = 0
        if (period.index > 30) {
            index = 0
        } else if (period.index > 20) {
            index = 2
        } else if (period.index > 15) {
            index = 1
        }
        return index
    }
    const getClassName = () => {
        const index = calcIndex()
        return ["stroke-brand", "stroke-warn", "stroke-error"][index]
    }

    const getTextClass = () => {
        const index = calcIndex()
        return ["text-up", "text-[#FF9820]", "text-down"][index]
    }
    const isNotInGame = period.isNotInGame
    const getProgress = () => {
        return (30 - period.index-1) / 30 * 360 - 360
    }
    return <div className="relative text-brand w-24 h-24 s1440:w-30 s1440:h-30 s1920:w-34 s1920:h-34 shrink-0">
        <svg className="w-full h-full" width="136" height="136" viewBox="0 0 136 136">
            <circle className="fill-layer2" cx="68" cy="68" r="68" strokeWidth="0"></circle>
            <circle className={`${getClassName()}${period.index < 30 ? '/20' : ''}`} cx="68" cy="68" r="60.5"
                    strokeWidth="15" fill="none"></circle>
            {period.index < 30 ? <circle
                className={`origin-center -rotate-90 transition-[stroke-dashoffset] ease-linear duration-1000 
                ${getClassName()}`}
                strokeDashoffset={getProgress()} strokeDasharray="380.132711084365,1000"
                strokeLinecap="round" cx="68" cy="68" r="60.5" strokeWidth="15" fill="none"></circle> : <></>}
        </svg>
        <div className="abs-center flex-center flex-col w-full h-full">
            {(period.index && period.index > 30) ?
                <div className="flex-center gap-1 w-full h-full">
                    {isNotInGame ?
                        (times.map((item, key) => <div className="rounded-full cutdown-waiting bg-brand"
                                                       key={key}
                                                       style={{
                                                           animationDelay: `${item}ms`,
                                                           width: '4.412%',
                                                           height: '17.647%'
                                                       }}></div>)) :
                        <div
                            className="w-20 s1440:w-24 text-center text-10 s1440:text-12 s1920:text-14 font-500">WAITING
                            FOR RESULT</div>

                    }
                </div> :
                <div className="abs-center flex-center flex-col w-full h-full">
                    <div
                        className={`text-24 s1440:text-30 s1920:text-36 leading-none font-600 ${getTextClass()}`}>{30 - period.index}</div>
                    <div className={`text-12 s1920:text-14 font-400 ${getTextClass()}`}>SEC</div>
                </div>
            }
        </div>
    </div>
}
export default Loading
