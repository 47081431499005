import NoBetOrder from "./NoBetOrder";

const NoData = ({msg}:{msg:string})=> {
    return <div className="abs-center text-center" style={{marginTop:'20px'}}>
        <>
            <NoBetOrder/>
        </>
        <div className="text-secondary light:text-secondary text-13">
            {msg}
        </div>
    </div>
}
export default NoData
