import CopyToClipboard from "react-copy-to-clipboard";
import {useRecoilValue} from "recoil";
import {userInfoState} from "../store/user";
import avatar from '../static/Avatar-5.png'
import Copy from "./Copy";
import React, {useState} from "react";
import UpdateUser from "./UpdateUser";
import {success} from "../util/toast";
import {useTranslation} from "react-i18next";

const Profile = () => {
    const userInfo = useRecoilValue(userInfoState)
    const {t} = useTranslation()
    const [updateUserVisible, setUpdateUserVisible] = useState(false)
    return <div className="flex flex-col flex-1 overflow-auto">
        <main className="flex-1 bg-layer2 p-4">
            <div className="px-4 flex-1 overflow-y-auto">
                <div className="p-6 rounded-3 bg-layer2 mb-2.5">
                    <div className="text-16 font-500">Profile Info</div>
                    <hr className="my-5 border-thirdly"/>
                    <div className="flex items-center gap-2 s768:gap-4"><span
                        className="block overflow-hidden w-13 h-13 rounded-4 s768:w-18 s768:h-18 s768:rounded-5 lazy-load-image-background  lazy-load-image-loaded"><img
                        src={userInfo?.icon || avatar}
                        className="w-full h-full object-cover"/></span>
                        <div className="flex-1 overflow-hidden">
                            <div className="text-16 font-500">{userInfo?.username}</div>
                            <div className="inline-flex items-center max-w-full text-[#C2C2C2]">
                                <CopyToClipboard text={userInfo?.outId || ""} onCopy={() => success('copy success')}>
                                    <div>
                                        <div className="text-12 cursor-pointer flex-row">UID:
                                            <text className="overflow-hidden">{userInfo?.outId}</text>
                                        </div>
                                        <Copy/>
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <button
                            onClick={()=>{setUpdateUserVisible(true)}}
                            className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-9 px-4 py-2 text-12 s768:px-7 shrink-0">edit
                        </button>
                    </div>
                </div>
                <div className="p-6 rounded-3 bg-layer2">
                    <div className="text-16 font-500">{t('contact_info')}</div>
                    <hr className="my-5 border-thirdly"/>
                    <div className="mb-2 text-16 text-[#C2C2C2] font-500">{t('e-mail')}</div>
                    <div className="inline-flex p-4 rounded-2 bg-layer3">{userInfo?.email}</div>
                </div>
            </div>
        </main>
        <UpdateUser visible={updateUserVisible} onCancel={() => {
            setUpdateUserVisible(false)
        }}/>
    </div>
}
export default Profile
