import {useRecoilValue} from "recoil";
import {highLowOrderState, HighLowOrderUser} from "../store/game";
import avatar from '../static/Avatar-5.png'
import CalcResult from "./CalcResult";

const UpdownPlayers = () => {
    const highLowOrder = useRecoilValue(highLowOrderState)
    const calcFlag = highLowOrder.calcFlag
    return <div className="relative flex shrink-0 px-1.5 s1920:px-2 py-5 rounded-4 bg-layer4 overflow-hidden"
                style={{width: '376px'}}>

        {calcFlag ? <CalcResult order={highLowOrder}/> : <></>}

        <div className="flex-1 w-0 px-1.5 s1920:px-2 text-down !block" style={{opacity: 1, display: 'block'}}>
            <div className="mb-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"
                     className="mx-auto w-10 h-10 s1366:w-11 s1366:h-11 s1920:w-13 s1920:h-13 p-2 rounded-full bg-down mb-1.5">
                    <g opacity="0.75">
                        <path
                            d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                            fill="url(#paint0_linear_62_14101)"></path>
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_62_14101" x1="19" y1="0.500026" x2="19" y2="39"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"></stop>
                            <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                <span className="text-18 font-600">Down</span></div>
            <div className="relative">
                <div style={{transform: 'none', opacity: 1}}>
                    <div
                        className="relative mb-4 p-3 border-t-[5px] rounded-t-2 bg-gradient-to-b to-transparent border-down from-down/20">
                        <div className="flex mb-6 text-12 font-400 text-down">
                            <div className="mr-1 text-secondary">Players</div>
                            <div className="font-600">{highLowOrder?.downOrderCount || 0}</div>
                            <div className="flex-1 text-right">${highLowOrder?.downOrderAmount || 0}</div>
                        </div>
                        <div className="flex justify-between text-14 text-primary font-500">
                            <div>Players</div>
                            <div>Bet</div>
                        </div>
                        <div
                            className="absolute bottom-0 inset-x-0 h-px bg-layer5 light:bg-layer2 -mr-1.5 s1920:-mr-2"></div>
                    </div>
                    <div className="text-primary after:clear-both mb-3 overflow-hidden" style={{height: '424px'}}>
                        {highLowOrder.downUsers?.map((item: HighLowOrderUser, index) => <div
                            key={`down-user-${index}`}
                            className="relative flex items-center h-7 w-full mb-4 pr-1 text-12 s1920:text-14 rounded overflow-hidden float-left"
                            style={{zIndex: 11, marginTop: '0px', width: '100%', opacity: 1}}><img
                            className="aspect-square object-cover w-7 rounded-2 mr-2"
                            alt=""
                            src={avatar}/>
                            <div className="relative z-10 mr-2 truncate font-600 s1440:font-700">{item.username}</div>
                            <div className="relative z-10 flex-1 flex justify-end mr-1"><span>$</span>
                                <div>{item.amount}</div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                                 fill="none" className="w-5 h-5 z-10 shrink-0 text-brand">
                                <path
                                    d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                                    fill="currentColor"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M17.9217 17.3824C17.8117 17.3904 17.2447 17.4244 15.9797 17.4244C14.9697 17.4244 14.2587 17.3944 14.0087 17.3824C10.1207 17.2114 7.21869 16.5344 7.21869 15.7244C7.21869 14.9144 10.1187 14.2384 14.0087 14.0644V16.7094C14.2627 16.7274 14.9907 16.7704 15.9967 16.7704C17.2037 16.7704 17.8087 16.7204 17.9217 16.7104V14.0674C21.8017 14.2404 24.6967 14.9174 24.6967 15.7254C24.6967 16.5334 21.7967 17.2104 17.9217 17.3824ZM17.9217 13.7924V11.4264H23.3357V7.81836H8.59969V11.4264H14.0137V13.7914C9.61369 13.9914 6.30469 14.8654 6.30469 15.9094C6.30469 16.9534 9.61369 17.8244 14.0137 18.0274V25.6094H17.9267V18.0254C22.3197 17.8254 25.6207 16.9524 25.6207 15.9094C25.6207 14.8664 22.3207 13.9954 17.9267 13.7924"
                                      fill="white"></path>
                            </svg>
                            <div
                                className="absolute inset-y-1 left-10 right-3.5 origin-right bg-gradient-to-l to-transparent from-up/80"
                                style={{transform: 'scaleX(0) translateZ(0px)'}}></div>
                        </div>)}
                    </div>
                    <div
                        className="h-9 after:clear-both rounded-full cursor-pointer overflow-hidden border border-transparent bg-layer4 invisible"
                        style={{
                            borderWidth: '2px',
                            backgroundClip: 'padding-box, border-box',
                            backgroundOrigin: 'padding-box, border-box',
                            backgroundImage: 'linear-gradient(to right, var(--bg-layer4), var(--bg-layer4)),linear-gradient(to right, #61FFD9, #CF87FC)'
                        }}>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex-1 w-0 px-1.5 s1920:px-2 text-up !block" style={{opacity: 1, display: 'block'}}>
            <div className="mb-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"
                     className="mx-auto w-10 h-10 s1366:w-11 s1366:h-11 s1920:w-13 s1920:h-13 p-2 rounded-full bg-up mb-1.5">
                    <path
                        d="M4.5714 23.7937L11.0352 17.3299L16.4084 22.705L22.4067 16.7067L19 13.3L28.5 13.3L28.5 22.8L25.0933 19.3933L16.4084 28.0782L11.0352 22.705L6.3327 27.4056C8.46718 30.6182 11.7432 32.8992 15.4968 33.7864C19.2505 34.6736 23.201 34.1006 26.5479 32.1835C29.8949 30.2664 32.3878 27.1487 33.5215 23.462C34.6553 19.7753 34.3451 15.7955 32.6539 12.329C30.9626 8.86249 28.0167 6.1686 24.4132 4.79322C20.8097 3.41784 16.818 3.46384 13.2472 4.92189C9.67629 6.37995 6.79327 9.141 5.18232 12.6456C3.57137 16.1501 3.35298 20.136 4.5714 23.7956L4.5714 23.7937ZM1.6492 26.7539L1.6302 26.7349L1.6378 26.7273C0.554671 24.2953 -0.0034042 21.6623 8.90882e-06 19C1.25784e-05 8.5063 8.50631 2.97458e-06 19 6.64413e-06C29.4937 1.03137e-05 38 8.50631 38 19C38 29.4937 29.4937 38 19 38C11.267 38 4.617 33.383 1.6492 26.7539Z"
                        fill="url(#paint0_linear_62_14106)" fillOpacity="0.75"></path>
                    <defs>
                        <linearGradient id="paint0_linear_62_14106" x1="19" y1="6.64413e-06" x2="19" y2="38"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"></stop>
                            <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                <span className="text-18 font-600">Up</span></div>
            <div className="relative">
                <div style={{transform: 'none', opacity: 1}}>
                    <div
                        className="relative mb-4 p-3 border-t-[5px] rounded-t-2 bg-gradient-to-b to-transparent border-up from-up/20">
                        <div className="flex mb-6 text-12 font-400 text-up">
                            <div className="mr-1 text-secondary">Players</div>
                            <div className="font-600">{highLowOrder.upOrderCount}</div>
                            <div className="flex-1 text-right">${highLowOrder.upOrderAmount}</div>
                        </div>
                        <div className="flex justify-between text-14 text-primary font-500">
                            <div>Players</div>
                            <div>Bet</div>
                        </div>
                        <div
                            className="absolute bottom-0 inset-x-0 h-px bg-layer5 light:bg-layer2 -ml-1.5 s1920:-ml-2"></div>
                    </div>
                    <div className="text-primary after:clear-both mb-3 overflow-hidden" style={{height: '424px'}}>
                        {highLowOrder.upUsers?.map((item: HighLowOrderUser, index) => <div
                            key={`down-user-${index}`}
                            className="relative flex items-center h-7 w-full mb-4 pr-1 text-12 s1920:text-14 rounded overflow-hidden float-left"
                            style={{zIndex: 11, marginTop: '0px', width: '100%', opacity: 1}}><img
                            className="aspect-square object-cover w-7 rounded-2 mr-2"
                            alt=""
                            src={avatar}/>
                            <div className="relative z-10 mr-2 truncate font-600 s1440:font-700">{item.username}</div>
                            <div className="relative z-10 flex-1 flex justify-end mr-1"><span>$</span>
                                <div>{item.amount}</div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                                 fill="none" className="w-5 h-5 z-10 shrink-0 text-brand">
                                <path
                                    d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                                    fill="currentColor"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M17.9217 17.3824C17.8117 17.3904 17.2447 17.4244 15.9797 17.4244C14.9697 17.4244 14.2587 17.3944 14.0087 17.3824C10.1207 17.2114 7.21869 16.5344 7.21869 15.7244C7.21869 14.9144 10.1187 14.2384 14.0087 14.0644V16.7094C14.2627 16.7274 14.9907 16.7704 15.9967 16.7704C17.2037 16.7704 17.8087 16.7204 17.9217 16.7104V14.0674C21.8017 14.2404 24.6967 14.9174 24.6967 15.7254C24.6967 16.5334 21.7967 17.2104 17.9217 17.3824ZM17.9217 13.7924V11.4264H23.3357V7.81836H8.59969V11.4264H14.0137V13.7914C9.61369 13.9914 6.30469 14.8654 6.30469 15.9094C6.30469 16.9534 9.61369 17.8244 14.0137 18.0274V25.6094H17.9267V18.0254C22.3197 17.8254 25.6207 16.9524 25.6207 15.9094C25.6207 14.8664 22.3207 13.9954 17.9267 13.7924"
                                      fill="white"></path>
                            </svg>
                            <div
                                className="absolute inset-y-1 left-10 right-3.5 origin-right bg-gradient-to-l to-transparent from-up/80"
                                style={{transform: 'scaleX(0) translateZ(0px)'}}></div>
                        </div>)}
                    </div>
                    <div
                        className="h-9 after:clear-both rounded-full cursor-pointer overflow-hidden border border-transparent bg-layer4 invisible"
                        style={{
                            borderWidth: '2px',
                            backgroundClip: 'padding-box, border-box',
                            backgroundOrigin: 'padding-box, border-box',
                            backgroundImage: 'linear-gradient(to right, var(--bg-layer4), var(--bg-layer4)),linear-gradient(to right, #61FFD9, #CF87FC)'
                        }}></div>
                </div>
            </div>
        </div>
    </div>
}

export default UpdownPlayers
