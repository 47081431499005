import {Outlet} from "react-router-dom";
import TradeOrder from "./TradeOrder";
import React from "react";
import {useTranslation} from "react-i18next";
import Footer from "./Footer";

const TradeLayout = () => {
    const {t} = useTranslation()
    return <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex flex-1 overflow-hidden">
            <div className="flex-1 overflow-auto">
                <div className="flex-1 bg-layer2 overflow-x-hidden p-4">
                    <div className="flex flex-col flex-1 gap-3">
                        <Outlet/>
                        <TradeOrder/>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    </div>
}

export default TradeLayout
