import dayjs from "dayjs";
import UpDownIcon from "../components/UpDownIcon";
import LeverageActions from "../components/LeverageActions";
import TimeContactLogo from "../components/TimeContactLogo";
import HighLowIcon from "../components/HighLowIcon";
import LeverageIcon from "../components/LeverageIcon";
import DownOrUpNumberSmall from "../components/DownOrUpNumberSmall";
import {PairShow} from "../components/PairShow";
import React from "react";
import PlayerShow from "../components/PlayerShow";
import NumberShow from "../components/NumberShow";
import TimeShow from "../components/TimeShow";

export interface MenuIconProps {
    active: Boolean
}

interface HighLowListItem {
    amount: number,
    calcAmount: number
    createdAtTimestamp: number
    entryPrice: number
    exitPrice: number
    exitTime: number
    outId: string
    symbol: string
    win: boolean
}

export interface DialogProps {
    visible: boolean
    onCancel: Function
}

export interface TableItem {
    width: number
    name: string,
    label?: string,
    align?: "center" | "left" | "right" | "justify" | "char" | undefined,
    render?: Function,
    type?: string
}

const playerField = {
    width: 150, name: 'player', align: 'left', label: 'PLAYER', render(record: any) {
        return <PlayerShow record={record}/>
    }
}
const pairField = {
    width: 150, name: 'pair', label: 'PAIR', render(record: any) {
        return <PairShow record={record}/>
    }
}
const amountField = {width: 80, name: 'amount', label: 'AMOUNT'}
const currencyField = {width: 80, name: 'currencyCoin', label: 'CURRENCY'}
const yieldField = {
    width: 80, name: 'yield', label: 'YIELD', render(record: any) {
        return <span>{record.yieldRate || record.lossRate}%</span>
    }
}
const openField = {width: 80, name: 'entryPrice', label: 'OPEN'}
const tradeField = {width: 80, name: 'trades', label: 'TRADES'}
const winningField = {width: 80, name: 'winning', label: 'WINNING'}
const roiField = {width: 80, name: 'roi', label: 'ROI'}
const closeField = {width: 80, name: 'exitPrice', label: 'CLOSED'}
const profitField = {
    width: 80, name: 'profit', label: 'PROFIT', render(record: any) {
        return NumberShow(record.profit)
    }
}
const outIdField = {width: 120, name: 'outId', label: 'ID'}

const calcTimeField = {
    width: 150, name: 'time', label: 'Time', render(record: any) {
        return record.currentTime<=0?<></>:<TimeShow currentTime={record.currentTime} totalTime={record.totalTime || 50}/>
    }
}
// PLAYER	PAIR	CURRENCY	AMOUNT	OPEN	CLOSED	YIELD	PROFIT
export const TimeContractActiveFields = [
    pairField,
    currencyField,
    openField,
    closeField,
    yieldField,
    profitField,
    calcTimeField
]
export const TimeContractCloseFields = [
    pairField,
    currencyField,
    amountField,
    openField,
    closeField,
    yieldField,
    profitField,
    outIdField
]
export const TimeContractPublicFields = [
    playerField,
    pairField,
    currencyField,
    amountField,
    openField,
    closeField,
    yieldField,
    profitField
]
export const LeaderBoardFields = [
    playerField,
    tradeField,
    winningField,
    roiField,
    profitField
]

export const mapToSymbol = (symbol: string) => {
    return symbol?.replace("USD", "/USD")
}
// Order ID	BET	CURRENCY	investment	Start Price	Start Time	Profit(U)
const betField = {
    width: 80, name: 'bet', label: 'BET',
    render(record: any) {
        return <PairShow record={record}/>
    }
}

const wagerField = {
    width: 80, name: 'amount', label: 'WAGER'
}
const entryField = {
    width: 80, name: 'entryPrice', label: 'ENTRY'
}
const exitField = {
    width: 80, name: 'exitPrice', label: 'EXIT'
}
const bustField = {
    width: 80, name: 'bustPrice', label: 'BUST'
}
const multiplierField = {
    width: 80, name: 'multiplier', label: 'MULTIPLIER'
}
const foundingHourField = {
    width: 80, name: 'foundingHour', label: 'FUNDING/HOUR'
}

const pAndLField = {
    width: 80, name: 'profit', label: 'P&L'
}

const investField = {width: 80, name: 'amount', label: 'investment'}
const startPriceField = {width: 80, name: 'entryPrice', label: 'Start Price'}
const startTimeField = {
    width: 80, name: 'entryTime', label: 'Start Time', render(record: any) {
        return dayjs(record.entryTime).format("YYYY-MM-DD HH:mm:ss")
    }
}
const profitUField = {
    width: 80, name: 'profit', label: 'Profit(U)', render(record: any) {
        return NumberShow(record.profit)
    }
}
const LeverageActionsField = {
    width: 200, name: 'actions', label: 'actions', render(record: LeverageListItem) {
        return <LeverageActions order={record}/>
    }
}
const timeField = {
    width: 150, name: 'time', label: 'Time', render(record: any) {
        return dayjs(record.createdAtTimestamp).format("YYYY-MM-DD")
    }
}
export const HighLowActiveFields = [
    outIdField,
    betField,
    currencyField,
    investField,
    startTimeField,
    startPriceField,
    profitUField,
]
export const LeverageSimpleActiveFields = [
    betField,
    wagerField,
    pAndLField,
    LeverageActionsField
]
export const LeverageActiveFields = [
    betField,
    currencyField,
    wagerField,
    entryField,
    bustField,
    multiplierField,
    foundingHourField,
    pAndLField,
    LeverageActionsField
]
export const LeverageClosedFields = [
    betField,
    currencyField,
    wagerField,
    entryField,
    exitField,
    bustField,
    multiplierField,
    foundingHourField,
    pAndLField,
    roiField,
    LeverageActionsField
]
export const LeveragePublicFields = [
    playerField,
    betField,
    currencyField,
    wagerField,
    entryField,
    exitField,
    bustField,
    multiplierField,
    foundingHourField,
    pAndLField,
    roiField,
]
export const HighLowCloseFields = [
    outIdField,
    timeField,
    betField,
    currencyField,
    investField,
    startTimeField,
    startPriceField,
    profitUField,
]

// "TimeContract"|"HighLow"|"Updown"
export const TABLE_FIELDS: any = {
    "TimeContract": {
        "1": TimeContractActiveFields,
        "2": TimeContractCloseFields,
        "3": TimeContractPublicFields,
        "4": LeaderBoardFields
    },
    "Leverage": {
        "1": LeverageActiveFields,
        "2": LeverageClosedFields,
        "3": LeveragePublicFields
    },
    "Updown": {
        "1": HighLowActiveFields,
        "2": HighLowCloseFields,
        "4": LeaderBoardFields
    }
}

export const HighLowFields: TableItem[] = [
    outIdField,
    amountField,
    {width: 80, name: 'calcAmount', label: 'profit'},
    {
        width: 80, name: 'createdAtTimestamp', label: 'createAtTime', render(row: HighLowListItem) {
            return dayjs(row.createdAtTimestamp).format("YYYY-MM-DD")
        }
    },
    {
        width: 80, name: 'entryPrice'
    },
    {
        width: 80, name: 'exitPrice'
    },
    {
        width: 80, name: 'symbol'
    },
    {
        width: 80, name: 'win', render(row: HighLowListItem) {
            return row.win ? 'Y' : 'N'
        }
    },
]

export interface LeverageListItem {
    outId: string
    calcTime: number
    calcFlag: boolean
    entryPrice: number
    multiplier: number
    symbol: string
    amount: number
    profit: number
    roi: number
}

export const LeverageContractFields: TableItem[] = [
    {width: 80, name: 'outId', align: 'left', label: 'BET'},
    {
        width: 80,
        name: 'symbol',
        label: 'symbol',
        render(row: { type: string, symbol: string, entryPrice: number, exitPrice: number }) {
            const down = row.entryPrice > row.exitPrice
            return <div className="flex items-center gap-1 justify-center">
                <div className="text-13 text-primary w-18 truncate">{row.symbol}</div>
                <UpDownIcon type={down ? 'down' : 'up'}/>
            </div>
        }
    },
    currencyField,
    {width: 80, name: 'amount', label: 'WAGER'},
    {width: 80, name: 'multiplier', label: 'multiplier'},
    {width: 80, name: 'entryPrice', label: 'ENTRY'},
    {width: 80, name: 'bustPrice', label: 'BUST'},
    {width: 100, name: 'funding', label: 'FUNDING/HOUR'},
    {width: 100, name: 'exitPrice', label: 'exitPrice'},
    {
        width: 100, name: 'profit', label: 'P&L', render(record: any) {
            return <DownOrUpNumberSmall down={record?.profit < 0} price={record?.profit}/>
        }
    },
    {
        width: 100, name: 'roi', label: 'ROI', render(record: any) {
            return <DownOrUpNumberSmall down={record?.roi < 0} price={record?.roi}/>
        }
    },
    {
        width: 150, name: 'actions', label: 'actions', render(record: LeverageListItem) {
            return <LeverageActions order={record}/>
        }
    }
]

export interface CryptoVo {
    open: number
    close: number
    high: number
    low: number
    symbol: string
    pair: string
    change: number
    icon: string
}

export const MenuIconMap: any = {
    "time-contract": TimeContactLogo,
    "updown-contract": HighLowIcon,
    "leverage-contract": LeverageIcon,
}
