import {useEffect, useRef, useState} from "react";
import {config} from "../api";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {gameState, gameTypeState, timePeriodsState, timePeriodState} from "../store/game";
import SendOrder from "./SendOrder";
import {currentWalletState} from "../store/user";
import {useTranslation} from "react-i18next";

const TimeBet = () => {

    const [money, setMoney] = useState<any>()
    const [type, setType] = useState(0)
    const {t} = useTranslation()
    const currentWallet = useRecoilValue(currentWalletState)
    const game = useRecoilValue(gameState)
    const [periods,setTimePeriods] = useRecoilState(timePeriodsState)
    const [timePeriod,setTimePeriod] = useRecoilState(timePeriodState)
    const setGameType = useSetRecoilState(gameTypeState)
    const [activeIndex, setActiveIndex] = useState(0)
    const ref = useRef<{ join: Function }>();
    const initConfig = async () => {
        if (game.outId) {
            const data = await config(game.outId, 'BTCUSD')
            setTimePeriods(data.data)
            if(data.data && data.data.length>0){
                setTimePeriod(data.data[0])
            }
        }
    }
    const [oneClick, setOneClick] = useState(true)


    useEffect(() => {
        initConfig().then()
        setGameType("TimeContract")
    }, [game.outId]);


    return <>
        <div
            className="mb-3 s1024:mb-0 s1024:ml-3 s1024:w-[420px] s1440:w-[458px] card-container !bg-layer4">
            <div className="flex items-center rounded-3 gap-1 p-1 border border-input bg-layer3"><label
                htmlFor="amount-input-up-down"
                className="flex-center w-6 h-6 ml-1.5 rounded-full overflow-hidden pointer-events-none"
                style={{
                    backgroundImage: `url('${currentWallet?.currency?.icon}')`,
                    backgroundSize: 'contain'
                }}></label><input

                onChange={(e) => {
                    setMoney(e.target.value)
                }}
                className="px-2 text-13 font-700 rounded focus:outline-none focus:outline-background-brand disabled:cursor-not-allowed flex-1 w-0 h-8 s1366:h-10 text-primary bg-inherit"
                type="text" inputMode="decimal" autoComplete="off" id="amount-input-up-down" value={money}/>
                <button
                    onClick={() => setMoney(money / 2)}
                    className="inline-flex items-center justify-center max-w-full active:scale-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 w-8 s768:w-15 h-8 s1366:h-10 shrink-0 bg-layer5 rounded-2 text-13 s1366:text-14 text-primary font-500 select-none">1/2
                </button>
                <button
                    onClick={() => setMoney(money / 3)}
                    className="inline-flex items-center justify-center max-w-full active:scale-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 w-8 s768:w-15 h-8 s1366:h-10 shrink-0 bg-layer5 rounded-2 text-13 s1366:text-14 text-primary font-500 select-none">1/3
                </button>
            </div>
            <div className="mb-6 mt-5">
                <div className="text-primary text-14 font-600 mb-3 capitalize">{t('time')}</div>
                <div className="min-h-10">
                    <div
                        className="relative text-12 text-secondary font-600 p-1 rounded-3 bg-layer3 light:bg-black/5 flex"
                        role="tablist" aria-orientation="horizontal">
                        {periods?.map((item: any, index: number) => (<button
                            key={`${index}-period`}
                            onClick={() => {
                                setActiveIndex(index)
                                setTimePeriod(periods[index])
                            }}
                            className="relative outline-none px-5 py-3 transition-colors duration-300 z-10 text-primary font-700 flex-1"
                            id="headlessui-tabs-tab-:ru:" role="tab" type="button" aria-selected="true"
                            data-headlessui-state="selected">
                            {activeIndex === index ?
                                <div className="absolute inset-0 z-0 rounded-2 bg-tab_selected"/> : <></>}
                            <div className="relative z-10">{item.period}s</div>
                        </button>))}
                    </div>
                </div>
            </div>
            <div className="text-primary text-15 font-500 mb-[10px]">
                {t('low_or_high')}
            </div>
            <div className="relative flex h-12 w-full rounded-2 overflow-hidden -space-x-2 mb-3">
                <button
                    onClick={() => {
                        setType(0)
                        if (oneClick) {
                            ref.current?.join({type: 0})
                        }
                    }}
                    className={`${type === 0 || !oneClick ? '' : 'opacity-50'}  inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none whitespace-nowrap font-500 capitalize disabled:pointer-events-none disabled:opacity-50 bg-transparent px-4 py-2 text-12 relative h-full flex-1 transition-colors shadow-none rounded-none text-white origin-left gap-3`}>
                    <div className="skew-x-[23deg] absolute inset-0 z-0 rounded-tr bg-down origin-bottom-right"></div>
                    <div className="relative flex items-center gap-2 s1024:gap-10 text-16">
                        <div>{timePeriod?.lossRate}%</div>
                        <div>{t('down')}</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="relative size-6" width="38" height="38"
                         viewBox="0 0 38 38" fill="none">
                        <path
                            d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                            fill="currentColor" fillOpacity="0.75"></path>
                    </svg>
                </button>
                <button
                    onClick={() => {
                        setType(1)
                        if (oneClick) {
                            ref.current?.join({type: 1})
                        }
                    }}
                    className={`${type === 1 || !oneClick ? '' : 'opacity-50'} inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none whitespace-nowrap font-500 capitalize disabled:pointer-events-none disabled:opacity-50 bg-transparent px-4 py-2 text-12 relative h-full flex-1 transition-colors shadow-none rounded-none text-white origin-right flex-row-reverse gap-3`}>
                    <div className="skew-x-[23deg] absolute inset-0 z-0 rounded-bl bg-up origin-top-left"></div>
                    <div className="relative flex items-center gap-2 s1024:gap-10 text-16">
                        <div>{timePeriod?.yieldRate}</div>
                        <div>{t('up')}</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="rotate-180 relative size-6" width="38"
                         height="38"
                         viewBox="0 0 38 38" fill="none">
                        <path
                            d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                            fill="currentColor" fillOpacity="0.75"></path>
                    </svg>
                </button>
            </div>
            {/*<button*/}
            {/*    onClick={() => {*/}
            {/*        ref.current?.join()*/}
            {/*    }}*/}
            {/*    className="flex-1 inline-flex items-center justify-center max-w-full active:scale-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-transparent px-4 py-2 rounded-2 text-18 font-800 text-black h-12 capitalize"*/}
            {/*    style={{*/}
            {/*        width: '100%',*/}
            {/*        background: 'linear-gradient(to right, rgb(var(--up)), #9FE871)',*/}
            {/*        boxShadow: '0px 0px 12px 0px rgb(var(--up) / 0.3), 0px -2px 0px 0px #1DCA6A inset'*/}
            {/*    }}>place*/}
            {/*    bet*/}
            {/*</button>*/}
            {oneClick ? <></> : <div style={{height: '48px', cursor: 'pointer'}}>
                <button
                    className="items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all whitespace-nowrap font-500 capitalize disabled:pointer-events-none disabled:opacity-50 bg-transparent h-12 px-4 text-14 flex w-full border py-0 shadow-none border-down text-down">
                    {t('place_order')}
                </button>
            </div>}
            <SendOrder amount={money} params={{symbol: timePeriod.symbol, period: timePeriod.period}} ref={ref}/>
            <div>
                <div
                    // onClick={() => ref.current?.join()}
                    onClick={() => {
                        setOneClick(!oneClick)
                    }}
                    className="flex items-center justify-between mt-4 pt-4 h-12 border-t border-input cursor-pointer">
                    <div>{t('1_click_trade')}</div>
                    {!oneClick ? <div className="relative h-6 w-11 rounded-full transition-all bg-brand">
                            <div className="absolute top-1 size-4 rounded-full transition-all shadow left-6 bg-white"></div>
                        </div> :
                        <div className="relative h-6 w-11 rounded-full transition-all bg-layer2">
                            <div
                                className="absolute top-1 left-1 size-4 rounded-full transition-all shadow bg-layer6"></div>
                        </div>}
                </div>
            </div>
        </div>
    </>
}
export default TimeBet
