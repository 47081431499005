const NumberShow = (text:number)=> {
    if(!text){
        return 0
    }
    if(text<0){
        return <div  className="text-down">{-text}</div>
    }
    return <div className="text-up">{text}</div>
}
export default NumberShow
