import {DialogProps} from "../constant/fields";
import Close from "./Close";
import {updateUser, uploadFile} from "../api";
import {useRef, useState} from "react";
import {useRecoilState} from "recoil";
import {refreshUserState} from "../store/user";
import {success} from "../util/toast";

const UploadAvatar = (props: DialogProps&{onConfirm:Function}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [url, setUrl] = useState<any>(null)
    const [res, setRes] = useState<any>(null)
    const [refreshUser,setRefreshUser] = useRecoilState(refreshUserState)
    const uploadFileAction = async (file: any) => {
        const formData = new FormData()
        formData.append('file', file)
        const {data} = await uploadFile(formData)
        setRes(data)
    }
    const changeFile = async (e: any) => {
        const file = inputRef.current?.files?.[0]
        if (file) {
            const url = URL.createObjectURL(file);
            setUrl(url)
            await uploadFileAction(file)
        }
    }
    const saveIcon = async()=>{
        await updateUser({icon:res})
        setRefreshUser(refreshUser+1)
        success("upload success")
        props.onCancel()
    }
    return <div className="fixed inset-0 z-modal bg-black/70 overflow-auto no-scrollbar overscroll-none"
                style={{left: '0px', opacity: 1, display: props.visible ? 'block' : 'none'}}>
        <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
            <div className="relative mx-5 py-6 bg-layer2 rounded-3 overflow-hidden w-[400px] h-[460px]">
                <div className="px-6 pb-5 text-20 font-500 text-primary capitalize">
                    <div className="flex items-center text-18 font-600 gap-2 cursor-pointer">
                        <svg
                            onClick={() => {
                                props.onCancel()
                            }}
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            className="text-primary w-4 h-4 rotate-180">
                            <path d="M10 7L15 12L10 17" stroke="currentColor" strokeWidth="2" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                        </svg>
                        <div>Upload Avatar</div>
                    </div>
                </div>
                <Close onCancel={props.onCancel}/>
                <div
                    className="px-6 pb-2 max-h-[60vh] overflow-auto overscroll-none no-scrollbar text-secondary text-12 font-500">
                    <div>
                        <label
                            className="flex-center aspect-square bg-layer2 border border-dashed border-main rounded-full cursor-pointer overflow-hidden w-28 mx-auto my-19">
                            <input onChange={(e) => {
                                changeFile(e)
                            }} ref={inputRef} className="w-0 h-0 overflow-hidden opacity-0" type="file"
                                   accept="image/*"/>
                            {url?<img src={url} alt=""/>:<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                  fill="none">
                                <path
                                    d="M8.63605 8.69468H0V11.2383H8.63605V20H11.4009V11.2383H20V8.69468H11.4009V0H8.63605V8.69468Z"
                                    fill="#26303E"></path>
                            </svg>}
                        </label>
                        <button
                            onClick={()=>{saveIcon()}}
                            className="items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap capitalize disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-12 px-4 py-3.5 text-14 flex w-72 mx-auto"
                        >done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default UploadAvatar
