import React from "react";
import {Link} from "react-router-dom";
import {menuIndexState, MenuListItem, menuState} from "../store/game";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {hideMenuNameState, langVisibleState, selectLangState, walletPanelState} from "../store/system";
import {MenuIconMap} from "../constant/fields";
import {useTranslation} from "react-i18next";

const Menu = () => {
    const activeClass = "h-12 flex items-center px-5 py-2 cursor-pointer text-secondary group hover:from-[#24ee89]/20 hover:to-[#24ee89]/10 bg-gradient-to-r from-[#24ee89]/20 to-[#24ee89]/10 from-0% to-100%"
    const inActiveClass = "h-12 flex items-center px-5 py-2 cursor-pointer text-secondary group bg-gradient-to-r hover:from-[#24ee89]/20 hover:to-[#24ee89]/10 from-0% to-100%"
    const activeTextClass = "text-14 truncate text-brand"
    const inActiveTextClass = "text-primary text-14 truncate"
    const menuData = useRecoilValue<MenuListItem[]>(menuState)
    const setWalletPanel = useSetRecoilState(walletPanelState)
    const [menuIndex] = useRecoilState(menuIndexState)
    const setLangVisible = useSetRecoilState(langVisibleState)
    const hideMenuName = useRecoilValue(hideMenuNameState)
    const selectLang = useRecoilValue(selectLangState)
    const {t} = useTranslation()
    const subMenus = [{
        name: t('wallet'),
        onClick() {
            setWalletPanel(true)
        },
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
            <path
                d="M14.6812 5.21551C14.7408 5.43734 14.7618 5.68113 14.7701 5.96887C14.8716 5.97725 14.9689 5.98771 15.0631 6.00026C15.6961 6.08502 16.2549 6.27022 16.7017 6.71701C17.1495 7.16275 17.3347 7.72044 17.4195 8.35139C17.5011 8.95198 17.5011 9.71162 17.5011 10.6324V12.0889C17.5011 13.0097 17.5011 13.7693 17.4195 14.3699C17.3347 15.0009 17.1495 15.5586 16.7017 16.0053C16.2549 16.4511 15.6961 16.6363 15.0631 16.721C14.4615 16.8016 13.6997 16.8016 12.7769 16.8016H8.58836C7.34531 16.8016 6.34397 16.8016 5.55607 16.6959C4.73784 16.5861 4.04935 16.3506 3.50107 15.8065C2.95488 15.2604 2.71945 14.5719 2.60854 13.7557C2.50391 12.971 2.50391 11.9707 2.50391 10.7308V5.23958C2.50391 4.11267 3.41841 3.19922 4.54845 3.19922H11.4595C12.0015 3.19922 12.4044 3.19922 12.7528 3.29234C13.6945 3.54346 14.429 4.2759 14.6812 5.21551ZM12.4002 4.6055C12.2484 4.56574 12.0434 4.55946 11.3643 4.55946H4.5495C4.46018 4.55946 4.37174 4.57705 4.28923 4.61123C4.20671 4.64541 4.13173 4.69551 4.06858 4.75866C4.00542 4.82182 3.95533 4.89679 3.92115 4.97931C3.88697 5.06182 3.86938 5.15026 3.86938 5.23958C3.86938 5.32889 3.88697 5.41733 3.92115 5.49985C3.95533 5.58236 4.00542 5.65734 4.06858 5.72049C4.13173 5.78365 4.20671 5.83375 4.28923 5.86793C4.37174 5.90211 4.46018 5.9197 4.5495 5.9197H12.7779L13.4047 5.92074C13.4049 5.80207 13.3915 5.68376 13.3649 5.56813C13.3028 5.33709 13.1809 5.12649 13.0116 4.95751C12.8422 4.78852 12.6313 4.66712 12.4002 4.6055ZM13.8378 10.0004C13.4678 10.0004 13.1129 10.1474 12.8512 10.4091C12.5895 10.6708 12.4425 11.0257 12.4425 11.3957C12.4425 11.7658 12.5895 12.1207 12.8512 12.3823C13.1129 12.644 13.4678 12.791 13.8378 12.791C14.2079 12.791 14.5628 12.644 14.8245 12.3823C15.0861 12.1207 15.2331 11.7658 15.2331 11.3957C15.2331 11.0257 15.0861 10.6708 14.8245 10.4091C14.5628 10.1474 14.2079 10.0004 13.8378 10.0004Z"
                fill="currentColor"></path>
        </svg>
    }, {
        name: t('My profile'),
        href: '/system/profile',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M9.84761 1.65039C7.60252 1.65039 5.77585 3.50412 5.77585 5.78176C5.77585 8.05941 7.60252 9.91313 9.84761 9.91313C12.0927 9.91313 13.9194 8.0598 13.9194 5.78176C13.9194 3.50333 12.0927 1.65039 9.84761 1.65039ZM11.5253 11.4223H8.47428C5.63938 11.4223 3.3335 13.7607 3.3335 16.6352V16.9446C3.3335 18.4478 5.60251 18.4478 8.47428 18.4478H11.5253C14.2845 18.4478 16.6668 18.4478 16.6668 16.9446V16.6352C16.6668 13.7611 14.3606 11.4223 11.5253 11.4223Z"
                fill="currentColor"></path>
        </svg>
    }, {
        name: t('setting'),
        href: '/system/setting',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M11.3195 2.02346C13.0689 2.40497 14.6171 3.32522 15.7815 4.60165C15.3926 5.22663 15.3521 6.04018 15.7459 6.72221C16.14 7.40491 16.8659 7.77672 17.6023 7.75167C17.8607 8.56429 18 9.43002 18 10.3283C18 11.2265 17.8607 12.0922 17.6024 12.905C16.8659 12.8798 16.14 13.2516 15.7459 13.9343C15.3521 14.6163 15.3925 15.4299 15.7814 16.0549C14.6171 17.3313 13.0689 18.2516 11.3195 18.6331L11.3195 18.6329C10.9729 17.9828 10.2881 17.5404 9.5 17.5404C8.71191 17.5404 8.02714 17.9828 7.68052 18.6329L7.6805 18.6331C5.93105 18.2516 4.38288 17.3313 3.21854 16.0549C3.60744 15.4299 3.64787 14.6163 3.25411 13.9343C2.85996 13.2516 2.13413 12.8798 1.39767 12.9049C1.13933 12.0923 1 11.2265 1 10.3283C1 9.43 1.13933 8.56429 1.39756 7.75156C2.13413 7.77672 2.85996 7.40491 3.25411 6.72221C3.64788 6.04018 3.60746 5.22661 3.21859 4.60161C4.38288 3.32522 5.93105 2.40497 7.6805 2.02344L7.68054 2.0237C8.02716 2.67372 8.71191 3.11614 9.5 3.11614C10.2853 3.11614 10.9679 2.67691 11.3157 2.03073L11.3195 2.02346ZM9.5 8.26766C8.36196 8.26766 7.43939 9.19022 7.43939 10.3283C7.43939 11.4663 8.36196 12.3889 9.5 12.3889C10.638 12.3889 11.5606 11.4663 11.5606 10.3283C11.5606 9.19022 10.638 8.26766 9.5 8.26766Z"
                fill="currentColor"></path>
        </svg>
    }, {
        name: t('language'),
        onClick() {
            setLangVisible(true)
        },
        renderName() {
            return `Language:${selectLang?.name}`
        },
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                   fill="none"
                   className="text-secondary group-hover:text-primary shrink-0 size-5">
            <path
                d="M14.2402 8.41797C14.2402 12.0941 11.2601 15.0742 7.58398 15.0742C3.90784 15.0742 0.927734 12.0941 0.927734 8.41797C0.927734 4.74182 3.90784 1.76172 7.58398 1.76172C11.2601 1.76172 14.2402 4.74182 14.2402 8.41797Z"
                stroke="#B3BEC1" strokeWidth="1.6875"></path>
            <path
                d="M10.4902 8.41797C10.4902 10.3865 10.0895 12.1269 9.48096 13.3439C8.84719 14.6115 8.12776 15.0742 7.58398 15.0742C7.04021 15.0742 6.32078 14.6115 5.68701 13.3439C5.07847 12.1269 4.67773 10.3865 4.67773 8.41797C4.67773 6.44949 5.07847 4.70907 5.68701 3.492C6.32078 2.22447 7.04021 1.76172 7.58398 1.76172C8.12776 1.76172 8.84719 2.22447 9.48096 3.492C10.0895 4.70907 10.4902 6.44949 10.4902 8.41797Z"
                stroke="#B3BEC1" strokeWidth="1.6875"></path>
            <line x1="1.02148" y1="8.51172" x2="14.1465" y2="8.51172" stroke="#B3BEC1"
                  strokeWidth="1.6875"></line>
        </svg>
    }]
    return <div className="bg-layer3" style={{width: hideMenuName ? '90px' : '240px'}}>
        <div className="p-4 flex-1">
            <nav className="flex flex-col gap-1" style={{opacity: 1, transform: 'none'}}>
                <div className="rounded-2 bg-layer4 overflow-hidden">
                    {menuData?.map((item, index) => (<Link to={item.url} key={`menu-${index}`}>
                        <div
                            className={menuIndex === item.url ? activeClass : inActiveClass}>
                            <div className="flex items-center h-full flex-1 gap-1.5">
                                {React.createElement(MenuIconMap[item.icon || ''], {active: menuIndex === item.url})}
                                {hideMenuName ? <></> :
                                    <div className={menuIndex === item.url ? activeTextClass : inActiveTextClass}>
                                        {t(item.name)}
                                    </div>}
                            </div>
                        </div>
                    </Link>))}
                </div>
                {subMenus.map((item, index) => <div key={`submenu-${index}`} className="rounded-2 bg-layer7 space-y-1 overflow-hidden">
                    {item.onClick ? <div key={`subMenu-${index}`}
                                         onClick={item.onClick}
                                         className="h-12 flex items-center px-5 py-2 cursor-pointer text-secondary group bg-gradient-to-r hover:from-[#24ee89]/20 hover:to-[#24ee89]/10 from-0% to-100%">
                        <div className="flex items-center h-full flex-1 gap-1.5">
                            {item?.icon}
                            {hideMenuName ? <></> : <div className="text-primary text-14 truncate">{item.renderName ? item.renderName() : item.name}</div>}
                        </div>
                    </div> : <Link to={item.href || ""} key={`subMenu-${index}`}>
                        <div
                            className="h-12 flex items-center px-5 py-2 cursor-pointer text-secondary group bg-gradient-to-r hover:from-[#24ee89]/20 hover:to-[#24ee89]/10 from-0% to-100%">
                            <div className="flex items-center h-full flex-1 gap-1.5">
                                {React.cloneElement(item.icon, {className: `text-secondary group-hover:text-primary shrink-0 size-5 ${menuIndex === item.href ? '!text-brand' : ''}`})}
                                {hideMenuName ? <></> : <div
                                    className={menuIndex === item.href ? activeTextClass : inActiveTextClass}>
                                    {item.renderName ? item.renderName() : item.name}
                                </div>}
                            </div>
                        </div>
                    </Link>}
                </div>)}
            </nav>
        </div>
    </div>
}
export default Menu
