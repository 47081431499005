import {atom} from "recoil";
import {CryptoVo, TableItem} from "../constant/fields";
import {Order} from "../util/order";

export interface Game {
    outId: string | null
}

export interface TimeUint {
    minTrade: number,
    fullTime: number
    unit: number
    symbol: string
    outId: string
}

export interface HighLowOrderUser {
    username: string;
    amount: number
}

export interface HighLowOrder {
    unit: number
    index: number
    period: string
    downOrderCount: number,
    downOrderAmount: number,
    upOrderCount: number,
    upOrderAmount: number,
    calcFlag: boolean,
    resultType?: string | null
    upUsers: HighLowOrderUser[]
    downUsers: HighLowOrderUser[]
}

export const orderFieldsState = atom<TableItem[]>({
    key: "orderFieldsState",
    default: []
})

export const gameTypeState = atom<"TimeContract"|"Leverage"|"Updown">({
    key: "gameTypeState",
    default: "TimeContract"
})

export const gameOrderState = atom({
    key:'gameOrders',
    default:[]
})

export interface SymbolVo {
    high: number
    low: number
    close: number
    open: number
    symbol: string
    icon: string
    change: number,
    grow?: false
}

export const symbolsState = atom<SymbolVo[]>({
    key: 'symbols',
    default: [{
        symbol: 'BTCUSD',
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        change: 1,
        icon: 'https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png',
        grow: false
    }]
})

export const selectSymbolState = atom<CryptoVo>({
    key: 'selectSymbol',
    default: {
        pair: 'BTCUSD',
        symbol: '',
        open: 0,
        high: 0,
        low: 0,
        icon: 'https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png',
        close: 0,
        change: 1,
    }
})

export const highLowOrderState = atom<HighLowOrder>({
    default: {
        unit: 0,
        resultType: null,
        calcFlag: false,
        index: 0,
        period: "",
        downOrderCount: 0,
        downOrderAmount: 0,
        upOrderCount: 0,
        upOrderAmount: 0,
        upUsers: [],
        downUsers: []
    },
    key: "high-low-order"
})

export const highLowOrderDetailState = atom<Order | null>({
    key: 'highLowOrderDetail',
    default: null
})

export interface MenuListItem {
    name: string
    icon: string
    gameId: string
    url: string
}

export interface TimePeriod {
    symbol: string | null
    period: number
    lossRate?: number
    yieldRate?: number
}

export const menuState = atom<MenuListItem[]>({
    key: "menu",
    default: []
})
export const menuIndexState = atom<string>({
    key: "menu-index",
    default: ""
})
export const gameState = atom<Game>({
    key: 'game',
    default: {outId: null},
});

export const timeUnitState = atom<TimeUint | null>({
    key: 'timeUnitStat',
    default: null,
});

export const timePeriodsState = atom<TimePeriod[]>({
    key: 'timePeriods',
    default: []
});

export const timePeriodState = atom<TimePeriod>({
    key: 'timePeriod',
    default: {symbol: 'BTCUSD', period: 10},
});

export const gameIdState = atom<string>({
    default: "0",
    key: 'gameId'
})
