import {formatTimeUnit} from "../util/number";

const TimeShow = ({currentTime,totalTime}:{currentTime:number,totalTime:number})=> {
    return <div className="items-center flex-row justify-end">
        <div className="text-14 mr-3 text-primary">00:{formatTimeUnit(currentTime)}</div>
        <div className="bg-warn/30"
             style={{position: 'relative', height: '4px', width: '64px', overflow: 'hidden', minWidth: '64px'}}>
            <div className="bg-warn"
                 style={{height: '4px', width: '100%', transform: `translate3d(-${100-currentTime*100/totalTime}%, 0px, 0px)`}}></div>
        </div>
    </div>
}
export default TimeShow
