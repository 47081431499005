import {orderStat} from "../api";

export interface Period{
    unit:number
    index:number,
    newFlag:boolean,
    fullTimeOfGame?:number
    inGameTime?:number
    isNotInGame?:boolean
}
let lastPeriod:string|null = null
function calcPeriod(unit:number,fullTime:number=30){
    const fullTimeOfGame = fullTime+unit+5
    const inGameTime = fullTime+unit
    const timeSecond = parseInt(`${new Date().getTime() / 1000}`)
    const currentTime = parseInt(`${timeSecond / fullTimeOfGame}}`)
    const index = timeSecond - currentTime * fullTimeOfGame
    const startTime = currentTime + fullTime
    const endTime = startTime+unit
    const period = "2024$currentTime$index"
    let newFlag=false
    if(period !== lastPeriod){
        newFlag = true
    }
    lastPeriod = period
    const isNotInGame = index>inGameTime
    return {unit,index,startTime,endTime,currentTime,period:period,newFlag,fullTimeOfGame,inGameTime,isNotInGame}
}
export interface Order{
    entryPrice:number
    exitPrice:number
    multiplier:number
    amount:number
    roi:number
    bust:number
    funding:number
    profit:number
    type:number
    calcFlag:boolean,
    visible:boolean
}
function calcROIAndPL(order:Order,exitPrice:number){
    if(order.calcFlag){
        return
    }
    order.exitPrice = exitPrice
    const entryPrice = order.entryPrice
    const multiplier = order.multiplier
    const amount = order.amount
    const rate = (exitPrice - entryPrice) / entryPrice
    const roi = rate * multiplier
    order.roi = roi
    const orderType = order.type
    order.funding = -0.000101
    order.bust = exitPrice - (orderType === 0 ? -1 : 1) * (exitPrice / multiplier)
    const pl =roi * amount
    order.profit = pl
}
export {
    calcPeriod,calcROIAndPL
}

export const leaderboardApi = async (gameId:string)=>{
    const {data} = await orderStat("APP_1730383883376")
    return data.map(([rowId,outId,time,profit,trades,roi,winning,gameId]:any)=>({rowId,outId,time,profit,trades,roi,winning,gameId}))
}
