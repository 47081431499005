export const WalletIcon = ()=> {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="currentColor"
                className="text-primary">
        <path
            d="M22.35 17.4805C21.9 17.4805 21.6 17.7805 21.6 18.2305V21.5305H1.8V6.98047H21.6V10.1305C21.6 10.5805 21.9 10.8805 22.35 10.8805C22.8 10.8805 23.1 10.5805 23.1 10.1305V7.13047C23.1 6.23047 22.35 5.48047 21.45 5.48047H20.25C19.8 3.83047 18.3 2.48047 16.5 2.48047H3.9C1.8 2.48047 0 4.28047 0 6.38047V6.98047V7.13047V21.5305C0 22.4305 0.75 23.1805 1.65 23.1805H21.45C22.35 23.1805 23.1 22.4305 23.1 21.5305V18.2305C23.1 17.9305 22.8 17.4805 22.35 17.4805ZM3.9 4.13047H16.5C17.4 4.13047 18.15 4.73047 18.6 5.48047H1.95C2.25 4.73047 3 4.13047 3.9 4.13047Z"
            fill="currentColor"></path>
        <path
            d="M21.15 11.4805H17.1C15.6 11.4805 14.25 12.6805 14.25 14.3305C14.25 15.9805 15.45 17.1805 17.1 17.1805H21.15C22.65 17.1805 24 15.9805 24 14.3305C24 12.6805 22.8 11.4805 21.15 11.4805ZM21.15 15.5305H17.1C16.5 15.5305 15.9 15.0805 15.9 14.3305C15.9 13.7305 16.35 13.1305 17.1 13.1305H21.15C21.75 13.1305 22.35 13.5805 22.35 14.3305C22.35 14.9305 21.9 15.5305 21.15 15.5305Z"
            fill="currentColor"></path>
        <path
            d="M16.5 14.3311C16.5 14.53 16.579 14.7207 16.7197 14.8614C16.8603 15.002 17.0511 15.0811 17.25 15.0811C17.4489 15.0811 17.6397 15.002 17.7803 14.8614C17.921 14.7207 18 14.53 18 14.3311C18 14.1321 17.921 13.9414 17.7803 13.8007C17.6397 13.6601 17.4489 13.5811 17.25 13.5811C17.0511 13.5811 16.8603 13.6601 16.7197 13.8007C16.579 13.9414 16.5 14.1321 16.5 14.3311Z"
            fill="currentColor"></path>
    </svg>

}
export default WalletIcon
