const Close = ({onCancel}: { onCancel: Function }) => {
    return <svg onClick={() => {
            onCancel()
        }}
        className="absolute z-10 w-6 h-6 right-4 rounded-full text-secondary hover:text-primary transition-colors cursor-pointer top-6"
        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
            fill="currentColor"></path>
    </svg>
}
export default Close
