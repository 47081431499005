import {CanvasRenderingTarget2D} from "fancy-canvas";
import {
    IChartApi,
    ISeriesPrimitive, ISeriesPrimitiveAxisView, ISeriesPrimitivePaneRenderer,
    ISeriesPrimitivePaneView, SeriesAttachedParameter,
    SeriesPrimitivePaneViewZOrder,
    Time
} from "lightweight-charts";
import startFlag from '../../static/flag.png'
import endFlag from '../../static/flag-fill.png'

export class ShowFlagRender implements ISeriesPrimitivePaneRenderer {
    _source: ShowFlag
    _view: ShowFlagPaneView

    constructor(source: ShowFlag, view: ShowFlagPaneView) {
        this._source = source
        this._view = view
    }


    draw(target: CanvasRenderingTarget2D): void {
        if (!this._source._chart) {
            return
        }
        const height = this._source._chart.paneSize().height
        const y1 = height*0.05
        target.useMediaCoordinateSpace(scope => {
            const ctx = scope.context;
            if (this._source._startImg && this._view._startXs && this._view._startXs.length>0) {
                const img = this._source._startImg
                console.log(this._view._startXs)
                this._view._startXs.forEach((e:any)=>{
                    ctx.drawImage(
                        img,
                        e,
                        y1,
                        20,
                        20
                    );
                })
            }

            if (this._source._endImg && this._view._endXs && this._view._startXs.length>0) {
                const img = this._source._endImg
                console.log(this._view._endXs)
                this._view._endXs.forEach((e:any)=>{
                    ctx.drawImage(
                        img,
                        e,
                        y1,
                        20,
                        20
                    );
                })
            }

        });
    }

}

export class ShowFlagPaneView implements ISeriesPrimitivePaneView {
    _source: ShowFlag
    _startXs: any = null
    _endXs: any = null

    constructor(source: ShowFlag) {
        this._source = source
    }

    zOrder(): SeriesPrimitivePaneViewZOrder {
        return 'bottom';
    }

    update() {
        if (!this._source._chart) {
            return
        }
        const timeScale = this._source._chart.timeScale()
        if (this._source._startTimes && this._source._startTimes.length>0) {
            const posData:any = []
            this._source._startTimes.forEach((e)=>{
                const sPos: any = timeScale.timeToCoordinate(e)
                if(sPos!=null) {
                    posData.push(sPos)
                }
            })
            this._startXs = posData
        }
        if (this._source._endTimes && this._source._endTimes.length>0) {
            const posData:any = []
            this._source._endTimes.forEach((e)=>{
                const sPos: any = timeScale.timeToCoordinate(e)
                if(sPos!=null) {
                    posData.push(sPos)
                }
            })
            this._endXs = posData
        }
    }

    renderer() {
        return new ShowFlagRender(this._source, this);
    }

}

interface Placement {
    x: number;
    y: number;
    height: number;
    width: number;
}

export class ShowFlag implements ISeriesPrimitive<Time> {
    _startTime: any = null
    _endTime: any = null
    _startTimes:any[]=[]
    _endTimes:any[]=[]
    _startTPos: Placement | null = null;
    _endTPos: Placement | null = null;
    _paneViews: ShowFlagPaneView[]
    _chart: IChartApi | null = null
    _startImg: HTMLImageElement | null = null
    _endImg: HTMLImageElement | null = null
    _containerElement: HTMLElement | null = null;
    _requestUpdate?: () => void;

    constructor() {
        this._paneViews = [new ShowFlagPaneView(this)]
    }

    setCalcPeriod(result: { startTime: number, endTime: number }) {
        if(this._startTime!==result.startTime){
            this._startTimes = [result.startTime]
        }
        if(this._endTime!==result.endTime){
            this._endTimes = [result.endTime]
        }
        this._startTime = result.startTime
        this._endTime = result.endTime
    }


    attached({chart, requestUpdate}: SeriesAttachedParameter<Time>) {
        this._chart = chart
        this._containerElement = chart.chartElement();
        this._startImg = new Image();
        this._requestUpdate = requestUpdate;
        this._startImg.onload = () => {
            this.requestUpdate();
        };
        this._startImg.src = startFlag;
        this._endImg = new Image();
        this._endImg.onload = () => {
            this.requestUpdate();
        };
        this._endImg.src = endFlag;
    }

    detached(): void {
        this._startImg = null;
        this._endImg = null;
    }

    requestUpdate(): void {
        if (this._requestUpdate) {
            this._requestUpdate()
        }
    }


    paneViews(): readonly ISeriesPrimitivePaneView[] {
        return this._paneViews;
    }

    updateAllViews(): void {
        this._paneViews.forEach(pv => pv.update());
    }


}
