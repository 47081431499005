import {useRecoilState, useRecoilValue} from "recoil";
import {menuIndexState, MenuListItem, menuState} from "../store/game";
import {Link} from "react-router-dom";
import React from "react";
import {MenuIconMap} from "../constant/fields";

const MobileMenu = () => {
    const [menuIndex] = useRecoilState(menuIndexState)
    const menuData = useRecoilValue<MenuListItem[]>(menuState)
    return <nav className="fixed bottom-0 left-0 right-0 z-30 bg-layer4 flex items-center gap-2.5 py-1.5"
                style={{boxShadow: 'rgba(0, 0, 0, 0.25) 0px -2px 4px 0px'}}>
        {menuData?.map((item, index) =>
            <Link key={`${index}-menu`} to={item.url} className={`flex-1 items-center ${menuIndex===item.url?'text-success':''}`}>
                <div className="flex flex-col gap-1 items-center justify-center">
                    <span className="size-7 flex-center">
                        {React.createElement(MenuIconMap[item.icon || ''], {active: menuIndex === item.url})}
                    </span>
                    <div className="text-10 text-nowrap">{item.name}</div>
                </div>
            </Link>)
        }
    </nav>
}
export default MobileMenu
