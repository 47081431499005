import {DialogProps} from "../constant/fields";

const LeverageOutDetail = (props:DialogProps)=> {
    return <div className="fixed inset-0 z-modal bg-black/70 overflow-auto no-scrollbar overscroll-none"
                style={{left: '0px', opacity: 1,display:props.visible?'block':'none'}}>
        <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
            <div className="relative mx-5 py-6 bg-layer2 rounded-3 overflow-hidden w-[400px]">
                <div className="px-6 pb-5 text-16 s768:text-20 font-500 text-primary capitalize">DETAILS</div>
                <svg
                    className="absolute z-10 w-6 h-6 right-6 rounded-full text-secondary hover:text-primary transition-colors cursor-pointer top-6"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                        fill="currentColor"></path>
                </svg>
                <div
                    className="px-6 max-h-[80vh] overflow-auto overscroll-none no-scrollbar text-secondary text-12 font-500">
                    <div className="relative h-[350px]">
                        <div className="text-secondary text-12 mb-3">10/27/2024, 11:55:24 PM</div>
                        <div className="grid gap-3 grid-cols-2 mb-3">
                            <div className="flex items-center gap-1 flex-1">
                                <div className="flex items-center"><span
                                    className="block overflow-hidden size-5 rounded-full -mr-2.5 z-10 lazy-load-image-background  lazy-load-image-loaded"><img
                                    src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/LTC.png"
                                    className="w-full h-full object-cover"/></span><span
                                    className="block overflow-hidden size-5 rounded-full lazy-load-image-background  lazy-load-image-loaded"><img
                                    src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/USD.png"
                                    className="w-full h-full object-cover"/></span></div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-up UpDownIcon" width="23"
                                     height="24" viewBox="0 0 23 24" fill="none">
                                    <path
                                        d="M11.3998 6.24695L5.60645 12.0402L6.92637 13.3602L11.3998 8.88681L15.8731 13.3602L17.1931 12.0402L11.3998 6.24695ZM11.3998 11.5202L5.60645 17.3134L6.92637 18.6335L11.3998 14.1601L15.8731 18.6335L17.1931 17.3134L11.3998 11.5202Z"
                                        fill="currentColor"></path>
                                </svg>
                            </div>
                            <div className="flex items-center flex-1"><span
                                className="block overflow-hidden size-5 mr-1 lazy-load-image-background  lazy-load-image-loaded"><img
                                src="https://currency-trade.s3.ap-east-1.amazonaws.com/avatar/user/Avatar-1.png"
                                className="w-full h-full object-cover"/></span>
                                <div className="text-primary text-13 flex-1 truncate">ABfTV</div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">ENTRY PRICE</div>
                                <div className="text-primary">70.4810</div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">BUST PRICE</div>
                                <div className="text-primary">70.2602</div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">WAGER AMOUNT</div>
                                <div className="text-primary">1.00098 USDT</div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">PROFIT &amp; LOSS</div>
                                <div className="text-primary">
                                    <div className="text-up">2.237858</div>
                                </div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">MULTIPLIER</div>
                                <div className="text-primary">x285</div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">ROI</div>
                                <div className="text-primary">
                                    <div className="text-up">223.57%</div>
                                </div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">FUNDING/HOUR</div>
                                <div className="text-primary">-</div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">STATUS</div>
                                <div className="text-primary">Open</div>
                            </div>
                            <div className="text-12 flex-1">
                                <div className="text-secondary">ID</div>
                                <div className="text-primary">891496894187521</div>
                            </div>
                        </div>
                        <button
                            className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap font-500 capitalize disabled:pointer-events-none disabled:opacity-50 h-9 px-4 text-12 w-1/2 rounded py-1 bg-layer5 light:bg-layer7 text-theme">CASH
                            OUT
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default LeverageOutDetail
