export const showNumber6 = (value:number,length:number=6)=>{
    if(!value){
        return 0
    }
    return value.toLocaleString('zh-CN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: length
    })
}

export function formatTimeUnit(unit:number) {
    return unit < 10 ? '0' + unit : unit;
}
