import {DialogProps} from "../constant/fields";
import {useRecoilState, useRecoilValue} from "recoil";
import {refreshUserState, userInfoState} from "../store/user";
import {useEffect, useState} from "react";
import {updateUser} from "../api";
import {success} from "../util/toast";

const UpdateName = (props:DialogProps)=> {
    const userInfo = useRecoilValue(userInfoState)
    const [username, setUsername] = useState<any>()
    const [refreshUser,setRefreshUser] = useRecoilState(refreshUserState)
    const changeUsername = (e:any)=>{
        setUsername(e.target.value)
    }
    const updateUsername =async  ()=>{
        await updateUser({username})
        success("update success")
        setRefreshUser(refreshUser+1)
        props.onCancel()
    }
    useEffect(() => {
        setUsername(userInfo?.username||'')
    }, [userInfo]);
    return <div className="fixed inset-0 z-modal bg-black/70 overflow-auto no-scrollbar overscroll-none"
                style={{left: '0px', opacity: 1,display:props.visible?'block':'none'}}>
        <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
            <div className="relative mx-5 py-6 bg-layer2 rounded-3 overflow-hidden w-[400px] h-[460px]">
                <div className="px-6 pb-5 text-20 font-500 text-primary capitalize">
                    <div className="inline-flex items-center text-18 font-600 gap-2 cursor-pointer">
                        <svg onClick={()=>{props.onCancel()}}
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             className="text-primary w-4 h-4 rotate-180">
                            <path d="M10 7L15 12L10 17" stroke="currentColor" strokeWidth="2" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                        </svg>
                        <div>Edit Name</div>
                    </div>
                </div>
                <svg
                    className="absolute z-10 w-6 h-6 right-4 rounded-full text-secondary hover:text-primary transition-colors cursor-pointer top-6"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                        fill="currentColor"></path>
                </svg>
                <div
                    className="px-6 pb-2 max-h-[60vh] overflow-auto overscroll-none no-scrollbar text-secondary text-12 font-500">
                    <div className="mt-2"><input
                        onChange={changeUsername}
                        className="flex h-12 px-2 rounded-2 bg-layer2 placeholder:text-quarterary outline-none shadow-sm focus:ring focus:ring-brand transition-all disabled:cursor-not-allowed disabled:opacity-50 w-full mb-20"
                        autoComplete="off" placeholder="Enter your name" value={username}/>
                        <button
                            onClick={updateUsername}
                            className="items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap capitalize disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-12 px-4 py-3.5 text-14 flex w-72 mx-auto"
                            >done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default UpdateName
