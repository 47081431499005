import {useRecoilValue} from "recoil";
import {menuIndexState} from "../store/game";

const Transaction = () => {
    const menuIndex = useRecoilValue(menuIndexState)
    const menus = [{
        url: '/trade/transaction',
        name: 'transaction'
    }, {
        url: '/trade/bet-history',
        name: 'bet history'
    }, {
        url: '/trade/other',
        name: 'other'
    }]
    return <div className="flex flex-col flex-1 overflow-auto">
        <main className="flex-1 bg-layer2 p-4">
            <div className="mx-auto pt-2 pb-9" style={{maxWidth: '1200px'}}>
                <div className="flex items-center gap-2 mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                            d="M14.046 10.5087H5.86207C5.49425 10.5087 5.12644 10.2312 5.12644 9.76879C5.12644 9.39884 5.49425 9.0289 5.86207 9.0289H14.046C14.4138 9.0289 14.7816 9.30636 14.7816 9.6763C14.7816 10.0462 14.5057 10.5087 14.046 10.5087ZM14.046 14.0231H5.86207C5.49425 14.0231 5.12644 13.7457 5.12644 13.2832C5.12644 12.9133 5.49425 12.5434 5.86207 12.5434H14.046C14.4138 12.5434 14.7816 12.8208 14.7816 13.2832C14.7816 13.7457 14.5057 14.0231 14.046 14.0231ZM5.12644 6.34682C5.12644 5.97688 5.49425 5.60694 5.86207 5.60694H11.0115C11.3793 5.60694 11.7471 5.88439 11.7471 6.25434C11.7471 6.62428 11.4713 6.99422 11.1034 6.99422H5.86207C5.49425 7.0867 5.12644 6.71676 5.12644 6.34682ZM16.5287 2H3.47126C2.64368 2 2 2.6474 2 3.38728V16.6127C2 17.4451 2.64368 18 3.47126 18H16.5287C17.3563 18 18 17.3526 18 16.6127V3.38728C17.908 2.6474 17.2644 2 16.5287 2Z"
                            fill="currentColor"></path>
                    </svg>
                    <div className="text-18 capitalize">transactions</div>
                </div>
                <div className="flex gap-3">
                    <div className="w-[230px] self-start p-2 rounded-3 bg-layer4 border-input flex flex-col gap-1">
                        {menus.map((item, index) =>
                            <a key={`${index}-profile-menu`}
                               className={`h-10 px-3 py-1 capitalize text-14 flex items-center rounded-2 hover:bg-layer5 ${menuIndex === item.url ? 'text-primary bg-layer5' : 'text-secondary'}  font-600`}
                               href={item.url}>{item.name}</a>)}
                    </div>
                    <div className="flex-1">
                        <div className="p-3 s768:p-8 bg-layer4 rounded-3">
                            <div className="grid s1024:grid-cols-4 grid-cols-2 gap-1.5 mb-3">
                                <div className="relative flex items-center" data-headlessui-state="">
                                    <button
                                        className="font-600 pl-3 pr-1 py-1 rounded-2 border border-input bg-layer3 w-full outline-none flex items-center gap-2.5 h-[42px] text-14"
                                        id="headlessui-listbox-button-:rh:" type="button" aria-haspopup="listbox"
                                        aria-expanded="false" data-headlessui-state="">
                                        <div className="truncate capitalize flex-1 text-left text-primary">deposits
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none"
                                             className="bg-layer5 p-1 rounded-1.5 rotate-90 text-primary size-8">
                                            <path d="M10 7L15 12L10 17" stroke="currentColor" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="relative flex items-center" data-headlessui-state="">
                                    <button
                                        className="font-600 pl-3 pr-1 py-1 rounded-2 border border-input bg-layer3 w-full outline-none flex items-center gap-2.5 h-[42px] text-14"
                                        id="headlessui-listbox-button-:ri:" type="button" aria-haspopup="listbox"
                                        aria-expanded="false" data-headlessui-state="">
                                        <div className="truncate capitalize flex-1 text-left text-primary">
                                            <div className="capitalize">all assets</div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none"
                                             className="bg-layer5 p-1 rounded-1.5 rotate-90 text-primary size-8">
                                            <path d="M10 7L15 12L10 17" stroke="currentColor" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="relative flex items-center" data-headlessui-state="">
                                    <button
                                        className="font-600 pl-3 pr-1 py-1 rounded-2 border border-input bg-layer3 w-full outline-none flex items-center gap-2.5 h-[42px] text-14"
                                        id="headlessui-listbox-button-:rj:" type="button" aria-haspopup="listbox"
                                        aria-expanded="false" data-headlessui-state="">
                                        <div className="truncate capitalize flex-1 text-left text-primary">all status
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none"
                                             className="bg-layer5 p-1 rounded-1.5 rotate-90 text-primary size-8">
                                            <path d="M10 7L15 12L10 17" stroke="currentColor" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="relative flex items-center" data-headlessui-state="">
                                    <button
                                        className="font-600 pl-3 pr-1 py-1 rounded-2 border border-input bg-layer3 w-full outline-none flex items-center gap-2.5 h-[42px] text-14"
                                        id="headlessui-listbox-button-:rk:" type="button" aria-haspopup="listbox"
                                        aria-expanded="false" data-headlessui-state="">
                                        <div className="truncate capitalize flex-1 text-left text-primary">past 24
                                            hours
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none"
                                             className="bg-layer5 p-1 rounded-1.5 rotate-90 text-primary size-8">
                                            <path d="M10 7L15 12L10 17" stroke="currentColor" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="relative max-h-[inherit] h-50">
                                <div className="h-full overflow-auto">
                                    <table
                                        className="table-fixed border-separate border-spacing-y-md w-full text-primary font-500 text-14">
                                        <thead>
                                        <tr className="text-secondary capitalize">
                                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                                align="left" style={{width: '70px'}}>type
                                            </th>
                                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                                align="left" style={{width: '90px'}}>time
                                            </th>
                                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                                align="right" style={{width: '120px'}}>amount
                                            </th>
                                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                                align="left" style={{width: '140px'}}>order id
                                            </th>
                                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                                align="left" style={{width: '90px'}}>status
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="relative"></tbody>
                                    </table>
                                </div>
                                <div className="abs-center text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                         viewBox="0 0 151 113" fill="none">
                                        <g filter="url(#filter0_d_236_11518)">
                                            <rect x="16" y="8" width="91.6031" height="62.2901" rx="5.49618"
                                                  className="fill-layer3"></rect>
                                            <rect x="25.1604" y="23.5723" width="45.8015" height="5.49618" rx="2.74809"
                                                  className="fill-layer5 light:fill-layer7"></rect>
                                            <rect x="25.1604" y="40.9769" width="73.2824" height="5.49618" rx="2.74809"
                                                  className="fill-layer5 light:fill-layer7"></rect>
                                        </g>
                                        <g filter="url(#filter1_d_236_11518)">
                                            <rect x="44" y="36" width="91.6031" height="62.2901" rx="5.49618"
                                                  className="fill-layer3"></rect>
                                            <rect x="53.1604" y="51.5725" width="45.8015" height="5.49618" rx="2.74809"
                                                  className="fill-layer5 light:fill-layer7"></rect>
                                            <rect x="53.1604" y="68.9771" width="73.2824" height="5.49618" rx="2.74809"
                                                  className="fill-layer5 light:fill-layer7"></rect>
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_236_11518" x="0" y="0" width="123.603" height="94.29"
                                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                                <feColorMatrix in="SourceAlpha" type="matrix"
                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                               result="hardAlpha"></feColorMatrix>
                                                <feOffset dy="8"></feOffset>
                                                <feGaussianBlur stdDeviation="8"></feGaussianBlur>
                                                <feComposite in2="hardAlpha" operator="out"></feComposite>
                                                <feColorMatrix type="matrix"
                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
                                                <feBlend mode="normal" in2="BackgroundImageFix"
                                                         result="effect1_dropShadow_236_11518"></feBlend>
                                                <feBlend mode="normal" in="SourceGraphic"
                                                         in2="effect1_dropShadow_236_11518" result="shape"></feBlend>
                                            </filter>
                                            <filter id="filter1_d_236_11518" x="27.084" y="18.1679" width="123.603"
                                                    height="94.29" filterUnits="userSpaceOnUse"
                                                    color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                                <feColorMatrix in="SourceAlpha" type="matrix"
                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                               result="hardAlpha"></feColorMatrix>
                                                <feOffset dx="-0.916031" dy="-1.83206"></feOffset>
                                                <feGaussianBlur stdDeviation="8"></feGaussianBlur>
                                                <feComposite in2="hardAlpha" operator="out"></feComposite>
                                                <feColorMatrix type="matrix"
                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
                                                <feBlend mode="normal" in2="BackgroundImageFix"
                                                         result="effect1_dropShadow_236_11518"></feBlend>
                                                <feBlend mode="normal" in="SourceGraphic"
                                                         in2="effect1_dropShadow_236_11518" result="shape"></feBlend>
                                            </filter>
                                        </defs>
                                    </svg>
                                    <div className="text-quarterary text-13 capitalize">no data available</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end py-3"></div>
                    </div>
                </div>
            </div>
        </main>
        <footer className="pt-8 pb-6 px-10 bg-layer1">
            <div className="max-w-[1200px] mx-auto">
                <div className="flex s768:justify-center s1024:justify-between flex-wrap gap-4 mb-8">
                    <div className="flex flex-col gap-2.5">
                        <div className="font-600 text-14 leading-5 mb-4">Trading Center</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">High Low</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">1000 Leverage</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Up Down</div>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <div className="font-600 text-14 leading-5 mb-4">Profile</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Personal</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Setting</div>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <div className="font-600 text-14 leading-5 mb-4">Overview</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Platform</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Description</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Agreement</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">AML &amp; KYC Policy
                        </div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Privacy Policy</div>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <div className="font-600 text-14 leading-5 mb-4">Service Guide</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">DeTrade Overview</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Deposits And
                            Withdrawals
                        </div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Partner Program</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">License</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">Connect</div>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <div className="font-600 text-14 leading-5 mb-4">Language</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">English</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">한국어</div>
                        <div className="text-14 text-secondary hover:text-brand cursor-pointer">日本语</div>
                        <div
                            className="relative flex items-center gap-1 cursor-pointer text-secondary hover:text-brand">
                            <div className="text-14 cursor-pointer">more</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                 fill="currentColor">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M11.6572 12.0707C11.8839 12.0707 12.0687 11.8859 12.0687 11.6592V7.47715H13.3341V11.6676C13.3291 12.5881 12.5778 13.3341 11.6572 13.3341H4.32381C3.40671 13.3238 2.66602 12.5759 2.66602 11.6573V4.32729C2.67478 3.41057 3.42424 2.66797 4.34287 2.66797H8.52299V3.93332H4.34287C4.11616 3.93332 3.93137 4.11811 3.93137 4.34482V11.6592C3.93137 11.8859 4.11616 12.0707 4.34287 12.0707H11.6572ZM9.56729 3.93332V2.66797H13.3344V6.43507H12.069V4.82947L6.73825 10.1584L5.844 9.26411L11.1729 3.93332H9.56729Z"
                                      fill="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <hr className="my-10 border-thirdly"/>
                <div className="flex flex-col items-center gap-3">
                    <div className="flex items-center gap-1.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="31" viewBox="0 0 29 31" fill="none"
                             className="w-7 h-8">
                            <path
                                d="M5.0273 8.4475C5.98397 8.03737 7.05054 7.84026 8.14296 7.79893V7.78939H21.0885C21.0885 7.78939 21.0885 14.7479 21.0885 18.153C21.0885 18.8467 21.0995 20.1423 20.7632 20.9712C20.427 21.8001 19.8387 22.2393 19.8387 22.2393H21.0885H21.0917C25.1544 22.2393 26.9287 19.9152 27.1937 19.5305C28.1827 17.9218 28.6643 16.2686 28.7677 14.1925C28.9067 11.3788 28.831 2.02187 28.831 0.505562V0.000126779H28.1666C27.7638 0.000126779 24.9682 0.000126497 22.9187 0.0108932C20.8638 0.0108932 19.0268 0.0387392 17.5061 0.0732499C14.106 0.146374 12.4027 0.788591 9.65877 3.11583C7.94903 4.56559 5.04346 7.49371 3.5729 9.14694C3.19152 9.57615 2.85216 9.99582 2.54835 10.4155C3.24646 9.48077 4.01568 8.87988 5.0273 8.44432V8.4475Z"
                                fill="url(#0.rjr8s1eo0c8)"></path>
                            <path
                                d="M24.5196 22.5525C23.5629 22.9626 22.4963 23.1597 21.4039 23.2011V23.2106H8.45839C8.45839 23.2106 8.45839 16.2521 8.45839 12.847C8.45839 12.1533 8.44742 10.8577 8.78363 10.0288C9.11985 9.19988 9.70822 8.76074 9.70822 8.76074H8.45839H8.45516C4.39252 8.76074 2.61815 11.0848 2.35313 11.4695C1.36414 13.0782 0.882566 14.7314 0.779142 16.8075C0.640165 19.6212 0.71592 28.9781 0.715921 30.4944V30.9999H1.3803C1.78307 30.9999 4.5787 30.9999 6.62821 30.9891C8.6831 30.9891 10.52 30.9613 12.0408 30.9268C15.4409 30.8536 17.1441 30.2114 19.8881 27.8842C21.5978 26.4344 24.5034 23.5063 25.974 21.8531C26.3554 21.4239 26.6947 21.0042 26.9985 20.5845C26.3004 21.5192 25.5312 22.1201 24.5196 22.5557V22.5525Z"
                                fill="url(#0.o36lemn96og)"></path>
                            <defs>
                                <linearGradient id="0.rjr8s1eo0c8" x1="22.3344" y1="24.067" x2="1.51585" y2="-4.21042"
                                                gradientUnits="userSpaceOnUse">
                                    <stop offset="0.0350515" stop-color="#00FFA8"></stop>
                                    <stop offset="0.765728" stop-color="#32A2E7"></stop>
                                </linearGradient>
                                <linearGradient id="0.o36lemn96og" x1="2.35744" y1="10.0288" x2="14.2868" y2="31.5698"
                                                gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#3700FF"></stop>
                                    <stop offset="1" stop-color="#8779FF"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="106" height="21" viewBox="0 0 106 21" fill="#fff"
                             className="">
                            <g clip-path="url(#clip0_2280_5769)">
                                <path
                                    d="M0.146484 20.2162V0.470703H8.3519C9.78695 0.470703 11.1042 0.710983 12.2975 1.19154C13.4909 1.6721 14.5241 2.3534 15.3912 3.23544C16.2582 4.12053 16.9229 5.16377 17.3912 6.36517C17.8594 7.56657 18.089 8.89267 18.089 10.3404C18.089 11.7882 17.8534 13.1173 17.3912 14.334C16.9229 15.5445 16.2552 16.5908 15.3912 17.4637C14.5241 18.3366 13.4909 19.0179 12.2975 19.4924C11.1042 19.9729 9.78695 20.2132 8.3519 20.2132H0.146484V20.2162ZM4.90478 15.9885H8.20991C8.99239 15.9885 9.69329 15.8517 10.3096 15.5779C10.9229 15.3072 11.4546 14.924 11.8927 14.4343C12.3307 13.9446 12.6691 13.3515 12.9138 12.6581C13.1555 11.9646 13.2764 11.189 13.2764 10.3435C13.2764 9.49793 13.1585 8.72843 12.9138 8.02888C12.6721 7.33541 12.3307 6.74232 11.8927 6.25263C11.4546 5.76295 10.9259 5.3858 10.3096 5.10902C9.69631 4.83833 8.99239 4.69842 8.20991 4.69842H4.90478V15.9855V15.9885Z"
                                    fill="currentColor"></path>
                                <path d="M51.7751 5.23633H47.2676V20.2128H51.7751V5.23633Z" fill="currentColor"></path>
                                <path
                                    d="M56.6046 9.41527H51.4082L51.8463 8.32336C52.5925 6.45891 54.3901 5.23926 56.384 5.23926H58.2783L56.6046 9.41527Z"
                                    fill="currentColor"></path>
                                <path
                                    d="M44.043 15.2157C43.2303 16.3228 41.9433 17.0436 40.8436 16.7516C39.6654 16.4323 39.2304 15.678 39.2304 13.9656V9.05963H44.4569V5.23948H39.2304V1.46191H34.75V13.5732C34.75 16.4931 35.3905 18.2998 37.2122 19.565C38.753 20.6326 41.3603 20.8607 43.4056 20.0152C45.327 19.2244 46.2364 18.026 46.2364 18.026L44.04 15.2187L44.043 15.2157Z"
                                    fill="currentColor"></path>
                                <path
                                    d="M68.3273 4.92969V6.48695C67.424 5.51366 65.8742 4.92969 63.9799 4.92969C61.4029 4.92969 57.3848 6.05505 57.3848 12.7129C57.3848 19.3708 61.2337 20.3593 63.844 20.4719C65.6597 20.5479 67.282 19.8848 68.3334 18.8872V20.2194H72.7865V4.92969H68.3304H68.3273ZM65.1159 16.8038C62.9648 16.8038 61.7715 15.3347 61.7715 12.646C61.7715 9.95732 62.9648 8.48827 65.1159 8.48827C67.2669 8.48827 68.4603 9.95732 68.4603 12.646C68.4603 15.3347 67.2669 16.8038 65.1159 16.8038Z"
                                    fill="currentColor"></path>
                                <path
                                    d="M85.2677 1.45898V6.48966C84.3674 5.51637 82.8205 4.9324 80.9203 4.9324C78.3493 4.9324 74.3281 6.05776 74.3281 12.7157C74.3281 19.3735 78.171 20.362 80.7813 20.4746C82.594 20.5506 84.2133 19.8876 85.2647 18.8899V20.2221H89.7178V1.46203H85.2647L85.2677 1.45898ZM82.0562 16.8004C79.9052 16.8004 78.7118 15.3314 78.7118 12.6427C78.7118 9.95395 79.9052 8.4849 82.0562 8.4849C84.2073 8.4849 85.4006 9.95395 85.4006 12.6427C85.4006 15.3314 84.2073 16.8004 82.0562 16.8004Z"
                                    fill="currentColor"></path>
                                <path
                                    d="M105.091 13.6983C105.091 13.6983 105.335 12.5546 105.299 11.3928C105.242 9.48879 104.314 4.86872 98.2961 4.81398C92.3928 4.75923 90.7402 9.20593 90.7402 12.798C90.7402 15.6053 92.0514 18.4917 95.0151 19.7783C98.8943 21.4602 103.366 20.1311 105.242 17.1352C104.009 15.7999 102.816 14.6746 102.816 14.6746C102.816 14.6746 101.181 17.415 98.0363 16.7854C96.3082 16.4387 95.13 14.8571 95.13 12.436C95.13 10.015 96.0121 8.49726 98.3505 8.52463C100.686 8.55201 101.115 10.2279 101.181 11.1373C101.269 12.3569 101.066 13.7043 101.066 13.7043H105.088L105.091 13.6983Z"
                                    fill="currentColor"></path>
                                <path d="M103.366 11.1611H94.4238V13.6978H103.366V11.1611Z" fill="currentColor"></path>
                                <path
                                    d="M33.493 13.6983C33.493 13.6983 33.7347 12.5546 33.7015 11.3928C33.6441 9.48879 32.7166 4.86872 26.6985 4.81398C20.7951 4.75923 19.1426 9.20593 19.1426 12.798C19.1426 15.6053 20.4538 18.4917 23.4205 19.7783C27.2997 21.4602 31.7709 20.1311 33.6471 17.1352C32.4145 15.7999 31.2211 14.6746 31.2211 14.6746C31.2211 14.6746 29.5867 17.415 26.4417 16.7854C24.7136 16.4387 23.5353 14.8571 23.5353 12.436C23.5353 10.015 24.4175 8.49726 26.7559 8.52463C29.0942 8.55201 29.5202 10.2279 29.5867 11.1373C29.6743 12.3569 29.4719 13.7043 29.4719 13.7043H33.493V13.6983Z"
                                    fill="currentColor"></path>
                                <path d="M31.7648 11.1611H22.8223V13.6978H31.7648V11.1611Z" fill="currentColor"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_2280_5769">
                                    <rect width="105.157" height="20.0588" fill="white"
                                          transform="translate(0.146484 0.470703)"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="text-12 text-secondary font-600">© 2024 Detrade.com | All Rights Reserved.</div>
                </div>
            </div>
        </footer>
    </div>
}
export default Transaction
