import Loading from "./Loading";
import React from "react";
import {useMediaQuery} from "react-responsive";
import {OrderResult} from "./UpdownInvestInfo";
import {Period} from "../util/order";

const UpdownOrderInfo = ({orderResult, currPeriod}: { orderResult: OrderResult, currPeriod: Period }) => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1080px)'})
    const downProfit = (orderResult?.downProfit || 0)
    const upProfit = (orderResult?.upProfit || 0)
    const totalAmount = (orderResult?.totalAmount || 0)
    const downRate = totalAmount === 0 ? 0 : (downProfit * 100 / totalAmount).toFixed(0)
    const upRate = totalAmount === 0 ? 0 : (upProfit * 100 / totalAmount).toFixed(0)
    return isTabletOrMobile ? <div className="flex s768:gap-3 s1440:gap-5 s1920:gap-10">
        <div className="flex flex-col flex-1 items-start">
            <div className="mb-1.5 text-10 text-down">DOWN POOL PAYOUT</div>
            <div className="flex gap-0.5 mb-1.5 leading-6">
                <div className="text-20 font-600">{downProfit}</div>
                <div className="text-14 font-400">({orderResult?.totalAmount||0})</div>
            </div>
            <div className="text-20 text-down">{downRate}%</div>
        </div>
        <Loading period={currPeriod}/>
        <div className="flex flex-col flex-1 items-end">
            <div className="mb-1.5 text-10 text-up">UP POOL PAYOUT</div>
            <div className="flex gap-0.5 mb-1.5 leading-6 flex-row-reverse">
                <div className="text-20 font-600">{upProfit || 0}</div>
                <div className="text-14 font-400">({orderResult?.totalAmount||0})</div>
            </div>
            <div className="text-20 text-up">{upRate}%</div>
        </div>
    </div> : <div className="flex s768:gap-3 s1440:gap-5 s1920:gap-10">
        <div className="flex flex-1 justify-between w-0">
            <div className="order-1">
                <div className="mb-1.5 text-12 text-secondary">YOUR INVESTMENT</div>
                <div className="flex items-center gap-1 s1366:gap-2 mb-2.5 font-700">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                         fill="none"
                         className="w-4 h-4 s1366:w-6 s1366:h-6 s1440:w-8 s1440:h-8 text-brand">
                        <path
                            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                            fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M17.9217 17.3824C17.8117 17.3904 17.2447 17.4244 15.9797 17.4244C14.9697 17.4244 14.2587 17.3944 14.0087 17.3824C10.1207 17.2114 7.21869 16.5344 7.21869 15.7244C7.21869 14.9144 10.1187 14.2384 14.0087 14.0644V16.7094C14.2627 16.7274 14.9907 16.7704 15.9967 16.7704C17.2037 16.7704 17.8087 16.7204 17.9217 16.7104V14.0674C21.8017 14.2404 24.6967 14.9174 24.6967 15.7254C24.6967 16.5334 21.7967 17.2104 17.9217 17.3824ZM17.9217 13.7924V11.4264H23.3357V7.81836H8.59969V11.4264H14.0137V13.7914C9.61369 13.9914 6.30469 14.8654 6.30469 15.9094C6.30469 16.9534 9.61369 17.8244 14.0137 18.0274V25.6094H17.9267V18.0254C22.3197 17.8254 25.6207 16.9524 25.6207 15.9094C25.6207 14.8664 22.3207 13.9954 17.9267 13.7924"
                              fill="white"></path>
                    </svg>
                    <div className="hidden s1920:block text-14 s1920:text-16">USDT</div>
                    <div className="text-14 s1440:text-18">${orderResult?.totalAmount || 0}</div>
                </div>
                <div className="mb-1 text-12 text-secondary">POTENTIAL RETURN</div>
                <div className="flex items-center gap-1 s1366:gap-2 font-700">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                         fill="none"
                         className="w-4 h-4 s1366:w-6 s1366:h-6 s1440:w-8 s1440:h-8 text-brand">
                        <path
                            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                            fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M17.9217 17.3824C17.8117 17.3904 17.2447 17.4244 15.9797 17.4244C14.9697 17.4244 14.2587 17.3944 14.0087 17.3824C10.1207 17.2114 7.21869 16.5344 7.21869 15.7244C7.21869 14.9144 10.1187 14.2384 14.0087 14.0644V16.7094C14.2627 16.7274 14.9907 16.7704 15.9967 16.7704C17.2037 16.7704 17.8087 16.7204 17.9217 16.7104V14.0674C21.8017 14.2404 24.6967 14.9174 24.6967 15.7254C24.6967 16.5334 21.7967 17.2104 17.9217 17.3824ZM17.9217 13.7924V11.4264H23.3357V7.81836H8.59969V11.4264H14.0137V13.7914C9.61369 13.9914 6.30469 14.8654 6.30469 15.9094C6.30469 16.9534 9.61369 17.8244 14.0137 18.0274V25.6094H17.9267V18.0254C22.3197 17.8254 25.6207 16.9524 25.6207 15.9094C25.6207 14.8664 22.3207 13.9954 17.9267 13.7924"
                              fill="white"></path>
                    </svg>
                    <div className="hidden s1920:block text-14 s1920:text-16">USDT</div>
                    <div className="text-14 s1440:text-18">${orderResult?.downProfit || 0}</div>
                </div>
            </div>
            {isTabletOrMobile ? <></> : <div className="flex flex-col order-2 items-end">
                <div
                    className="px-3 py-px rounded-full s768:text-10 s1440:text-12 s1920:text-13 font-500 bg-down/10 text-down">DOWN
                    POOL PAYOUT
                </div>
                <div
                    className="text-20 s768:text-[40px] s1024:text-30 s1440:text-[50px] s1920:text-[65px] font-600 text-down">{downRate}%
                </div>
            </div>}
        </div>
        <Loading period={currPeriod}/>
        <div className="flex flex-1 justify-between w-0">
            <div className="order-3">
                <div className="mb-1.5 text-12 text-secondary">YOUR INVESTMENT</div>
                <div className="flex items-center gap-1 s1366:gap-2 mb-2.5 font-700">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                         fill="none"
                         className="w-4 h-4 s1366:w-6 s1366:h-6 s1440:w-8 s1440:h-8 text-brand">
                        <path
                            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                            fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M17.9217 17.3824C17.8117 17.3904 17.2447 17.4244 15.9797 17.4244C14.9697 17.4244 14.2587 17.3944 14.0087 17.3824C10.1207 17.2114 7.21869 16.5344 7.21869 15.7244C7.21869 14.9144 10.1187 14.2384 14.0087 14.0644V16.7094C14.2627 16.7274 14.9907 16.7704 15.9967 16.7704C17.2037 16.7704 17.8087 16.7204 17.9217 16.7104V14.0674C21.8017 14.2404 24.6967 14.9174 24.6967 15.7254C24.6967 16.5334 21.7967 17.2104 17.9217 17.3824ZM17.9217 13.7924V11.4264H23.3357V7.81836H8.59969V11.4264H14.0137V13.7914C9.61369 13.9914 6.30469 14.8654 6.30469 15.9094C6.30469 16.9534 9.61369 17.8244 14.0137 18.0274V25.6094H17.9267V18.0254C22.3197 17.8254 25.6207 16.9524 25.6207 15.9094C25.6207 14.8664 22.3207 13.9954 17.9267 13.7924"
                              fill="white"></path>
                    </svg>
                    <div className="hidden s1920:block text-14 s1920:text-16">USDT</div>
                    <div className="text-14 s1440:text-18">${orderResult?.totalAmount || 0}</div>
                </div>
                <div className="mb-1 text-12 text-secondary">POTENTIAL RETURN</div>
                <div className="flex items-center gap-1 s1366:gap-2 font-700">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                         fill="none"
                         className="w-4 h-4 s1366:w-6 s1366:h-6 s1440:w-8 s1440:h-8 text-brand">
                        <path
                            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                            fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M17.9217 17.3824C17.8117 17.3904 17.2447 17.4244 15.9797 17.4244C14.9697 17.4244 14.2587 17.3944 14.0087 17.3824C10.1207 17.2114 7.21869 16.5344 7.21869 15.7244C7.21869 14.9144 10.1187 14.2384 14.0087 14.0644V16.7094C14.2627 16.7274 14.9907 16.7704 15.9967 16.7704C17.2037 16.7704 17.8087 16.7204 17.9217 16.7104V14.0674C21.8017 14.2404 24.6967 14.9174 24.6967 15.7254C24.6967 16.5334 21.7967 17.2104 17.9217 17.3824ZM17.9217 13.7924V11.4264H23.3357V7.81836H8.59969V11.4264H14.0137V13.7914C9.61369 13.9914 6.30469 14.8654 6.30469 15.9094C6.30469 16.9534 9.61369 17.8244 14.0137 18.0274V25.6094H17.9267V18.0254C22.3197 17.8254 25.6207 16.9524 25.6207 15.9094C25.6207 14.8664 22.3207 13.9954 17.9267 13.7924"
                              fill="white"></path>
                    </svg>
                    <div className="hidden s1920:block text-14 s1920:text-16">USDT</div>
                    <div className="text-14 s1440:text-18">${orderResult?.upProfit || 0}</div>
                </div>
            </div>
            {
                isTabletOrMobile ? <></> : <div className="flex flex-col order-2 items-start">
                    <div
                        className="px-3 py-px rounded-full s768:text-10 s1440:text-12 s1920:text-13 font-500 bg-up/10 text-up">UP
                        POOL PAYOUT
                    </div>
                    <div
                        className="text-20 s768:text-[40px] s1024:text-30 s1440:text-[50px] s1920:text-[65px] font-600 text-up">{upRate}%
                    </div>
                </div>
            }
        </div>
    </div>
}
export default UpdownOrderInfo
