const ConfirmDialog = ({onConfirm,onCancel,title,introduce,visible}:{visible:boolean,title:string,introduce:string,onConfirm:Function,onCancel:Function})=> {
    return <div className="fixed inset-0 z-modal bg-black/70 overflow-auto no-scrollbar overscroll-none"
                style={{left: '0px', opacity: 1,display:visible?'block':'none'}}>
        <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
            <div className="relative mx-5 py-6 bg-layer2 rounded-3 overflow-hidden w-[400px]">
                <div className="px-6 pb-5 text-20 font-500 text-primary capitalize">{title}</div>
                <svg
                    onClick={()=>onCancel()}
                    className="absolute z-10 w-6 h-6 right-4 rounded-full text-secondary hover:text-primary transition-colors cursor-pointer top-6"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                        fill="currentColor"></path>
                </svg>
                <div
                    className="px-6 pb-2 max-h-[60vh] overflow-auto overscroll-none no-scrollbar text-secondary text-12 font-500">
                    <div className="text-primary text-13 mb-6">{introduce}</div>
                    <div className="flex gap-3 justify-center">
                        <button
                            onClick={()=>{onCancel()}}
                            className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap text-primary font-500 disabled:pointer-events-none disabled:opacity-50 bg-layer5 h-9 px-4 py-2 text-12">Cancel
                        </button>
                        <button
                            onClick={()=>{onConfirm()}}
                            className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-9 px-4 py-2 text-12">Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ConfirmDialog
