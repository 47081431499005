import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n.use(HttpBackend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "LA20241112061821255331316",
        backend: {
            loadPath: 'https://aa.zappernow.com/open/v1/locales/{{lng}}.json', // 服务器上翻译文件的路径
            requestOptions: {}
        },
        parseMissingKeyHandler(key: string, defaultValue?: string): any {
            // collectI18n(i18n.language,key).then()
            return key
        },
        // ontransitionrun(){},
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });


export default i18n
