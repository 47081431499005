import {DialogProps} from "../constant/fields";
import Close from "./Close";

const Notifications = (props: DialogProps) => {
    return <div className="fixed z-50 top-12 s768:top-15 right-0 bottom-0 overscroll-none"
                style={{width: '380px', display: props.visible ? 'block' : 'none'}}>
        <div className="relative h-screen overflow-x-hidden overflow-y-auto no-scrollbar bg-layer3"
             style={{width: '380px'}}>
            <div className="sticky top-0 z-10 h-17 flex justify-between items-center p-4 bg-inherit text-primary"><h3
                className="text-16 s768:text-20 font-600">Notifications</h3>
                <Close onCancel={props.onCancel}/>
            </div>
            <div className="px-4 pb-17">
                <div>
                    <div className="h-0 sticky top-17 z-10">
                        <div className="bg-layer3 h-12"></div>
                    </div>
                    <div
                        className="text-secondary font-600 inline-flex w-full sticky top-17 z-10 bg-layer2 p-1 rounded-2 text-14"
                        role="tablist" aria-orientation="horizontal">
                        <div className="inline-flex relative flex-1">
                            <button
                                className="relative outline-none px-5 py-3 transition-colors duration-300 z-10 text-primary font-700 w-full"
                                id="headlessui-tabs-tab-:r12:" role="tab" type="button" aria-selected="true"
                                data-headlessui-state="selected"
                                aria-controls="headlessui-tabs-panel-:r15:">
                                <div className="absolute inset-0 z-0 bg-tab_selected rounded-1.5"
                                     style={{opacity: 1}}></div>
                                <div className="relative z-10">System Notice</div>
                            </button>
                        </div>
                        <div className="inline-flex relative flex-1">
                            <button
                                className="relative outline-none px-5 py-3 transition-colors duration-300 z-20 w-full"
                                id="headlessui-tabs-tab-:r14:" role="tab" type="button" aria-selected="false"
                                data-headlessui-state="" aria-controls="headlessui-tabs-panel-:r16:">
                                <div className="relative z-10">Activities</div>
                            </button>
                        </div>
                    </div>
                    <div>
                        <div className="pt-4" id="headlessui-tabs-panel-:r15:" role="tabpanel"
                             data-headlessui-state="selected" aria-labelledby="headlessui-tabs-tab-:r12:">
                            <div className="p-3 text-primary bg-layer5 rounded-2 mb-4">
                                <div className="flex items-start mb-3 text-12 text-secondary font-500">
                                    <svg className="w-4 h-4 mt-0.5 mr-1 cursor-default"
                                         xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                         fill="none">
                                        <g>
                                            <path
                                                d="M8.09961 2.00018C11.6896 2.00018 14.5996 4.91023 14.5996 8.50018C14.5996 12.0901 11.6896 15.0002 8.09961 15.0002C4.50966 15.0002 1.59961 12.0901 1.59961 8.50018H2.89961C2.89961 11.3719 5.22791 13.7002 8.09961 13.7002C10.9713 13.7002 13.2996 11.3719 13.2996 8.50018C13.2996 5.62848 10.9713 3.30018 8.09961 3.30018C6.31211 3.30018 4.73521 4.20173 3.79986 5.57518H5.49961V6.87518H1.59961V2.97518H2.89961V4.60018C4.08521 3.02068 5.97346 2.00018 8.09961 2.00018ZM8.74961 5.25018V8.23043L10.8576 10.3384L9.93781 11.2581L7.44961 8.76863V5.25018H8.74961Z"
                                                fill="currentColor"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_274_5394">
                                                <rect width="15.6" height="15.6" fill="currentColor"
                                                      transform="translate(0.299561 0.700195)"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    5/27/2024, 4:20:27 AM
                                </div>
                                <div className="mb-4 text-14 font-500">测试标题2</div>
                                <img src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png"
                                     className="block w-full rounded-4 object-cover mb-3 aspect-video" alt="cover"/>
                                <div className="text-12 text-secondary font-400 overflow-hidden text-ellipsis !h-auto"
                                     style={{height: '4.5em'}}>测试内容2
                                </div>
                            </div>
                            <div className="p-3 text-primary bg-layer5 rounded-2 mb-4">
                                <div className="flex items-start mb-3 text-12 text-secondary font-500">
                                    <svg className="w-4 h-4 mt-0.5 mr-1 cursor-default"
                                         xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                         fill="none">
                                        <g>
                                            <path
                                                d="M8.09961 2.00018C11.6896 2.00018 14.5996 4.91023 14.5996 8.50018C14.5996 12.0901 11.6896 15.0002 8.09961 15.0002C4.50966 15.0002 1.59961 12.0901 1.59961 8.50018H2.89961C2.89961 11.3719 5.22791 13.7002 8.09961 13.7002C10.9713 13.7002 13.2996 11.3719 13.2996 8.50018C13.2996 5.62848 10.9713 3.30018 8.09961 3.30018C6.31211 3.30018 4.73521 4.20173 3.79986 5.57518H5.49961V6.87518H1.59961V2.97518H2.89961V4.60018C4.08521 3.02068 5.97346 2.00018 8.09961 2.00018ZM8.74961 5.25018V8.23043L10.8576 10.3384L9.93781 11.2581L7.44961 8.76863V5.25018H8.74961Z"
                                                fill="currentColor"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_274_5394">
                                                <rect width="15.6" height="15.6" fill="currentColor"
                                                      transform="translate(0.299561 0.700195)"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    5/27/2024, 4:20:27 AM
                                </div>
                                <div className="mb-4 text-14 font-500">测试标题1</div>
                                <img src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png"
                                     className="block w-full rounded-4 object-cover mb-3 aspect-video" alt="cover"/>
                                <div className="text-12 text-secondary font-400 overflow-hidden text-ellipsis !h-auto"
                                     style={{height: '4.5em'}}>测试内容1
                                </div>
                            </div>
                            <div className="p-3 text-primary bg-layer5 rounded-2 mb-4">
                                <div className="flex items-start mb-3 text-12 text-secondary font-500">
                                    <svg className="w-4 h-4 mt-0.5 mr-1 cursor-default"
                                         xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                         fill="none">
                                        <g>
                                            <path
                                                d="M8.09961 2.00018C11.6896 2.00018 14.5996 4.91023 14.5996 8.50018C14.5996 12.0901 11.6896 15.0002 8.09961 15.0002C4.50966 15.0002 1.59961 12.0901 1.59961 8.50018H2.89961C2.89961 11.3719 5.22791 13.7002 8.09961 13.7002C10.9713 13.7002 13.2996 11.3719 13.2996 8.50018C13.2996 5.62848 10.9713 3.30018 8.09961 3.30018C6.31211 3.30018 4.73521 4.20173 3.79986 5.57518H5.49961V6.87518H1.59961V2.97518H2.89961V4.60018C4.08521 3.02068 5.97346 2.00018 8.09961 2.00018ZM8.74961 5.25018V8.23043L10.8576 10.3384L9.93781 11.2581L7.44961 8.76863V5.25018H8.74961Z"
                                                fill="currentColor"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_274_5394">
                                                <rect width="15.6" height="15.6" fill="currentColor"
                                                      transform="translate(0.299561 0.700195)"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    5/27/2024, 4:20:27 AM
                                </div>
                                <div className="mb-4 text-14 font-500">测试标题</div>
                                <img src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png"
                                     className="block w-full rounded-4 object-cover mb-3 aspect-video" alt="cover"/>
                                <div className="text-12 text-secondary font-400 overflow-hidden text-ellipsis !h-auto"
                                     style={{height: '4.5em'}}>测试内容
                                </div>
                            </div>
                        </div>
                        <span aria-hidden="true" id="headlessui-tabs-panel-:r16:" role="tabpanel"
                              aria-labelledby="headlessui-tabs-tab-:r14:"
                              style={{
                                  position: 'fixed',
                                  top: '1px',
                                  left: '1px',
                                  width: '1px',
                                  height: '0px',
                                  padding: '0px',
                                  margin: '-1px',
                                  overflow: 'hidden',
                                  clip: 'rect(0px, 0px, 0px, 0px)',
                                  whiteSpace: 'nowrap',
                                  borderWidth: '0px'
                              }}></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Notifications
