import {createChart, InternalHorzScaleItem, LastPriceAnimationMode, TickMarkType, Time} from 'lightweight-charts';
import {Locale} from "dayjs/locale/*";
import dayjs from "dayjs";
import {ShowFlag} from "./libs/plugins/show-flag";

const chartOptions = {
    timeScale: {
        tickMarkFormatter(time: Time, tickMarkType: TickMarkType, local: Locale) {
            return dayjs(+time * 1000).format("HH:mm:ss")
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'minute', // 设置时间单位为分钟
                    tooltipFormat: 'YYYY-MM-DD HH:mm:ss', // 工具提示格式
                    displayFormats: {
                        minute: 'HH:mm:ss' // 显示格式
                    }
                }
            }
        }
    },
    // autoSize: true,
    grid: {
        vertLines: {visible: false},
        horzLines: {visible: false},
    },
    watermark: {visible: false},
    // timeScale: {timeVisible: true},

    localization: {
        timeFormatter(time: InternalHorzScaleItem){
            return dayjs(+time*1000).format("YYYY-MM-DD HH:mm:ss")
        }
    },
    layout: {
        attributionLogo: false,
        textColor: 'white', background: {type: 'solid', color: '#292d2e'}
    }
};
function renderChart(resolve:any){
    // @ts-ignore
    const chart = createChart("chartContainer", chartOptions);
    // const areaSeries = chart.addAreaSeries({
    //     lineColor: '#2962FF',
    //     topColor: '#2962FF',
    //     bottomColor: 'rgba(41, 98, 255, 0.28)'
    // });

    // const data = [{value: 0, time: 1642425322}, {value: 8, time: 1642511722}, {value: 10, time: 1642598122}, {
    //     value: 20,
    //     time: 1642684522
    // }, {value: 3, time: 1642770922}, {value: 43, time: 1642857322}, {value: 41, time: 1642943722}, {
    //     value: 43,
    //     time: 1643030122
    // }, {value: 56, time: 1643116522}, {value: 46, time: 1643202922}];
    //
    // areaSeries.setData(data);

    const pos = chart.timeScale().scrollPosition();
    chart.timeScale().scrollToPosition(pos + 1, false);
    // chart.timeScale().fitContent()
    // chart.updateFormatter({dateFormat:"YYYY-MM-dd"})
    resolve(chart)
}

function initChart() {
    return new Promise((resolve)=>{
        if(document.getElementById("chartContainer")==null){
            setTimeout(()=>{
                renderChart(resolve)
            },1000)
            return
        }
        renderChart(resolve)
    })

}

export const showFlag = new ShowFlag()

export const setSeriesData = (chart: any, data: any[]) => {
    const areaSeries = chart.addAreaSeries({
        lineColor: '#2962FF',
        lineWidth: 2,
        topColor: 'rgba(41, 98, 255, 0.5)',
        bottomColor: 'rgba(41, 98, 255, 0)',
        lastPriceAnimation: LastPriceAnimationMode.OnDataUpdate,
    });
    areaSeries.setData(data)
    // areaSeries.attachPrimitive(new PartialPriceLine());
    // const markers = []
    // markers.push({
    //     time: new Date().getTime()/1000,
    //     // position: 'inBar',
    //     color: '#e91e63',
    //     type:'image',
    //     shape: 'arrowDown',
    //     text: 'Sell @ ' + Math.floor(1),
    // });
    // areaSeries.setMarkers(markers)
    // const watermark = new ImageWatermark(flag, {
    //     time:1731157585,
    //     maxHeight: 20,
    //     maxWidth: 20,
    //     padding: 50,
    //     alpha: 1,
    // });


    // areaSeries.attachPrimitive(watermark)
    areaSeries.attachPrimitive(showFlag)
    //
    // const streamingDataProvider = getNextRealtimeUpdate(data);
    // const intervalID = window.setInterval(() => {
    //     const update = streamingDataProvider.next();
    //     if (update.done) {
    //         window.clearInterval(intervalID);
    //         return;
    //     }
    //     areaSeries.update(update.value);
    // }, 200);
    return areaSeries
}
// function* getNextRealtimeUpdate(realtimeData: LineData[]) {
//     for (const dataPoint of realtimeData) {
//         yield dataPoint;
//     }
//     return null;
// }


export default initChart
