export const callbacks:any = {}
let socketMap:any = {}
enum EVENT_TYPE {
    PING="PING",TEXT="TEXT",SUBSCRIBE="SUBSCRIBE"
}

export const initSocket = (subUrl:string="ws",topic="BTCUSD,XRPUSD,ETHUSD")=>{
    let socket = socketMap[subUrl]
    if(socket?.readyState===WebSocket.OPEN){
        return
    }
    const token = localStorage.getItem("token")
    if(!token){
        setTimeout(()=>{
            initSocket(subUrl)
        },3000)
        return
    }
    socket = new WebSocket(`wss://aa.zappernow.com/${subUrl}?token=${token}`);
    socketMap[subUrl] = socket
    socket.onopen = () => {
        ping(subUrl)
        subscribe(topic,subUrl)
    }
    socket.onerror = (err:Error) => {
        console.log(err)
    }
    socket.onclose = () => {
        initSocket(subUrl)
    }
    socket.onmessage = async (event:any) => {
        if (event.data instanceof Blob) {
            // Handle binary data
            const blob = event.data;
            const text = await blob.text()
            for (let item in callbacks) {
                const func = callbacks[item]
                func && func(JSON.parse(text))
            }
        } else {
            console.log('Received text data:', event.data);
        }
    }
    return socket
}

const isConnected = (subUrl:string="ws")=>{
    const socket = socketMap[subUrl]
    return socket?.readyState===WebSocket.OPEN
}

const sendMsg = (data:any,eventType:EVENT_TYPE=EVENT_TYPE.TEXT,subUrl:string="ws")=>{
    const socket = socketMap[subUrl]
    if(isConnected(subUrl)){
        const msg:any = {type:eventType}
        if(data){
            msg.data = data
        }
        socket?.send(JSON.stringify(msg))
    }
}

export const disconnectWebsocket = (subUrl:string="ws")=>{
    const socket = socketMap[subUrl]
    if(socket){
        socket.close()
    }
}

export const addCallback = (key:string,func:Function)=>{
    callbacks[key] = func
}

export const delCallback = (key:string)=>{
    delete callbacks[key]
}
let timer:any = null
const ping = (subUrl:string="ws")=>{
    sendMsg(null,EVENT_TYPE.PING,subUrl)
    if(timer) {
        clearTimeout(timer)
    }
    timer = setTimeout(()=>{
        ping(subUrl)
    },10*1000)
}

const subscribe = (topic:string,subUrl:string="ws")=>{
    sendMsg(topic,EVENT_TYPE.SUBSCRIBE,subUrl)
}
const isDisconnected = (subUrl:string="ws")=>{
    const socket = socketMap[subUrl]
    return socket?.readyState===WebSocket.CLOSED||socket?.readyState===WebSocket.CLOSING
}

