import request from "./request";

export const get_cryptos = async () => {
    // @ts-ignore
    // const headers = {'Authorization':token}
    return await request.get('/open/v1/cryptos', {})
}
export const get_account_info = async () => {
    return await request.get('/open/v1/accountInfo', {})
}

export const updateUserInfo = async () => {
    return await request.get('/open/v1/userInfo', {})
}

export const updateActionStatus = async (actionStatusType:number,status:boolean) => {
    return await request.put('/open/v1/user/updateStatus', {actionStatusType,status})
}


export const queryActionStatus = async () => {
    return await request.get('/open/v1/user/actionStatus')
}

export const login = (params: { username: string, password: string }) => {
    return request.post('/open/v1/login', params)
}

export const updateUser = (params: { icon?: string, username?: string }) => {
    return request.post('/open/v1/user/update', params)
}

export const uploadFile = (formData: FormData) => {
    return request.post('/open/v1/file/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data' // 设置请求头
        }
    })
}

export const joinGame = (currencyId: string, gameId: string, amount: number, params: any) => {
    return request.post('/open/v1/game/join', {gameId, currencyId, params: params, amount})
}

export const queryOrders = (gameId: string, params: any, pageNo: number = 1) => {
    const queryString = new URLSearchParams(params).toString();
    return request.get(`/open/v1/game/orders?pageNo=${pageNo}&gameId=${gameId}&${queryString}`)
}

export const close = (gameId: string, orderId: string) => {
    return request.post(`/open/v1/game/close`, {gameId, orderId})
}

export const menus = () => {
    return request.get(`/open/v1/games/menus`)
}

export const current = (gameId?: string | null, configId?: string | null) => {
    return request.get(`/open/v1/game/current?gameId=${gameId}&configId=${configId}`)
}

export const resetWallet = (currencyId: string) => {
    return request.post(`/open/v1/wallet/reset`, {currencyId})
}

export const setLang = (langId: string) => {
    return request.post(`/open/v1/lang/set`, {langId})
}

export const getNotificationCount = () => {
    return request.get(`/open/v1/notification/unread`)
}

export const orderStat = (key:string) => {
    return request.get(`/open/v1/orderStat/${key}`)
}

export const userInfos = (userIds:string[]) => {
    return request.post(`/open/v1/userInfos`, {userIds})
}

export const queryLangs = () => {
    return request.get(`/open/v1/langs`)
}

export const queryI18n = (langId:string) => {
    return request.get(`/open/v1/i18n?langId=${langId}`)
}


export const collectI18n = (langId:string,key:string) => {
    return request.post(`/open/v1/i18n/collect`,{langId,key})
}


export const config = (gameId: string | null, symbol: string | null) => {
    return request.get(`/open/v1/game/config?gameId=${gameId}&type=2&symbol=${symbol}`)
}


export const kline = (symbol: string, mill: number = new Date().getTime(), size: number = 200) => {
    return request.get(`/open/v1/kline?symbol=${symbol}&mill=${mill}&size=${size}`)
}

export const listWallets = () => {
    return request.get(`/open/v1/wallets`)
}

