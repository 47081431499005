import {Popover, PopoverButton, PopoverPanel} from "@headlessui/react";
import {listWallets, resetWallet} from "../api";
import {useRecoilState} from "recoil";
import {currentWalletState, WalletItem, walletRequestIdState, walletState} from "../store/user";
import {useAfterUserInfo} from "../hooks";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";


const Wallet = () => {
    const {t} = useTranslation()
    const [wallets, setWallets] = useRecoilState(walletState)
    const [currentWallet, setCurrentWallet] = useRecoilState(currentWalletState)
    const [walletRequestId, setWalletRequestId] = useRecoilState(walletRequestIdState)
    const queryWallets = async () => {
        const {data} = await listWallets()
        if (data && data.length > 0 && !currentWallet) {
            setCurrentWallet(data[0])
        }
        setWallets(data)
    }

    const selectWallet = (item: WalletItem) => {
        setCurrentWallet(item)
    }
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 780px)'})

    useAfterUserInfo(() => {
        queryWallets().then()
    }, [walletRequestId]);
    return <div
                 //flex items-center gap-1 bg-layer3 pl-3 pr-1 py-1 border-2 border-input rounded-2 w-50 s768:w-[264px] ml-auto mr-3 s768:mr-auto h-10 s768:h-11
        className="flex items-center gap-1 bg-layer3 pl-3 pr-1 py-1 border-2 border-input rounded-2 w-50 s768:w-[264px] ml-auto mr-3 s768:mr-auto h-10 s768:h-11">
        <Popover className="relative flex-1">
            <PopoverButton
                className="outline-none bg-transparent active:translate-y-px hover:enabled:brightness-95 flex items-center gap-1 w-full">
                <span
                    className="block overflow-hidden size-4 s768:size-5 rounded-full lazy-load-image-background  lazy-load-image-loaded"><img
                    src={currentWallet?.currency?.icon}
                    className="w-full h-full object-cover"/></span>
                <div
                    className="text-12 s768:text-16 text-left font-600 w-24 truncate flex-1">{currentWallet?.balance}</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     className="rotate-90 size-4 s768:size-6 text-[#B3BEC1]">
                    <path d="M10 7L15 12L10 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"></path>
                </svg>
                {currentWallet?.currency?.testFlag ? <button
                        onClick={async (e) => {
                            await resetWallet(currentWallet?.currency?.outId)
                            setWalletRequestId(walletRequestId + 1)
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                        className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 px-4 py-2 capitalize w-14 s768:w-18 h-7 s768:h-9 text-14 rounded-1.5">
                        {t('reset')}
                    </button> :
                    <button
                        className={`inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 text-14  ${isTabletOrMobile?
                            'size-7 p-1 rounded-1.5 capitalize':
                            'px-4 py-3.5 h-8 w-15 rounded-1.5 capitalize'}`}>
                        {!isTabletOrMobile ? <span>{t('wallet')}</span>:
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path
                                    d="M14.6812 5.21551C14.7408 5.43734 14.7618 5.68113 14.7701 5.96887C14.8716 5.97725 14.9689 5.98771 15.0631 6.00026C15.6961 6.08502 16.2549 6.27022 16.7017 6.71701C17.1495 7.16275 17.3347 7.72044 17.4195 8.35139C17.5011 8.95198 17.5011 9.71162 17.5011 10.6324V12.0889C17.5011 13.0097 17.5011 13.7693 17.4195 14.3699C17.3347 15.0009 17.1495 15.5586 16.7017 16.0053C16.2549 16.4511 15.6961 16.6363 15.0631 16.721C14.4615 16.8016 13.6997 16.8016 12.7769 16.8016H8.58836C7.34531 16.8016 6.34397 16.8016 5.55607 16.6959C4.73784 16.5861 4.04935 16.3506 3.50107 15.8065C2.95488 15.2604 2.71945 14.5719 2.60854 13.7557C2.50391 12.971 2.50391 11.9707 2.50391 10.7308V5.23958C2.50391 4.11267 3.41841 3.19922 4.54845 3.19922H11.4595C12.0015 3.19922 12.4044 3.19922 12.7528 3.29234C13.6945 3.54346 14.429 4.2759 14.6812 5.21551ZM12.4002 4.6055C12.2484 4.56574 12.0434 4.55946 11.3643 4.55946H4.5495C4.46018 4.55946 4.37174 4.57705 4.28923 4.61123C4.20671 4.64541 4.13173 4.69551 4.06858 4.75866C4.00542 4.82182 3.95533 4.89679 3.92115 4.97931C3.88697 5.06182 3.86938 5.15026 3.86938 5.23958C3.86938 5.32889 3.88697 5.41733 3.92115 5.49985C3.95533 5.58236 4.00542 5.65734 4.06858 5.72049C4.13173 5.78365 4.20671 5.83375 4.28923 5.86793C4.37174 5.90211 4.46018 5.9197 4.5495 5.9197H12.7779L13.4047 5.92074C13.4049 5.80207 13.3915 5.68376 13.3649 5.56813C13.3028 5.33709 13.1809 5.12649 13.0116 4.95751C12.8422 4.78852 12.6313 4.66712 12.4002 4.6055ZM13.8378 10.0004C13.4678 10.0004 13.1129 10.1474 12.8512 10.4091C12.5895 10.6708 12.4425 11.0257 12.4425 11.3957C12.4425 11.7658 12.5895 12.1207 12.8512 12.3823C13.1129 12.644 13.4678 12.791 13.8378 12.791C14.2079 12.791 14.5628 12.644 14.8245 12.3823C15.0861 12.1207 15.2331 11.7658 15.2331 11.3957C15.2331 11.0257 15.0861 10.6708 14.8245 10.4091C14.5628 10.1474 14.2079 10.0004 13.8378 10.0004Z"
                                    fill="currentColor"></path>
                            </svg>}
                    </button>}
            </PopoverButton>
            <PopoverPanel anchor="bottom"
                          className="absolute z-40 bg-layer4 shadow-popup rounded-2 text-secondary overflow-auto p-2 s768:p-3 max-h-100 w-72 s768:w-96 -left-4 mt-5 s768:mt-7">
                {({close}) =>
                    <>
                        <div className="relative mt-2 rounded shadow-sm">
                            <svg className="absolute left-2 top-1/2 -translate-y-1/2" xmlns="http://www.w3.org/2000/svg"
                                 width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M12.0213 11.078L14.8765 13.9332L13.9337 14.876L11.0785 12.0208C10.0519 12.8422 8.74999 13.3335 7.33399 13.3335C4.02198 13.3335 1.33398 10.6455 1.33398 7.3335C1.33398 4.0215 4.02198 1.3335 7.33399 1.3335C10.646 1.3335 13.334 4.0215 13.334 7.3335C13.334 8.7495 12.8427 10.0514 12.0213 11.078ZM10.6838 10.5834C11.499 9.74323 12.0007 8.59723 12.0007 7.3335C12.0007 4.75516 9.91232 2.66683 7.33399 2.66683C4.75565 2.66683 2.66732 4.75516 2.66732 7.3335C2.66732 9.91183 4.75565 12.0002 7.33399 12.0002C8.59772 12.0002 9.74372 11.4985 10.5839 10.6833L10.6838 10.5834Z"
                                    fill="#ADB1B8"></path>
                            </svg>
                            <input type="text"
                                   className="flex w-full px-2 rounded-2 placeholder:text-quarterary outline-none shadow-sm focus:ring focus:ring-brand transition-all disabled:cursor-not-allowed disabled:opacity-50 h-8 bg-transparent border border-input s768:h-10 pl-7 mb-3 text-12 s768:text-14"
                                   autoComplete="off" placeholder="Search" value="" readOnly={true}/></div>
                        {wallets?.map((item: WalletItem, index) => <div onClick={() => {
                            selectWallet(item);
                            close()
                        }}
                                                                        className="text-primary"
                                                                        key={`wallet-${index}`}>
                            <div
                                className={`flex items-center justify-between cursor-pointer px-3 py-2 mb-2 last:mb-0 rounded-2 hover:bg-layer5 ${currentWallet?.currency.outId === item.currency?.outId ? 'bg-layer5' : ''}`}>
                                <div className="flex items-center gap-3"><span
                                    className="block overflow-hidden w-7 h-7 rounded-full lazy-load-image-background  lazy-load-image-loaded">
                            <img
                                alt=""
                                src={item.currency?.icon}
                                className="w-full h-full object-cover"/></span>
                                    <div className="text-12 s768:text-16 font-600">{item.currency?.coin}</div>
                                    {item.currency?.testFlag ?
                                        <div
                                            className="text-12 text-success px-2.5 py-1 bg-success/10 rounded-2">{t('demo')}
                                        </div> : <></>}
                                </div>
                                <div className="text-right">
                                    <div className="text-12 s768:text-16 font-600">{item.balance}</div>
                                    <div className="text-12 text-secondary">USD 0</div>
                                </div>
                            </div>
                        </div>)}</>}
            </PopoverPanel>
        </Popover>
    </div>
}

export default Wallet
