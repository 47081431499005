import React, {useEffect, useState} from "react";
import {queryOrders, userInfos} from "../api";
import EventEmitter from "../EventEmitter";
import {useRecoilValue} from "recoil";
import {gameState, selectSymbolState} from "../store/game";
import {TableItem} from "../constant/fields";
import {calcROIAndPL, leaderboardApi, Order} from "../util/order";
import LeverageOutDetail from "./LeverageOutDetail";
import NoData from "./NoData";
import DataLoading from "./DataLoading";
import {useTranslation} from "react-i18next";

let timer: any = null
const OrderPage = ({params, fieldInfo}: { params: any, fieldInfo: any }) => {
    const [detailVisible, setDetailVisible] = useState(false)
    const {t} = useTranslation()
    const [orders, setOrders] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const selectSymbol = useRecoilValue<any>(selectSymbolState)
    const game = useRecoilValue(gameState)
    const refresh = fieldInfo.refresh
    const initUser = async (data: any[], userIds: string[]) => {
        const {data: users} = await userInfos(userIds)
        data.forEach((item: any) => {
            const user = users[item.outId]
            if (user) {
                item.ownerUsername = user.first
                item.ownerUserIcon = user.second
            }
        })
        setOrders([...data])
    }
    const fields = fieldInfo.fields
    const loadOrders = async (params: any, startTimeFlag: boolean = false) => {
        clearTimeout(timer)
        if (game.outId) {
            setLoading(true)
            if (params.type === 4) {
                const data = await leaderboardApi(game.outId)
                setOrders(data)
                const userIds = data.map((it: any) => it.outId)
                initUser(data, userIds).then()
            } else {
                const {data} = await queryOrders(game.outId, params)
                setOrders(data?.items)
                if (startTimeFlag) {
                    startTimer(data?.items)
                }
            }
            setLoading(false)
        }
    }

    EventEmitter.on("loadOrder", () => {
        loadOrders(params, true).then()
    })
    useEffect(() => {
        setOrders([])
    }, [params]);
    const calc = fieldInfo.calc

    function startTimer(orders: any[]) {
        clearTimeout(timer)
        if (orders) {
            let flag = false
            orders.forEach((order: Order) => {
                if (refresh && calc) {
                    const temp = calc(order) || false
                    if (temp) {
                        flag = temp
                    }
                }
                calcROIAndPL(order, selectSymbol.close)
            })
            setOrders([...orders])
            if (flag) {
                setTimeout(function () {
                    loadOrders(params, false).then()
                }, 2000)
            }
        }
        timer = setTimeout(() => {
            startTimer(orders)
        }, 1000)
    }

    // useEffect(() => {
    //     startTimer()
    // }, [selectSymbol]);
    useEffect(() => {
        loadOrders(params, true).then()
        return () => {
            clearTimeout(timer)
        }
    }, [game.outId, params]);


    return <div className="relative h-50 max-h-[inherit]">
        <div className="h-full overflow-auto">
            {loading ? <DataLoading/> :
                <>
                    <table
                        className="table-fixed border-separate border-spacing-y-md w-full text-primary font-500 text-13">
                        <thead>
                        <tr className="text-secondary">
                            {fields?.map(({name, label, align, width}: TableItem) => (
                                <th key={name} className="px-3 py-1 first:rounded-l last:rounded-r font-500"
                                    align={align || "center"} style={{width: `${width}px`}}>{t(name)}
                                </th>))}
                        </tr>
                        </thead>
                        <tbody className="relative" style={{transform: 'none'}}>
                        {orders && orders.length > 0 && orders.map((order: any, rowIndex: number) => <tr
                            className="odd:bg-layer4" key={`${rowIndex}-order`}>
                            {
                                fields?.map((item: TableItem, index: number) => (
                                    <td align="center"
                                        key={`field-${rowIndex}-row-${index}`}
                                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">
                                        {item.render ? item.render(order) : order[item.name] || '-'}
                                    </td>
                                ))
                            }
                        </tr>)}
                        </tbody>
                    </table>
                    </>}
        </div>
        {(!orders || orders.length <= 0) && <NoData msg={"No bets"}/>}
        <LeverageOutDetail visible={detailVisible} onCancel={() => {
            setDetailVisible(false)
        }}/>
    </div>
}
export default OrderPage
