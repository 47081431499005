import {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import {refreshUserState, userInfoState} from "../store/user";
import {cryptoFlagState} from "../store/system";
import initChart, {setSeriesData} from "../chart";
import {addCallback, delCallback, initSocket} from "../store/socket";
import {kline} from "../api";
import {CryptoVo} from "../constant/fields";
import {selectCryptoState} from "../store/crypto";

export const useAfterUserInfo = (callback: Function, depends: any[]) => {
    const userInfo = useRecoilValue(userInfoState)
    useEffect(() => {
        if (userInfo && userInfo.login) {
            callback()
        }
    }, [userInfo.login, ...depends]);
}
export const mapQuote = (quote: any) => ({
    DT: new Date(quote.timestampMillis),
    Open: parseFloat(quote.open),
    Close: parseFloat(quote.close),
    High: parseFloat(quote.high),
    Low: parseFloat(quote.low),
    Volume: parseInt(quote.value, 10),
    value: parseFloat(quote.close),
    time: quote.timestampMillis / 1000 // dayjs().format("HH:mm:ss")
})
let maxMillTime = 0
export const useChart = () => {
    const cryptoFlag = useRecoilValue(cryptoFlagState)
    const userInfo = useRecoilValue(userInfoState)
    const [loading, setLoading] = useState(false)
    const [selectCrypto, setSelectCrypto] = useRecoilState<CryptoVo | null>(selectCryptoState)
    let chart: any = null
    const initChartInfo = async () => {
        initSocket()
        setLoading(true)
        const {data}: any = await kline("BTCUSD")
        setLoading(false)
        chart = await initChart()
        const seriesApi = setSeriesData(chart, data.map(mapQuote).reverse())
        if (seriesApi) {
            addCallback("kline", (function (data: any) {
                const timestampMillis = data.data?.timestampMillis
                if (data.type === 'AGG' && maxMillTime < timestampMillis) {
                    maxMillTime = timestampMillis
                    seriesApi.update(mapQuote(data.data))
                    setSelectCrypto({
                        ...selectCrypto, ...data.data,
                        grow: data.data.close < (selectCrypto?.close || 0)
                    })
                }
            }).bind(this))
        }
    }
    useEffect(() => {
        if(cryptoFlag && userInfo.login) {
            initChartInfo().then()
        }
        return () => {
            delCallback("kline")
            chart?.remove()
        }
    }, [cryptoFlag,userInfo]);
    return {loading, chart, selectCrypto}
}

export const useRefreshUser = () => {
    const [refreshUser, setRefreshUser] = useRecoilState(refreshUserState)
    return {
        refresh: function () {
            setRefreshUser(refreshUser + 1)
        }
    }
}

