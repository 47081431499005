import {atom} from "recoil";
import {CryptoVo} from "../constant/fields";

export const currentCryptoState = atom({
    key: 'current',
    default: {},
});



export const cryptosState = atom<CryptoVo[]>({
    key:'cryptos',
    default:[]
})

export const selectCryptoState = atom<CryptoVo|null>({
    key:'selectCrypto',
    default:null
})
// export const accountState = selector({
//     key: 'account',
//     get: async ({get}) => {
//         const {data} = await get_account_info();
//         return data[0];
//     },
// });
