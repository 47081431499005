import {atom} from "recoil";

export interface UserInfo {
    username?: string
    icon?: string
    outId?: string
    langOutId?: string
    email?: string
    login?:boolean
}

export const tokenState = atom({
    key: 'token',
    default: null,
});

export interface WalletItem {
    currency: {
        icon: string, coin: string, name: string, outId: string,
        testFlag: boolean
    },
    balance: number
}

export const accountState = atom({
    key: 'account',
    default: {balance: 0, coin: 'USD'}
});

export const userInfoState = atom<UserInfo>({
    key: 'userInfo',
    default: {}
})
export const refreshUserState = atom<number>({
    key: 'refreshUserState',
    default: 0
})

export const walletState = atom<WalletItem[]>({
    key: 'wallets',
    default: []
});

export const currentWalletState = atom<WalletItem | null>({
    key: 'currentWallet',
    default: null
})

export const walletRequestIdState = atom<number>({
    key: 'walletRequestId',
    default: 0
})
