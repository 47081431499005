const Copy = ()=> {
    return <svg
        className="text-secondary hover:text-primary cursor-pointer transition-colors shrink-0"
        xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
        fill="none">
        <path
            d="M9.04167 3.20833C9.27373 3.20833 9.49629 3.30052 9.66039 3.46462C9.82448 3.62871 9.91667 3.85127 9.91667 4.08333V12.25C9.91667 12.4821 9.82448 12.7046 9.66039 12.8687C9.49629 13.0328 9.27373 13.125 9.04167 13.125H2.625C2.51009 13.125 2.39631 13.1024 2.29015 13.0584C2.18399 13.0144 2.08753 12.95 2.00628 12.8687C1.92503 12.7875 1.86058 12.691 1.81661 12.5848C1.77263 12.4787 1.75 12.3649 1.75 12.25V4.08333C1.75 3.85127 1.84219 3.62871 2.00628 3.46462C2.17038 3.30052 2.39294 3.20833 2.625 3.20833H9.04167ZM8.75 4.375H2.91667V11.9583H8.75V4.375ZM11.3727 0.875C11.5903 0.874866 11.8001 0.955827 11.9613 1.10208C12.1224 1.24834 12.2233 1.44939 12.2442 1.666L12.2477 1.75V9.62092C12.2475 9.7696 12.1906 9.9126 12.0885 10.0207C11.9864 10.1288 11.8469 10.1939 11.6985 10.2026C11.5501 10.2113 11.4039 10.163 11.2899 10.0676C11.1759 9.97217 11.1026 9.83681 11.0851 9.68917L11.081 9.62092V2.04167H5.25C5.10712 2.04165 4.96922 1.98919 4.86245 1.89425C4.75568 1.79931 4.68747 1.66848 4.67075 1.52658L4.66667 1.45833C4.66669 1.31546 4.71914 1.17755 4.81408 1.07078C4.90903 0.964013 5.03985 0.8958 5.18175 0.879084L5.25 0.875H11.3727Z"
            fill="currentColor"></path>
    </svg>
}
export default Copy
