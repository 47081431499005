import React from "react";
import {MenuIconProps} from "../constant/fields";

const HighLowIcon = (props:MenuIconProps)=> {
    return <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                fill="currentColor"
                className={`text-secondary group-hover:text-primary shrink-0 size-5 ${props.active ? '!text-brand' : ''}`}>
        <g clipPath="url(#clip0_2401_3650)">
            <path
                d="M4.95409 18.7969C4.19046 18.7969 3.80803 17.8736 4.348 17.3336L8.73738 12.9443C9.07212 12.6095 9.61483 12.6095 9.94956 12.9443L14.3389 17.3336C14.8789 17.8736 14.4965 18.7969 13.7329 18.7969H9.34347H4.95409Z"
                fill="currentColor"></path>
            <circle cx="2.95647" cy="9.17522" r="2.82757" fill="currentColor"></circle>
            <circle cx="13.2768" cy="4.7221" r="3.04855" stroke="currentColor"
                    strokeWidth="1.71429"></circle>
            <path d="M1.48387 14.5005L17.2578 9.51347" stroke="currentColor"
                  strokeWidth="2.57143" strokeLinecap="round"></path>
        </g>
        <defs>
            <clipPath id="clip0_2401_3650">
                <rect width="18.4721" height="18.4721" fill="currentColor"
                      transform="translate(0.128906 0.324219)"></rect>
            </clipPath>
        </defs>
    </svg>
}
export default HighLowIcon
