import NoData from "./NoData";
import React, {useEffect, useState} from "react";
import {queryOrders} from "../api";
import {useRecoilValue} from "recoil";
import {gameState} from "../store/game";
import {LeverageSimpleActiveFields, TableItem} from "../constant/fields";
import DataLoading from "./DataLoading";

const QuickBetOrder = () => {
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const game = useRecoilValue(gameState)
    const initData = async () => {
        if (game.outId != null) {
            setLoading(true)
            const {data} = await queryOrders(game.outId, {calcFlag: false, type: 1})
            setOrders(data?.items)
            setLoading(false)
        }
    }
    useEffect(() => {
        initData().then()
    }, [game.outId]);
    return <div className="card-container">
        <div className="relative max-h-[inherit] h-50">
            {loading ? <DataLoading/> : orders && orders.length > 0 ? <div className="h-full overflow-auto">
                    <table
                        className="table-fixed border-separate border-spacing-y-md w-full text-primary font-500 text-12">
                        <thead>
                        <tr className="text-secondary">
                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                align="left" style={{width: '80px'}}>BET
                            </th>
                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                align="center" style={{width: '90px'}}>WAGER
                            </th>
                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                align="center" style={{width: '90px'}}>P&amp;L
                            </th>
                            <th className="px-3 py-1 first:rounded-l last:rounded-r font-500 capitalize"
                                align="right" style={{width: '95px'}}></th>
                        </tr>
                        </thead>
                        <tbody className="relative">
                        <tr className="group cursor-pointer">
                            {orders && orders.length > 0 && orders.map((order: any, rowIndex: number) => <tr
                                className="odd:bg-layer4" key={`${rowIndex}-order`}>
                                {
                                    LeverageSimpleActiveFields?.map((item: TableItem, index: number) => (
                                        <td align="center"
                                            key={`field-${rowIndex}-row-${index}`}
                                            className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">
                                            {item.render ? item.render(order) : order[item.name] || '-'}
                                        </td>
                                    ))
                                }
                            </tr>)}
                        </tr>
                        </tbody>
                    </table>
                </div>
                : <NoData msg="No Bets"/>}
        </div>
    </div>
}
export default QuickBetOrder
