import axios from "axios";
import EventEmitter from "./EventEmitter";
import {error} from "./util/toast";

const instance = axios.create({

})
const filter_urls = ["/open/v1/langs"]
instance.interceptors.request.use((config)=>{
    const url = config.url
    if(url && filter_urls.indexOf(url)>-1){
        return config
    }
    if(url!=='/open/v1/login'){
        const token = localStorage.getItem("token")
        if(!token){
            EventEmitter.emit("redirect","/login")
            return Promise.reject({ message: 'Request interrupted.' });
        }
        config.headers.Authorization = localStorage.getItem("token")
    }
    return config
})

instance.interceptors.response.use((res)=>{
    const url = res.config.url
    const {code,message,data} = res.data
    if(code!==200){
        error(`${code}:${message}`)
        if(code===1000234){
            EventEmitter.emit("redirect","/login")
        }
    }else{
        if(url==='/open/v1/login'){
            localStorage.setItem("token",data)
        }
    }
    return res.data;
},error => {
    console.log(error)
})

export default instance

