import {atom} from "recoil";

export const notificationState = atom({
    key:'notification',
    default:false
})

export const leverageState = atom({
    key:'leverageState',
    default:false
})

export const walletPanelState = atom({
    key:'walletPanel',
    default:false
})


export const langVisibleState = atom({
    key:'langState',
    default:false
})

export const cryptoFlagState = atom<boolean>({
    key:'cryptoFlagState',
    default:false
})

export const hideMenuNameState = atom<boolean>({
    key:'hideMenuState',
    default:false
})
export interface LangListItem {
    name?: string
    outId?: string
    initFlag?:boolean
}

export const selectLangState = atom<LangListItem|null>({
    key:'selectLangState',
    default:null
})

export const langsState = atom<LangListItem[]>({
    key:'langs',
    default:[]
})
