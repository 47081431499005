import UpdownPlayers from "./UpdownPlayers";
import React, {useEffect, useRef, useState} from "react";
import SendOrder from "./SendOrder";
import {addCallback, initSocket} from "../store/socket";
import TimeUnitSelect from "./TimeUnitSelect";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {gameState, gameTypeState, HighLowOrder, highLowOrderState, timeUnitState} from "../store/game";
import {config, current} from "../api";
import {calcPeriod, Period} from "../util/order";
import UpdownWork from "./UpdownWork";
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/react";
import UpdownOrderInfo from "./UpdownOrderInfo";
import {useMediaQuery} from "react-responsive";
import {useAfterUserInfo} from "../hooks";
import {showFlag} from "../chart";
import DataLoading from "./DataLoading";
import Kline from "./Kline";
import UpDownIcon from "./UpDownIcon";
import UpDownInvestIcon from "./UpDownInvestIcon";

const TOPIC = "HIGH_LOW_INFO"
let timeScheduler: any = null
let initFlag = false

export interface OrderResult {
    downProfit?: number,
    totalAmount?: number,
    upProfit?: number
}

const UpdownInvestInfo = () => {
    const [amount, setAmount] = useState(0)
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1080px)'})
    const [params] = useState({})
    const [open] = useState(false)
    const [unitConfig, setUnitConfig] = useState([])
    const game = useRecoilValue(gameState)
    const [orderResult, setOrderResult] = useState<OrderResult>({})
    const [, setHighLowOrder] = useRecoilState(highLowOrderState)
    const [currPeriod, setCurrPeriod] = useState<Period>({unit: 15, index: 0, newFlag: false})
    const [timeUnit, setTimeUit] = useRecoilState(timeUnitState)
    const setGameType = useSetRecoilState(gameTypeState)
    const [selectVisible, setSelectVisible] = useState(false)
    const sendOrderRef = useRef<{ join: Function }>();
    useEffect(() => {
        if (initFlag) {
            return
        }
        initFlag = true
        initSocket("order-ws", TOPIC)
        addCallback("invest", function (data: { content: HighLowOrder }) {
            const order = data.content
            if (order?.unit === currPeriod?.unit && order) {
                setHighLowOrder(order)
            }
        })
    }, [initFlag]);
    const canOrder = currPeriod.index <= (timeUnit?.fullTime || 30)
    const [numBtnIndex, setNumBtnIndex] = useState(-1)
    const refreshOrder = async () => {
        if (game.outId && timeUnit?.outId) {
            const {data} = await current(game.outId, timeUnit?.outId)
            setOrderResult(data)
        }
    }
    const afterSend = () => {
        refreshOrder().then(r => {
        })
    }
    const startTimer = () => {
        clearTimeout(timeScheduler)
        const period = calcPeriod(timeUnit?.unit || 15)
        showFlag.setCalcPeriod(period)
        setCurrPeriod(period)
        if (period.newFlag) {
            refreshOrder().then()
        }
        timeScheduler = setTimeout(function () {
            startTimer()
        }, 1000)
    }
    const queryConfig = async () => {
        if (game.outId) {
            const {data} = await config(game.outId, null)
            if (data) {
                if (data.length > 0) {
                    setTimeUit(data[0])
                }
                setUnitConfig(data)
            }
        }
    }
    useAfterUserInfo(() => {
        refreshOrder().then()
        setGameType("Updown")
    }, [game.outId, timeUnit?.outId]);
    useAfterUserInfo(() => {
        queryConfig().then()
        startTimer()
    }, [game.outId]);
    const [workVisible, setWorkVisible] = useState(false)
    return <div className="flex-1 bg-layer2">
        <div className="s1024:flex s1024:items-stretch gap-4 select-none">
            <div className="flex-1 flex flex-col gap-3 s768:gap-4">
                <div className="flex-1 flex flex-col gap-3 s768:gap-4 card-container">
                    <UpdownOrderInfo orderResult={orderResult} currPeriod={currPeriod}/>
                    <div className="relative w-full s1024:flex-1 s1024:h-full s1024:flex flex-col">
                        <div className="absolute left-5 s768:left-9 top-0 z-10">
                            <button
                                onClick={() => {
                                    setSelectVisible(true)
                                }}
                                className="justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap text-primary disabled:pointer-events-none disabled:opacity-50 h-9 flex items-center gap-2 w-fit p-2 rounded-4 bg-layer4 font-600 text-14 s768:text-16">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" className="size-4 s1366:size-6 text-[#F7931A] bg-white rounded-full">
                                    <g clipPath="url(#clip0_5607_132248)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M12 24C5.37225 24 0 18.6278 0 12C0 5.37225 5.37225 0 12 0C18.6278 0 24 5.37225 24 12C24 18.6278 18.6278 24 12 24ZM17.3918 10.515C17.6272 8.943 16.4295 8.09775 14.793 7.53375L15.324 5.40375L14.028 5.08125L13.5105 7.155C13.17 7.0695 12.8205 6.99 12.4718 6.9105L12.993 4.82325L11.697 4.5L11.166 6.62925C10.884 6.56475 10.6065 6.50175 10.338 6.43425L10.3395 6.4275L8.5515 5.98125L8.2065 7.36575C8.2065 7.36575 9.16875 7.58625 9.1485 7.59975C9.6735 7.731 9.768 8.07825 9.75225 8.35425L9.14775 10.7805C9.18375 10.7895 9.23025 10.803 9.28275 10.8232L9.1455 10.7895L8.298 14.1885C8.2335 14.3475 8.07075 14.5867 7.70325 14.496C7.71675 14.5147 6.76125 14.2612 6.76125 14.2612L6.11775 15.7448L7.80525 16.1655C8.11875 16.2443 8.42625 16.3267 8.7285 16.404L8.19225 18.558L9.4875 18.8805L10.0185 16.7505C10.3725 16.8457 10.716 16.9342 11.052 17.0182L10.5225 19.1392L11.8185 19.4618L12.3547 17.3123C14.5658 17.7308 16.2277 17.562 16.9275 15.5625C17.4915 13.953 16.8997 13.0238 15.7365 12.4185C16.584 12.2235 17.2215 11.6663 17.3918 10.515ZM14.4293 14.6685C14.0295 16.2788 11.3182 15.408 10.4392 15.1898L11.1518 12.336C12.0308 12.5557 14.8485 12.99 14.4293 14.6685ZM14.8305 10.4918C14.4653 11.9565 12.2092 11.2118 11.478 11.0295L12.123 8.442C12.8542 8.62425 15.2115 8.964 14.8305 10.4918Z"
                                              fill="currentColor"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5607_132248">
                                            <rect width="24" height="24" fill="transparent"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span>{timeUnit?.symbol}</span><span>{timeUnit?.unit}s</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" className="rotate-90">
                                    <path
                                        d="M12.1717 12.0005L9.34326 9.17203L10.7575 7.75781L15.0001 12.0005L10.7575 16.2431L9.34326 14.8289L12.1717 12.0005Z"
                                        fill="currentColor"></path>
                                </svg>
                            </button>
                        </div>
                        <div className="absolute top-0 right-0 z-10 flex items-center gap-2 pr-1"><span
                            className="p-1 text-16 font-600 s768:hover:text-secondary transition-all cursor-pointer text-secondary">Turbo</span>
                            <svg width="64px" height="64px" viewBox="0 0 100.00 100.00"
                                 xmlns="http://www.w3.org/2000/svg"
                                 transform="matrix(-1, 0, 0, 1, 0, 0)rotate(0)" stroke="currentColor" strokeWidth="10"
                                 className="w-6 h-6 s768:hover:text-secondary transition-all cursor-pointer text-primary">
                                <g>
                                    <path
                                        d="M33.162 19.463a3.5 3.5 0 0 0-3.111 1.974L9.34 64.239a3.5 3.5 0 0 0 1.626 4.674a3.5 3.5 0 0 0 4.676-1.625l19.453-40.203l35.412 9.361L84.182 78.13a3.5 3.5 0 0 0 4.418 2.234a3.5 3.5 0 0 0 2.234-4.416l-14.268-43.49a3.5 3.5 0 0 0-2.431-2.293l-40.04-10.586a3.5 3.5 0 0 0-.933-.115z"
                                        fill="currentColor"></path>
                                </g>
                            </svg>
                        </div>
                        <div className="relative mb-2.5 h-56 s768:h-72 s1024:h-0 s1024:flex-1">
                            <Kline hideHeader={true}/>
                        </div>
                        <div className="flex items-center justify-between py-1">
                            <div
                                className="flex justify-between text-10 s1920:text-12 text-secondary gap-3 s1024:gap-2.5">
                                <div className="hidden s1366:block">
                                    <div
                                        className="mb-0.5 text-12 leading-5 s768:text-14 s1024:text-12 s1440:text-14 text-primary font-600">--
                                    </div>
                                    <div>24H WIN RATIO</div>
                                </div>
                                <div>
                                    <div
                                        className="mb-0.5 text-12 leading-5 s768:text-14 s1024:text-12 s1440:text-14 text-primary font-600">0
                                    </div>
                                    <div className="hidden s768:block">24H LIVE PLAYERS</div>
                                    <div className="s768:hidden">24H PLAYERS</div>
                                </div>
                                <div className="hidden s768:block">
                                    <div
                                        className="mb-0.5 text-12 leading-5 s768:text-14 s1024:text-12 s1440:text-14 text-primary font-600 flex items-center gap-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                             viewBox="0 0 32 32"
                                             fill="none" className="w-3 h-3 text-brand">
                                            <path
                                                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                                                fill="currentColor"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M17.9217 17.3824C17.8117 17.3904 17.2447 17.4244 15.9797 17.4244C14.9697 17.4244 14.2587 17.3944 14.0087 17.3824C10.1207 17.2114 7.21869 16.5344 7.21869 15.7244C7.21869 14.9144 10.1187 14.2384 14.0087 14.0644V16.7094C14.2627 16.7274 14.9907 16.7704 15.9967 16.7704C17.2037 16.7704 17.8087 16.7204 17.9217 16.7104V14.0674C21.8017 14.2404 24.6967 14.9174 24.6967 15.7254C24.6967 16.5334 21.7967 17.2104 17.9217 17.3824ZM17.9217 13.7924V11.4264H23.3357V7.81836H8.59969V11.4264H14.0137V13.7914C9.61369 13.9914 6.30469 14.8654 6.30469 15.9094C6.30469 16.9534 9.61369 17.8244 14.0137 18.0274V25.6094H17.9267V18.0254C22.3197 17.8254 25.6207 16.9524 25.6207 15.9094C25.6207 14.8664 22.3207 13.9954 17.9267 13.7924"
                                                  fill="white"></path>
                                        </svg>
                                        <div>0</div>
                                    </div>
                                    <div>24H WINs PAID</div>
                                </div>
                                <div>
                                    <div
                                        className="mb-0.5 text-12 leading-5 s768:text-14 s1024:text-12 s1440:text-14 text-primary font-600 flex items-center gap-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                             viewBox="0 0 32 32"
                                             fill="none" className="w-3 h-3 text-brand">
                                            <path
                                                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                                                fill="currentColor"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M17.9217 17.3824C17.8117 17.3904 17.2447 17.4244 15.9797 17.4244C14.9697 17.4244 14.2587 17.3944 14.0087 17.3824C10.1207 17.2114 7.21869 16.5344 7.21869 15.7244C7.21869 14.9144 10.1187 14.2384 14.0087 14.0644V16.7094C14.2627 16.7274 14.9907 16.7704 15.9967 16.7704C17.2037 16.7704 17.8087 16.7204 17.9217 16.7104V14.0674C21.8017 14.2404 24.6967 14.9174 24.6967 15.7254C24.6967 16.5334 21.7967 17.2104 17.9217 17.3824ZM17.9217 13.7924V11.4264H23.3357V7.81836H8.59969V11.4264H14.0137V13.7914C9.61369 13.9914 6.30469 14.8654 6.30469 15.9094C6.30469 16.9534 9.61369 17.8244 14.0137 18.0274V25.6094H17.9267V18.0254C22.3197 17.8254 25.6207 16.9524 25.6207 15.9094C25.6207 14.8664 22.3207 13.9954 17.9267 13.7924"
                                                  fill="white"></path>
                                        </svg>
                                        <div>0</div>
                                    </div>
                                    <div className="whitespace-nowrap">ALL TIME WINS PAID</div>
                                </div>
                            </div>
                            <div className="flex justify-end gap-1 s1440:gap-2">
                                <UpDownInvestIcon type={"down"}/>
                                <UpDownInvestIcon type={"up"}/>
                            </div>
                        </div>
                    </div>
                </div>
                {!isTabletOrMobile ? <></> : <div className="relative">
                    <div className="flex items-stretch divide-x divide-[#808195]/30">
                        <div className="flex-1 text-down pr-4">
                            <div className="flex justify-between gap-1">
                                <div className="flex items-center text-12">
                                    <div className="mr-1 font-600">0</div>
                                    <div className="font-400">Players</div>
                                </div>
                                <div className="text-14 font-600">$0</div>
                            </div>
                            <div className="flex items-end flex-nowrap overflow-hidden flex-row-reverse"
                                 style={{height: '0px'}}></div>
                        </div>
                        <div className="flex-1 text-up pl-4">
                            <div className="flex justify-between gap-1 flex-row-reverse">
                                <div className="flex items-center text-12">
                                    <div className="mr-1 font-600">0</div>
                                    <div className="font-400">Players</div>
                                </div>
                                <div className="text-14 font-600">$0</div>
                            </div>
                            <div className="flex items-end flex-nowrap overflow-hidden"
                                 style={{height: '0px'}}></div>
                        </div>
                    </div>
                </div>}
                <div
                    className="relative flex flex-col s768:gap-5 s768:block p-3 s768:p-4 s1024:p-3.5 s1440:p-5 rounded-2 bg-layer4">
                    <div className="flex items-center gap-2 mb-4 s768:mb-5 text-15 font-600">
                        <div className="flex-1">UP or DOWN?</div>
                        <div className="text-12 text-secondary hover:text-primary cursor-pointer" onClick={() => {
                            setWorkVisible(true)
                        }}>How It Works
                        </div>
                        <svg className="size-6 cursor-pointer text-secondary hover:text-primary"
                             xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <g>
                                <path
                                    d="M8.09961 2.00018C11.6896 2.00018 14.5996 4.91023 14.5996 8.50018C14.5996 12.0901 11.6896 15.0002 8.09961 15.0002C4.50966 15.0002 1.59961 12.0901 1.59961 8.50018H2.89961C2.89961 11.3719 5.22791 13.7002 8.09961 13.7002C10.9713 13.7002 13.2996 11.3719 13.2996 8.50018C13.2996 5.62848 10.9713 3.30018 8.09961 3.30018C6.31211 3.30018 4.73521 4.20173 3.79986 5.57518H5.49961V6.87518H1.59961V2.97518H2.89961V4.60018C4.08521 3.02068 5.97346 2.00018 8.09961 2.00018ZM8.74961 5.25018V8.23043L10.8576 10.3384L9.93781 11.2581L7.44961 8.76863V5.25018H8.74961Z"
                                    fill="currentColor"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_274_5394">
                                    <rect width="15.6" height="15.6" fill="currentColor"
                                          transform="translate(0.299561 0.700195)"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                        <Popover>
                            <PopoverButton className="relative inline-flex cursor-pointer">
                                <svg
                                    className="text-secondary hover:text-primary transition-all duration-300 cursor-pointer"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="currentColor">
                                    <path
                                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM13 13.3551V14H11V12.5C11 11.9477 11.4477 11.5 12 11.5C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.2723 8.5 10.6656 9.01823 10.5288 9.70577L8.56731 9.31346C8.88637 7.70919 10.302 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10C15.5 11.5855 14.4457 12.9248 13 13.3551Z"
                                        fill="currentColor"></path>
                                </svg>
                            </PopoverButton>
                            <PopoverPanel
                                data-open={open}
                                className="absolute z-40 w-64 p-3 bg-layer5 light:bg-layer7 rounded-2 cursor-text select-text shadow text-12 text-primary font-300 right-full top-1/2 -translate-y-1/2 mr-1"
                                style={{
                                    boxShadow: 'rgba(0, 0, 0, 0.17) 0px 8px 40px 0px, rgba(0, 0, 0, 0.27) 0px 12px 32px -16px',
                                    opacity: 1
                                }}>
                                The
                                amount in the shortcut option is the corresponding USD amount, for example, 5
                                corresponds to 5USD. Users can place orders through the shortcut.
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10"
                                     fill="none"
                                     className="absolute left-full top-1/2 -translate-y-1/2 -translate-x-px">
                                    <path
                                        d="M4.58579 6.41421L3.41421 7.58579C1.96116 9.03884 1.23463 9.76537 0.617316 9.50967C-7.63216e-08 9.25397 -1.21234e-07 8.2265 -2.11057e-07 6.17157L-3.13479e-07 3.82843C-4.03303e-07 1.7735 -4.48215e-07 0.746035 0.617316 0.490334C1.23463 0.234632 1.96116 0.961159 3.41421 2.41421L3.41422 2.41421L4.58579 3.58579C5.25245 4.25245 5.58579 4.58579 5.58579 5C5.58579 5.41421 5.25245 5.74755 4.58579 6.41421Z"
                                        className="fill-layer5 light:fill-layer7"></path>
                                </svg>
                            </PopoverPanel>
                        </Popover>
                    </div>
                    <div className="s768:flex items-center gap-2 s1366:gap-3 text-14 font-500 order-2 mb-4 s768:mb-5">
                        <div
                            className="flex items-center bg-layer2 rounded-3 gap-1 p-1 border border-input s768:w-[313px] s768:mb-0 s1024:w-50 s1366:w-64 s1440:w-80 s1920:flex-1 mb-1.5">
                            <label htmlFor="amount-input-up-down"
                                   className="flex-center w-6 h-6 ml-1.5 rounded-full overflow-hidden pointer-events-none"
                                   style={{
                                       backgroundImage: 'url(https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC@3x.png)',
                                       backgroundSize: 'contain'
                                   }}></label><input
                            className="px-2 text-13 font-700 rounded placeholder:text-quarterary focus:outline-none focus:outline-background-brand disabled:cursor-not-allowed flex-1 w-0 h-8 s1366:h-10 text-primary bg-inherit"
                            type="text" inputMode="decimal" autoComplete="off" id="amount-input-up-down"
                            onChange={(e) => {
                                setAmount(+e.target.value)
                            }}
                            value={amount}/>
                            <button
                                className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 w-8 s768:w-15 h-8 s1366:h-10 shrink-0 bg-layer7 rounded-2 text-13 s1366:text-14 text-primary font-500 select-none">1/2
                            </button>
                            <button
                                className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 w-8 s768:w-15 h-8 s1366:h-10 shrink-0 bg-layer7 rounded-2 text-13 s1366:text-14 text-primary font-500 select-none">x2
                            </button>
                        </div>
                        <div className="flex-1 flex gap-1 s1366:gap-2 text-12 s1366:text-16">
                            {[5, 10, 15, 25, 50, 75, 100].map((item, key) =>
                                <button key={`action-${key}`}
                                        onClick={() => {
                                            setAmount(item)
                                            setNumBtnIndex(key)
                                        }}
                                        className={`inline-flex ${key === numBtnIndex ? "text-primary_brand bg-brand" : ''} items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap font-500 disabled:pointer-events-none disabled:opacity-50 bg-layer5 h-auto flex-1 py-2.5 s1366:py-3 rounded-4 border text-secondary border-thirdly`}>{item}
                                </button>)}
                        </div>
                    </div>
                    <div
                        className="relative flex order-3 s768:order-1 h-12 s768:h-15 s1024:h-12 s1366:h-16 s1440:h-18 overflow-hidden rounded-3 s768:rounded-4 s1024:rounded-3 s1366:rounded-4 -space-x-2 s768:-space-x-4 s1024:-space-x-3.5 s1366:-space-x-5 s1440:-space-x-6 s1920:-space-x-8">
                        <button
                            onClick={() => {
                                if (!canOrder) {
                                    return
                                }
                                sendOrderRef.current?.join({type: 0, configId: timeUnit?.outId})
                            }}
                            className={`${!canOrder ? 'opacity-30' : ''} inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none whitespace-nowrap font-500 disabled:pointer-events-none disabled:opacity-50 bg-transparent px-4 py-2 text-12 relative h-full flex-1 transition-colors shadow-none rounded-none text-white origin-left`}
                        >
                            <div
                                className="rounded-tr-2 skew-x-[15deg] s768:skew-x-[26deg] s1024:skew-x-[28deg] s1440:skew-x-[26deg] s1920:skew-x-[36deg] absolute inset-0 z-0 rounded-tr bg-down origin-bottom-right"></div>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="relative size-5 s768:w-8 s768:h-8 s1024:w-6 s1024:h-6 s1440:w-11 s1440:h-11"
                                 width="38" height="38" viewBox="0 0 38 38" fill="none">
                                <path
                                    d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                                    fill="currentColor" fillOpacity="0.75"></path>
                            </svg>
                        </button>
                        <SendOrder amount={amount} params={params} ref={sendOrderRef} afterSend={afterSend}/>
                        <button
                            onClick={() => {
                                if (!canOrder) {
                                    return
                                }
                                sendOrderRef.current?.join({type: 1, configId: timeUnit?.outId})
                            }}
                            className={`${!canOrder ? 'opacity-30' : ''} inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none whitespace-nowrap font-500 disabled:pointer-events-none disabled:opacity-50 bg-transparent px-4 py-2 text-12 relative h-full flex-1 transition-colors shadow-none rounded-none text-white origin-right`}
                        >
                            <div
                                className="rounded-bl-2 skew-x-[15deg] s768:skew-x-[26deg] s1024:skew-x-[28deg] s1440:skew-x-[26deg] s1920:skew-x-[36deg] absolute inset-0 z-0 rounded-bl bg-up origin-top-left"></div>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="rotate-180 relative size-5 s768:w-8 s768:h-8 s1024:w-6 s1024:h-6 s1440:w-11 s1440:h-11"
                                 width="38" height="38" viewBox="0 0 38 38" fill="none">
                                <path
                                    d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                                    fill="currentColor" fillOpacity="0.75"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {isTabletOrMobile ? <></> : <UpdownPlayers/>}
        </div>
        {
            selectVisible ? <TimeUnitSelect configs={unitConfig} onClose={() => {
                setSelectVisible(false)
            }}/> : <></>
        }

        <UpdownWork visible={workVisible} onCancel={() => {
            setWorkVisible(false)
        }}/>

    </div>
}
export default UpdownInvestInfo
