import {HighLowOrder, highLowOrderState} from "../store/game";
import {useRecoilValue} from "recoil";

const CalcResult =({order}:{order:HighLowOrder})=> {
    const highLowOrder = useRecoilValue(highLowOrderState)
    const upOrderCount = highLowOrder.upOrderCount||0
    const upOrderAmount = highLowOrder.upOrderAmount||0
    const downOrderAmount = highLowOrder.downOrderAmount||0
    const downOrderCount = highLowOrder.downOrderCount||0
    const resultType = highLowOrder.resultType||"DOWN"
    const resultFlag = highLowOrder.resultType==="DOWN"
    const winCount = resultFlag?downOrderCount:upOrderCount
    const winAmount = resultFlag?downOrderAmount:upOrderAmount
    return <div className="relative flex shrink-0 px-1.5 s1920:px-2 py-5 rounded-4 bg-layer4 overflow-hidden"
         style={{width: '376px'}}>
        <div className="flex-1 w-0 px-1.5 s1920:px-2 text-down shrink-0"
             style={{opacity: 1, display: 'block', flexBasis: '100%'}}>
            <div className="mb-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"
                     className="mx-auto w-10 h-10 s1366:w-11 s1366:h-11 s1920:w-13 s1920:h-13 p-2 rounded-full bg-down mb-1.5">
                    <g opacity="0.75">
                        <path
                            d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                            fill="url(#paint0_linear_62_14101)"></path>
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_62_14101" x1="19" y1="0.500026" x2="19" y2="39"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="white"></stop>
                            <stop offset="1" stop-color="white" stop-opacity="0"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                <span className="text-18 font-600">{resultType}</span></div>
            <div className="relative">
                <div className="absolute inset-0 flex-center"
                     style={{backgroundImage: 'linear-gradient(rgba(255, 78, 84, 0) 0%, rgba(255, 78, 84, 0.18) 49.61%, rgba(255, 78, 84, 0) 100%)', opacity: 1}}>
                    <div className="text-center font-700 text-24 s1440:text-36 text-up">
                        <div>{winCount}</div>
                        <div style={{lineHeight: '32px'}}>WINNER</div>
                        <div>{winAmount}</div>
                    </div>
                </div>
                <div style={{transform: 'scaleY(0) translateZ(0px)', opacity: 0}}>
                    <div
                        className="relative mb-4 p-3 border-t-[5px] rounded-t-2 bg-gradient-to-b to-transparent border-down from-down/20">
                        <div className="flex mb-6 text-12 font-400 text-down">
                            <div className="mr-1 text-secondary">Players</div>
                            <div className="font-600">0</div>
                            <div className="flex-1 text-right">$0</div>
                        </div>
                        <div className="flex justify-between text-14 text-primary font-500">
                            <div>Players</div>
                            <div>Bet</div>
                        </div>
                        <div
                            className="absolute bottom-0 inset-x-0 h-px bg-layer5 light:bg-layer2 -mr-1.5 s1920:-mr-2"></div>
                    </div>
                    <div className="text-primary after:clear-both mb-3 overflow-hidden"
                         style={{height: '424px'}}></div>
                    <div
                        className="h-9 after:clear-both rounded-full cursor-pointer overflow-hidden border border-transparent bg-layer4 invisible"
                        style={{
                            borderWidth: '2px', backgroundClip: 'padding-box, border-box', backgroundOrigin: 'padding-box, border-box', backgroundImage: 'linear-gradient(to right, var(--bg-layer4), var(--bg-layer4)),linear-gradient(to right, #61FFD9, #CF87FC)'
                        }}></div>
                </div>
            </div>
        </div>
        <div className="flex-1 w-0 px-1.5 s1920:px-2 text-up" style={{opacity: 0, display: 'none', flexBasis: '0px'}}>
            <div className="mb-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"
                     className="mx-auto w-10 h-10 s1366:w-11 s1366:h-11 s1920:w-13 s1920:h-13 p-2 rounded-full bg-up mb-1.5">
                    <path
                        d="M4.5714 23.7937L11.0352 17.3299L16.4084 22.705L22.4067 16.7067L19 13.3L28.5 13.3L28.5 22.8L25.0933 19.3933L16.4084 28.0782L11.0352 22.705L6.3327 27.4056C8.46718 30.6182 11.7432 32.8992 15.4968 33.7864C19.2505 34.6736 23.201 34.1006 26.5479 32.1835C29.8949 30.2664 32.3878 27.1487 33.5215 23.462C34.6553 19.7753 34.3451 15.7955 32.6539 12.329C30.9626 8.86249 28.0167 6.1686 24.4132 4.79322C20.8097 3.41784 16.818 3.46384 13.2472 4.92189C9.67629 6.37995 6.79327 9.141 5.18232 12.6456C3.57137 16.1501 3.35298 20.136 4.5714 23.7956L4.5714 23.7937ZM1.6492 26.7539L1.6302 26.7349L1.6378 26.7273C0.554671 24.2953 -0.0034042 21.6623 8.90882e-06 19C1.25784e-05 8.5063 8.50631 2.97458e-06 19 6.64413e-06C29.4937 1.03137e-05 38 8.50631 38 19C38 29.4937 29.4937 38 19 38C11.267 38 4.617 33.383 1.6492 26.7539Z"
                        fill="url(#paint0_linear_62_14106)" fillOpacity="0.75"></path>
                    <defs>
                        <linearGradient id="paint0_linear_62_14106" x1="19" y1="6.64413e-06" x2="19" y2="38"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="white"></stop>
                            <stop offset="1" stop-color="white" stop-opacity="0"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                <span className="text-18 font-600">Up</span></div>
            <div className="relative">
                <div className="absolute inset-0 flex-center"
                     style={{backgroundImage: 'linear-gradient(rgba(49, 167, 141, 0) 0%, rgba(49, 167, 141, 0.18) 49.61%, rgba(49, 167, 141, 0) 100%)', opacity: 1}}>
                    <div className="text-center font-700 text-24 s1440:text-36 text-down">
                        <div>0</div>
                        <div style={{lineHeight: '32px'}}>LOSER</div>
                        <div>0</div>
                    </div>
                </div>
                <div style={{transform: 'scaleY(0) translateZ(0px)', opacity: 0}}>
                    <div
                        className="relative mb-4 p-3 border-t-[5px] rounded-t-2 bg-gradient-to-b to-transparent border-up from-up/20">
                        <div className="flex mb-6 text-12 font-400 text-up">
                            <div className="mr-1 text-secondary">Players</div>
                            <div className="font-600">0</div>
                            <div className="flex-1 text-right">$0</div>
                        </div>
                        <div className="flex justify-between text-14 text-primary font-500">
                            <div>Players</div>
                            <div>Bet</div>
                        </div>
                        <div
                            className="absolute bottom-0 inset-x-0 h-px bg-layer5 light:bg-layer2 -ml-1.5 s1920:-ml-2"></div>
                    </div>
                    <div className="text-primary after:clear-both mb-3 overflow-hidden"
                         style={{height: '424px'}}></div>
                    <div
                        className="h-9 after:clear-both rounded-full cursor-pointer overflow-hidden border border-transparent bg-layer4 invisible"
                        style={{borderWidth: '2px', backgroundClip: 'padding-box, border-box', backgroundOrigin: 'padding-box, border-box', backgroundImage: 'linear-gradient(to right, var(--bg-layer4), var(--bg-layer4)),linear-gradient(to right, #61FFD9, #CF87FC)'}}></div>
                </div>
            </div>
        </div>
    </div>
}

export default CalcResult
