import {useRecoilState, useSetRecoilState} from "recoil";
import {accountState} from "../store/user";
import {get_account_info, get_cryptos, menus, queryActionStatus} from "../api";
import React, {useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import Menu from "../components/Menu";
import MobileMenu from "../components/MobileMenu";
import {Outlet, useLocation} from "react-router-dom";
import {gameState, menuIndexState, menuState} from "../store/game";
import RiskWarning from "../components/RiskWarning";
import PageHeader from "../components/PageHeader";
import WalletPanel from "../components/WalletPanel";
import Notifications from "../components/Notifications";
import {cryptoFlagState, langVisibleState, leverageState, notificationState, walletPanelState} from "../store/system";
import LeverageWork from "../components/LeverageWork";
import LangDialog from "../components/LangDialog";
import {cryptosState, selectCryptoState} from "../store/crypto";
import {useAfterUserInfo} from "../hooks";
import {useTranslation} from "react-i18next";

const Layout = () => {
    const {t} = useTranslation()
    const setAccountState = useSetRecoilState(accountState)
    const [riskVisible, setRiskVisible] = useState(false)
    const setCryptos = useSetRecoilState(cryptosState)
    const [actionStatuses,setActionStatuses] = useState<any>({})
    const [selectCrypto, setSelectCrypto] = useRecoilState(selectCryptoState)
    const [, setCryptoFlag] = useRecoilState(cryptoFlagState)
    const [notificationVisible, setNotificationVisible] = useRecoilState(notificationState)
    const [leverageWorkVisible, setLeverageWorkVisible] = useRecoilState(leverageState)
    const [walletPanelVisible, setWalletPanelVisible] = useRecoilState(walletPanelState)
    const [, setMenuIndex] = useRecoilState(menuIndexState)
    const setGameState = useSetRecoilState(gameState)
    const [menuData, setMenuData] = useRecoilState(menuState)
    const [langVisible, setLangVisible] = useRecoilState(langVisibleState)

    const queryStatusAction = async ()=>{
        const {data} = await queryActionStatus()
        setActionStatuses(data)
    }

    const initData = async () => {
        const {data} = await get_account_info()
        setAccountState(data)
    }

    const initCryptos = async () => {
        const {data} = await get_cryptos()
        setCryptos(data)
        if (selectCrypto == null && data != null && data.length > 0) {
            setSelectCrypto(data[0])
        }
        setCryptoFlag(true)
    }
    const location = useLocation()
    const queryGames = async () => {
        const {data} = await menus()
        setMenuData(data)
    }
    const matchFocus = () => {
        if (menuData?.length > 0) {
            const index = menuData.findIndex(t => t.url === location.pathname)
            if (index > -1) {
                const outId = menuData[index].gameId
                setGameState({outId})
            }
            setMenuIndex(location.pathname)
        }
    }
    useEffect(() => {
        queryStatusAction().then()
        matchFocus()
    }, [location, menuData]);
    const isMounted = useRef(false);
    useAfterUserInfo(() => {
        queryGames().then()
        initCryptos().then()
        get_cryptos().then()
        isMounted.current = true;
        initData().then()
        return function () {
            if (isMounted.current) {
            }
        }
    }, []);
    useEffect(() => {
        setRiskVisible(true)
    }, []);

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1366px)'})
    return <>
        <div style={{height:'100%'}} className="flex flex-col s768:w-screen s768:h-screen s768:overflow-hidden">
            <PageHeader/>
            <div className="relative flex-1 flex overflow-hidden">
                {!isTabletOrMobile ? <Menu/> : <></>}
                <Outlet/>
                {isTabletOrMobile ? <MobileMenu/> : <></>}
            </div>
        </div>
        <div id="custom-portal-root">
        </div>
        <RiskWarning visible={riskVisible && !actionStatuses[0]} onCancel={() => setRiskVisible(false)}/>
        <WalletPanel visible={walletPanelVisible} onCancel={() => {
            setWalletPanelVisible(false)
        }}/>
        <Notifications visible={notificationVisible} onCancel={() => {
            setNotificationVisible(false)
        }}/>
        <LeverageWork visible={leverageWorkVisible} onCancel={() => {
            setLeverageWorkVisible(false)
        }}/>
        <LangDialog visible={langVisible} onCancel={() => {
            setLangVisible(false)
        }}/>
    </>
}
export default Layout
