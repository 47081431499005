const TextEyeIcon = ({inputType, setInputType}: { inputType: any, setInputType: any }) => {
    return <>
        {inputType === "password" ? <svg onClick={() => {
                setInputType(inputType === "password" ? "text" : "password")
            }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                         fill="none" className="cursor-pointer text-secondary hover:text-primary">
                <g clipPath="url(#clip0_1623_9566)">
                    <path
                        d="M9.00005 4.385C12.3251 4.385 15.3531 7.615 16.5096 9C15.3531 10.385 12.3251 13.615 9.00005 13.615C5.67505 13.615 2.64705 10.385 1.49055 9C2.64705 7.615 5.67505 4.385 9.00005 4.385ZM9.00005 3C4.87405 3 1.37805 6.937 0.199052 8.4325C0.070199 8.59358 0 8.79372 0 9C0 9.20628 0.070199 9.40642 0.199052 9.5675C1.37805 11.063 4.87405 15 9.00005 15C13.1261 15 16.6221 11.063 17.8011 9.5675C17.9299 9.40642 18.0001 9.20628 18.0001 9C18.0001 8.79372 17.9299 8.59358 17.8011 8.4325C16.6221 6.937 13.1261 3 9.00005 3ZM9.00005 7.615C9.74555 7.615 10.3501 8.235 10.3501 9C10.3501 9.765 9.74555 10.385 9.00005 10.385C8.25455 10.385 7.65005 9.765 7.65005 9C7.65005 8.235 8.25455 7.615 9.00005 7.615ZM9.00005 6.231C7.90805 6.231 6.92355 6.9055 6.50605 7.9405C6.08805 8.9755 6.31905 10.166 7.09105 10.958C7.4632 11.3438 7.94362 11.6077 8.46888 11.7149C8.99413 11.822 9.53953 11.7673 10.0331 11.558C11.0421 11.13 11.7001 10.12 11.7001 9C11.7001 7.4705 10.4916 6.231 9.00055 6.231H9.00005Z"
                        fill="currentColor"></path>
                </g>
                <defs>
                    <clipPath id="clip0_1623_9566">
                        <rect width="18" height="18" fill="currentColor"></rect>
                    </clipPath>
                </defs>
            </svg> :
            <svg onClick={() => {
                setInputType(inputType === "password" ? "text" : "password")
            }} className="cursor-pointer text-secondary hover:text-primary" viewBox="0 0 1024 1024"
                 version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                <path
                    d="M469.333333 681.386667c-36.053333-2.432-71.253333-8.533333-104.96-17.92l-69.802666 149.674666a42.368 42.368 0 0 1-56.533334 20.266667 42.666667 42.666667 0 0 1-20.821333-56.32l66.986667-143.658667a451.712 451.712 0 0 1-148.906667-112.682666 388.693333 388.693333 0 0 1-70.570667-119.338667 42.666667 42.666667 0 1 1 80.128-29.354667 303.445333 303.445333 0 0 0 55.210667 93.098667C270.634667 547.413333 383.018667 597.333333 505.728 597.333333c122.752 0 235.136-49.962667 305.706667-132.181333a303.445333 303.445333 0 0 0 55.210666-93.098667 42.666667 42.666667 0 0 1 80.128 29.354667 388.693333 388.693333 0 0 1-70.570666 119.338667 423.68 423.68 0 0 1-18.773334 20.48l104.362667 104.362666a42.666667 42.666667 0 0 1-0.298667 60.032 42.368 42.368 0 0 1-60.032 0.298667l-109.653333-109.653333c-20.48 14.08-42.24 26.581333-65.024 37.418666l66.901333 143.36a42.666667 42.666667 0 0 1-20.821333 56.362667 42.368 42.368 0 0 1-56.533333-20.266667l-69.717334-149.546666a520.533333 520.533333 0 0 1-91.946666 16.810666v130.645334A42.666667 42.666667 0 0 1 512 853.333333c-23.722667 0-42.666667-18.944-42.666667-42.24v-129.706666z"
                    fill="currentColor"></path>
                <path
                    d="M176.128 524.373333a42.368 42.368 0 0 1 60.032 0.256 42.666667 42.666667 0 0 1 0.298667 60.074667l-121.216 121.216a42.368 42.368 0 0 1-60.074667-0.298667 42.666667 42.666667 0 0 1-0.298667-60.032l121.258667-121.258666z"
                    fill="currentColor"></path>
            </svg>}
    </>
}
export default TextEyeIcon
