import ArrowDown from "./ArrowDown";
import React, {useState} from "react";
import {Listbox, ListboxButton, ListboxOption,ListboxOptions} from "@headlessui/react";

const TradeFilter = ()=> {
    const cryptos = [
        {name:'BTC/USD',rowId:1},
        {name:'ETH/USD',rowId:2},
        {name:'LTC/USD',rowId:3}
    ]
    const directions = [
        {name:'Up',rowId:1},
        {name:'Down',rowId:2}
    ]
    const currencies = [
        {name:'USDT',rowId:1}
    ]
    const [selectCrypto,setSelectCrypto] = useState<{name?:string,rowId?:number}>({})
    const [selectDirection,setSelectDirection] = useState<{name?:string,rowId?:number}>({})
    const [selectCurrency,setSelectCurrency] = useState<{name?:string,rowId?:number}>({})
    return <div className="flex justify-between items-center self-stretch">
        <div className="flex items-center gap-3 flex-wrap s768:flex-nowrap">
            <Listbox value={selectCrypto} onChange={setSelectCrypto}>
                <div className="relative min-w-min border border-input bg-layer5 light:bg-white rounded-2 flex items-center px-3 s1366:px-4 text-12 w-26 s768:w-28 h-10 s768:h-12 s1366:h-12">
                    <ListboxButton className="bg-transparent w-full outline-none text-12 font-600 text-secondary flex items-center justify-between gap-1">
                        {selectCrypto?.name||'Crypto'}
                        <ArrowDown/>
                    </ListboxButton>
                    <ListboxOptions
                        className="absolute left-0 top-full z-40 max-h-60 min-w-full mt-2 p-2 s1366:p-3 bg-layer5 light:bg-white shadow focus:outline-none rounded-2 overflow-auto no-scrollbar text-12 leading-4 text-secondary"
                        as="ul">
                        {cryptos?.map((crypto) => (
                            <ListboxOption
                                className={`relative select-none px-2 py-1 mt-0 mb-2 last:mb-0 cursor-pointer w-full transition-all rounded ${selectCrypto.rowId===crypto.rowId?'bg-layer6 light:bg-layer5':''}`}
                                key={crypto.name} value={crypto.rowId}>
                                {crypto.name}
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </div>
            </Listbox>
            <Listbox value={selectDirection} onChange={setSelectDirection}>
                <div className="relative min-w-min border border-input bg-layer5 light:bg-white rounded-2 flex items-center px-3 s1366:px-4 text-12 w-26 s768:w-28 h-10 s768:h-12 s1366:h-12">
                    <ListboxButton className="bg-transparent w-full outline-none text-12 font-600 text-secondary flex items-center justify-between gap-1">
                        {selectDirection?.name||'Direction'}
                        <ArrowDown/>
                    </ListboxButton>
                    <ListboxOptions
                        className="absolute left-0 top-full z-40 max-h-60 min-w-full mt-2 p-2 s1366:p-3 bg-layer5 light:bg-white shadow focus:outline-none rounded-2 overflow-auto no-scrollbar text-12 leading-4 text-secondary"
                        as="ul">
                        {directions.map((direction) => (
                            <ListboxOption
                                className={`relative select-none px-2 py-1 mt-0 mb-2 last:mb-0 cursor-pointer w-full transition-all rounded ${selectDirection.rowId===direction.rowId?'bg-layer6 light:bg-layer5':''}`}
                                key={direction.name} value={direction.rowId}>
                                {direction.name}
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </div>
            </Listbox>
            <Listbox value={selectCurrency} onChange={setSelectCurrency}>
                <div className="relative min-w-min border border-input bg-layer5 light:bg-white rounded-2 flex items-center px-3 s1366:px-4 text-12 w-26 s768:w-28 h-10 s768:h-12 s1366:h-12">
                    <ListboxButton className="bg-transparent w-full outline-none text-12 font-600 text-secondary flex items-center justify-between gap-1">
                        {selectCurrency?.name||'Direction'}
                        <ArrowDown/>
                    </ListboxButton>
                    <ListboxOptions
                        className="absolute left-0 top-full z-40 max-h-60 min-w-full mt-2 p-2 s1366:p-3 bg-layer5 light:bg-white shadow focus:outline-none rounded-2 overflow-auto no-scrollbar text-12 leading-4 text-secondary"
                        as="ul">
                        {currencies.map((currency) => (
                            <ListboxOption
                                className={`relative select-none px-2 py-1 mt-0 mb-2 last:mb-0 cursor-pointer w-full transition-all rounded ${selectCurrency.rowId===currency.rowId?'bg-layer6 light:bg-layer5':''}`}
                                key={currency.name} value={currency.rowId}>
                                {currency.name}
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </div>
            </Listbox>
        </div>
    </div>
}
export default TradeFilter
