import {useSetRecoilState} from "recoil";
import {leverageState} from "../store/system";

const LeverageInfo = () => {
    const setLeverageState = useSetRecoilState(leverageState)
    return <div
        className="absolute z-40 p-3 bg-layer5 light:bg-layer7 rounded-2 cursor-text select-text shadow text-12 text-primary font-300 right-full top-1/2 -translate-y-1/2 mr-1 w-50"
        style={{
            boxShadow: 'rgba(0, 0, 0, 0.17) 0px 8px 40px 0px, rgba(0, 0, 0, 0.27) 0px 12px 32px -16px',
            opacity: 1,
        }}>
        <div className="gap-1 flex flex-col">
            <div
                onClick={()=>setLeverageState(true)}
                className="text-12 cursor-pointer text-secondary hover:text-primary hover:bg-layer6 px-2 py-1 rounded">How
                It Works
            </div>
            <div
                className="text-12 cursor-pointer text-secondary hover:text-primary hover:bg-layer6 px-2 py-1 rounded">ROI
                calculator
            </div>
            <div
                className="text-12 cursor-pointer text-secondary hover:text-primary hover:bg-layer6 px-2 py-1 rounded">Price
                Formulation
            </div>
            <div
                className="text-12 cursor-pointer text-secondary hover:text-primary hover:bg-layer6 px-2 py-1 rounded">Limits
            </div>
            <div
                className="text-12 cursor-pointer text-secondary hover:text-primary hover:bg-layer6 px-2 py-1 rounded">Risk
                Warning
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none"
             className="absolute left-full top-1/2 -translate-y-1/2 -translate-x-px">
            <path
                d="M4.58579 6.41421L3.41421 7.58579C1.96116 9.03884 1.23463 9.76537 0.617316 9.50967C-7.63216e-08 9.25397 -1.21234e-07 8.2265 -2.11057e-07 6.17157L-3.13479e-07 3.82843C-4.03303e-07 1.7735 -4.48215e-07 0.746035 0.617316 0.490334C1.23463 0.234632 1.96116 0.961159 3.41421 2.41421L3.41422 2.41421L4.58579 3.58579C5.25245 4.25245 5.58579 4.58579 5.58579 5C5.58579 5.41421 5.25245 5.74755 4.58579 6.41421Z"
                className="fill-layer5 light:fill-layer7"></path>
        </svg>
    </div>
}
export default LeverageInfo
