import {Outlet} from "react-router-dom";
import Footer from "./Footer";

const SystemLayout = ()=>{
    return <div className="flex-col flex-1">
        <Outlet/>
        <Footer/>
    </div>
}
export default SystemLayout
