const SettingPage = ()=> {
    return <main className="flex-1 bg-layer2 p-4">
        <div>
            <div className="px-4 flex-1 overflow-y-auto">
                <div className="p-6 rounded-3 bg-layer2 mb-2.5">
                    <div className="text-16 font-500">security</div>
                    <hr className="my-5 border-thirdly"/>
                    <div className="flex items-center justify-between">
                        <div className="text-16 font-500">Login Password</div>
                        <button
                            className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-9 px-4 py-2 text-12 s768:px-7 shrink-0">Edit
                        </button>
                    </div>
                    <hr className="my-5 border-thirdly"/>
                    <div className="flex items-center justify-between">
                        <div className="text-16 font-500">Google Authenticator</div>
                        <div>
                            <button
                                className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-9 px-4 py-2 text-12 s768:px-7 shrink-0">Bind
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
}
export default SettingPage
