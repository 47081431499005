import Close from "./Close";
import {DialogProps} from "../constant/fields";
import WalletIcon from "./WalletIcon";
import {useRecoilState, useRecoilValue} from "recoil";
import {walletPanelState} from "../store/system";
import {currentWalletState, WalletItem, walletRequestIdState, walletState} from "../store/user";
import {listWallets} from "../api";
import {useEffect, useState} from "react";

const WalletPanel = ({visible, onCancel}: DialogProps) => {
    const state = useRecoilValue(walletPanelState)
    const [hideSmall, setHideSmall] = useState(false)
    const [wallets, setWallets] = useRecoilState(walletState)
    const [viewWallets, setViewWallets] = useState<WalletItem[]>([])
    const [currentWallet, setCurrentWallet] = useRecoilState(currentWalletState)
    const [walletRequestId, setWalletRequestId] = useRecoilState(walletRequestIdState)
    const queryWallets = async () => {
        const {data} = await listWallets()
        if (data && data.length > 0 && !currentWallet) {
            setCurrentWallet(data[0])
        }
        setWallets(data)
        setViewWallets(data)
    }
    const switchSmall = () => {
        setViewWallets(hideSmall?wallets.filter(t => t.balance > 100000):wallets)
    }
    useEffect(() => {
        queryWallets().then()
    }, [walletRequestId]);
    useEffect(() => {
        switchSmall()
    }, [hideSmall]);
    return <div className="fixed inset-0 z-modal bg-black/70 overflow-auto no-scrollbar overscroll-none"
                style={{left: '0px', opacity: 1, display: visible ? 'block' : 'none'}}>
        <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
            <div className="relative mx-5 py-6 bg-layer2 rounded-3 overflow-hidden w-[600px]">
                <div className="px-6 pb-5 text-20 font-500 text-primary capitalize">
                    <div className="flex items-center gap-3" onClick={() => onCancel()}>
                        <WalletIcon/>
                        <div className="capitalize">wallet</div>
                    </div>
                </div>
                <Close onCancel={() => onCancel()}/>
                <div
                    className="px-6 pb-2 max-h-[60vh] overflow-auto overscroll-none no-scrollbar text-secondary text-12 font-500">
                    <div className="select-none">
                        <div className="mb-5">
                            <div className="flex items-center justify-between text-14 text-secondary mb-1">
                                <div className="flex items-center gap-3 cursor-pointer">
                                    <div>Account Value</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                         fill="none">
                                        <g clipPath="url(#clip0_1623_9566)">
                                            <path
                                                d="M9.00005 4.385C12.3251 4.385 15.3531 7.615 16.5096 9C15.3531 10.385 12.3251 13.615 9.00005 13.615C5.67505 13.615 2.64705 10.385 1.49055 9C2.64705 7.615 5.67505 4.385 9.00005 4.385ZM9.00005 3C4.87405 3 1.37805 6.937 0.199052 8.4325C0.070199 8.59358 0 8.79372 0 9C0 9.20628 0.070199 9.40642 0.199052 9.5675C1.37805 11.063 4.87405 15 9.00005 15C13.1261 15 16.6221 11.063 17.8011 9.5675C17.9299 9.40642 18.0001 9.20628 18.0001 9C18.0001 8.79372 17.9299 8.59358 17.8011 8.4325C16.6221 6.937 13.1261 3 9.00005 3ZM9.00005 7.615C9.74555 7.615 10.3501 8.235 10.3501 9C10.3501 9.765 9.74555 10.385 9.00005 10.385C8.25455 10.385 7.65005 9.765 7.65005 9C7.65005 8.235 8.25455 7.615 9.00005 7.615ZM9.00005 6.231C7.90805 6.231 6.92355 6.9055 6.50605 7.9405C6.08805 8.9755 6.31905 10.166 7.09105 10.958C7.4632 11.3438 7.94362 11.6077 8.46888 11.7149C8.99413 11.822 9.53953 11.7673 10.0331 11.558C11.0421 11.13 11.7001 10.12 11.7001 9C11.7001 7.4705 10.4916 6.231 9.00055 6.231H9.00005Z"
                                                fill="currentColor"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1623_9566">
                                                <rect width="18" height="18" fill="currentColor"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="flex items-center gap-3">
                                    <div>Hide Small</div>
                                    <button
                                        onClick={() => {
                                            setHideSmall(!hideSmall)
                                        }}
                                        className={`${hideSmall ? 'bg-success' : 'bg-layer5'} relative border border-input inline-flex h-6 w-11 items-center rounded-full`}
                                        id="headlessui-switch-:r51:" role="switch" type="button"
                                        aria-checked="false" data-headlessui-state=""><span
                                        className={`${hideSmall ? 'bg-white translate-x-6' : 'bg-layer6 translate-x-1'} translate-x-1 inline-block h-4 w-4 transform rounded-full transition`}></span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-24 text-primary font-700">USD 0</div>
                        </div>
                        <div className="relative h-60">
                            {
                                viewWallets?.length <= 0 ?
                                    <div className="abs-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"
                                             viewBox="0 0 24 25"
                                             fill="currentColor" className="size-17 text-secondary mx-auto mb-13">
                                            <path
                                                d="M22.35 17.4805C21.9 17.4805 21.6 17.7805 21.6 18.2305V21.5305H1.8V6.98047H21.6V10.1305C21.6 10.5805 21.9 10.8805 22.35 10.8805C22.8 10.8805 23.1 10.5805 23.1 10.1305V7.13047C23.1 6.23047 22.35 5.48047 21.45 5.48047H20.25C19.8 3.83047 18.3 2.48047 16.5 2.48047H3.9C1.8 2.48047 0 4.28047 0 6.38047V6.98047V7.13047V21.5305C0 22.4305 0.75 23.1805 1.65 23.1805H21.45C22.35 23.1805 23.1 22.4305 23.1 21.5305V18.2305C23.1 17.9305 22.8 17.4805 22.35 17.4805ZM3.9 4.13047H16.5C17.4 4.13047 18.15 4.73047 18.6 5.48047H1.95C2.25 4.73047 3 4.13047 3.9 4.13047Z"
                                                fill="currentColor"></path>
                                            <path
                                                d="M21.15 11.4805H17.1C15.6 11.4805 14.25 12.6805 14.25 14.3305C14.25 15.9805 15.45 17.1805 17.1 17.1805H21.15C22.65 17.1805 24 15.9805 24 14.3305C24 12.6805 22.8 11.4805 21.15 11.4805ZM21.15 15.5305H17.1C16.5 15.5305 15.9 15.0805 15.9 14.3305C15.9 13.7305 16.35 13.1305 17.1 13.1305H21.15C21.75 13.1305 22.35 13.5805 22.35 14.3305C22.35 14.9305 21.9 15.5305 21.15 15.5305Z"
                                                fill="currentColor"></path>
                                            <path
                                                d="M16.5 14.3311C16.5 14.53 16.579 14.7207 16.7197 14.8614C16.8603 15.002 17.0511 15.0811 17.25 15.0811C17.4489 15.0811 17.6397 15.002 17.7803 14.8614C17.921 14.7207 18 14.53 18 14.3311C18 14.1321 17.921 13.9414 17.7803 13.8007C17.6397 13.6601 17.4489 13.5811 17.25 13.5811C17.0511 13.5811 16.8603 13.6601 16.7197 13.8007C16.579 13.9414 16.5 14.1321 16.5 14.3311Z"
                                                fill="currentColor"></path>
                                        </svg>
                                        <div className="text-16 font-500">Your wallet is currently empty.</div>
                                    </div> :
                                    <div className="rounded-3 bg-layer4 px-4 py-3">
                                        <div>
                                            <div className="text-14 font-400 text-secondary flex justify-between">
                                                <div>Your Currencies</div>
                                                <div>Amount/Value</div>
                                            </div>
                                            <hr className="my-3 border-input"/>
                                        </div>
                                        <div className="h-40 overflow-y-auto">
                                            {
                                                viewWallets?.map((item, index) => (<div key={`${index}-wallet`}
                                                                                       className="leading-5 py-2 px-3 flex items-center justify-between rounded-2 hover:bg-layer5">
                                                        <div className="flex items-center gap-3"><span
                                                            className="block overflow-hidden size-8 lazy-load-image-background  lazy-load-image-loaded"><img
                                                            src={item.currency?.icon}
                                                            className="w-full h-full object-cover"/></span>
                                                            <div>
                                                                <div
                                                                    className="text-16 text-primary font-600">{item.currency.coin}</div>
                                                                <div
                                                                    className="text-12 text-secondary">{item.currency.name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="text-right">
                                                            <div
                                                                className="text-16 text-primary font-600">{item.balance}</div>
                                                            <div className="text-12 text-secondary">USD 0</div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="flex gap-5 mt-8">
                            <button
                                className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand h-12 px-4 py-3.5 capitalize text-16 font-700 flex-1">deposit
                            </button>
                            <button
                                className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand h-12 px-4 py-3.5 capitalize text-16 font-700 flex-1">withdraw
                            </button>
                        </div>
                        <button
                            className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 h-12 px-4 py-3.5 capitalize text-16 font-700 text-secondary w-full mt-5 bg-layer4">tip
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default WalletPanel
