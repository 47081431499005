import React, {useEffect} from 'react';
import './static/stx-chart.css'
import './App.css';
import './static/font.css';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Login from "./Login";
import 'react-toastify/dist/ReactToastify.css';
import EventEmitter from "./EventEmitter";
import Layout from "./page/Layout";
import LeverageContractPlace from "./components/LeverageContractPlace";
import TimeBet from "./components/TimeBet";
import UpDownContract from "./page/UpDownContract";
import KlineLayout from "./components/KlineLayout";
import Profile from "./components/Profile";
import SettingPage from "./components/SettingPage";
import Transaction from "./components/Transaction";
import './util/i18n'
import {useTranslation} from "react-i18next";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {LangListItem, langsState, selectLangState} from "./store/system";
import {queryLangs, setLang, updateUserInfo} from "./api";
import TradeLayout from "./components/TradeLayout";
import SystemLayout from "./components/SystemLayout";
import {useAfterUserInfo, useRefreshUser} from "./hooks";
import {refreshUserState, userInfoState} from "./store/user";


function App() {
    const navigate = useNavigate()

    const [userInfo, setUserInfo] = useRecoilState(userInfoState)
    const {i18n} = useTranslation()
    const setLangs = useSetRecoilState(langsState)
    const [selectLang, setSelectLang] = useRecoilState(selectLangState)

    const refreshUser = useRecoilValue(refreshUserState)
    useEffect(() => {
        refreshUserInfo().then()
    }, [refreshUser]);

    const refreshUserInfo = async () => {
        const result: any = await updateUserInfo()
        if (result) {
            const {data,code} = result
            if (code === 200) {
                data.login = true
                setUserInfo(data)
            }
        }
    }
    useEffect(() => {
        refreshUserInfo().then()
    }, []);

    const initLangs = async () => {
        let data: any = await queryLangs()
        if (data) {
            data = data.data
            const langOutId = userInfo.langOutId
            if (langOutId) {
                const item = data.filter((it: LangListItem) => it.outId === langOutId)[0]
                setSelectLang({...item, initFlag: false})
            } else {
                if (data && data.length > 0) {
                    const item = data[0]
                    setSelectLang({...item, initFlag: false})
                }
            }
            setLangs(data)
        }
    }

    const changeLangLang = (langId: string, initLangFlag: boolean = false) => {
        i18n.changeLanguage(langId)
        if (initLangFlag) {
            setLang(langId).then(() => {
                refresh()
            })
        }
    }

    const {refresh} = useRefreshUser()
    useEffect(() => {
        if (selectLang && selectLang.outId) {
            changeLangLang(selectLang.outId, selectLang.initFlag)
        }
    }, [selectLang]);

    useAfterUserInfo(() => {
        initLangs().then()
    }, []);

    EventEmitter.on("redirect", (router: string) => {
        navigate(router)
    })
    return (
        <Routes>
            <Route>
                <Route path="/" element={<Navigate to="/trade/time-contract" replace/>}/>
                <Route path="/" element={<Layout/>}>
                    <Route path="/trade" element={<TradeLayout/>}>
                        <Route path="" element={<KlineLayout/>}>
                            <Route path="/trade/leverage-contract" element={<LeverageContractPlace/>}/>
                            <Route path="/trade/time-contract" element={<TimeBet/>}/>
                        </Route>
                        <Route path="/trade/updown-contract" element={<UpDownContract/>}/>
                    </Route>
                    <Route path="/system" element={<SystemLayout/>}>
                        <Route path="/system/profile" element={<Profile/>}/>
                        <Route path="/system/transaction" element={<Transaction/>}/>
                        <Route path="/system/bet-history" element={<Transaction/>}/>
                        <Route path="/system/other" element={<Transaction/>}/>
                        <Route path="/system/setting" element={<SettingPage/>}/>
                    </Route>
                </Route>
                <Route path="/login" element={<Login/>}/>
            </Route>
        </Routes>
    );
}

export default App;
