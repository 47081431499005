import React from "react";
import Kline from "./Kline";
import {Outlet} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

const KlineLayout = ()=>{
    const isHiddenBet = useMediaQuery({query: '(max-width: 1024px)'})
    return <div className={`flex ${isHiddenBet?'flex-col gap-3':''}`}>
        <Kline/>
        <Outlet/>
    </div>
}

export default KlineLayout
