import {useRecoilState, useRecoilValue} from "recoil";
import {currentWalletState, walletRequestIdState} from "../store/user";
import {gameState} from "../store/game";
import {joinGame} from "../api";
import EventEmitter from "../EventEmitter";
import {forwardRef, useImperativeHandle} from "react";
import {error} from "../util/toast";

export interface SendOrderInstance{
    join:Function
}
const SendOrder =forwardRef(({amount,params,afterSend}:{amount:number,params:any,afterSend?:()=>void|null},ref)=>{
    const currentWallet = useRecoilValue(currentWalletState)
    const [walletRequestId,setWalletRequestId] = useRecoilState(walletRequestIdState)
    const game = useRecoilValue(gameState)

    useImperativeHandle(ref,()=>({
        join:async (temp:any=null)=>{
            if(currentWallet?.currency.outId==null || game.outId==null || amount<=0){
                error("param error")
                return
            }
            const {code}:any = await joinGame(currentWallet?.currency.outId,game.outId,amount, {...params,...temp})
            if(code===200){
                error("Bet Success")
                EventEmitter.emit("loadOrder")
                setWalletRequestId(walletRequestId+1)
                afterSend?.()
            }
        }
    }))
    return <></>
})
export default SendOrder
