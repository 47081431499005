import USD from '../static/USD.png'
import {mapToSymbol} from "../constant/fields";
import NumberUpIcon from "./NumberUpIcon";
export const PairShow = ({record}: { record: any }) => {
    return <div className="flex items-center gap-1">
        <div className="flex items-center"><span
            className="block overflow-hidden size-5 rounded-full -mr-2.5 z-10 lazy-load-image-background  lazy-load-image-loaded">
            <img alt=""
            src={record.symbolIcon}
            className="w-full h-full object-cover"/></span><span
            className="block overflow-hidden size-5 rounded-full lazy-load-image-background  lazy-load-image-loaded">
            <img alt=""
            src={USD}
            className="w-full h-full object-cover"/></span></div>
        <div className="text-13 text-primary w-18 truncate">{mapToSymbol(record.symbol)}</div>
        <NumberUpIcon down={record.tradeType===0}/>
    </div>
}
