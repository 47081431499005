import React from "react";
import {userInfoState} from "../store/user";
import avatar from '../static/Avatar-5.png'
import {useRecoilValue} from "recoil";
import {CopyToClipboard} from "react-copy-to-clipboard"
import {useNavigate} from "react-router-dom";
import {success} from "../util/toast";
import {useTranslation} from "react-i18next";


const ProfilePanel = (props:{onClick:Function}) => {
    const userInfo = useRecoilValue(userInfoState)
    const {t} = useTranslation()
    const outIdDesen = userInfo?.outId?.substring(0, 8) + "***"
    const navigate = useNavigate()
    return <div className="flex flex-col">
        <div className="flex items-center gap-3"><span
            className="block overflow-hidden size-10 rounded-3 shrink-0 lazy-load-image-background  lazy-load-image-loaded"><img
            src={userInfo?.icon || avatar}
            className="w-full h-full object-cover"/></span>
            <div className="flex-1 truncate">
                <div className="text-14 text-primary truncate">{userInfo?.username}</div>
                <CopyToClipboard text={userInfo?.outId || ""} onCopy={()=>success('copy success')}>
                    <div className="text-12 cursor-pointer">UID:
                        <text className="overflow-hidden">{outIdDesen}</text>
                    </div>
                </CopyToClipboard>
            </div>
        </div>
        <hr className="my-2 border-thirdly"/>
        <div data-nav="/my-profile"
             className="flex items-center h-10 gap-3 px-3 py-2 text-primary hover:bg-layer5 rounded-2 hover:text-primary transition-colors cursor-pointer capitalize">
            <div className="text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M9.84761 1.65039C7.60252 1.65039 5.77585 3.50412 5.77585 5.78176C5.77585 8.05941 7.60252 9.91313 9.84761 9.91313C12.0927 9.91313 13.9194 8.0598 13.9194 5.78176C13.9194 3.50333 12.0927 1.65039 9.84761 1.65039ZM11.5253 11.4223H8.47428C5.63938 11.4223 3.3335 13.7607 3.3335 16.6352V16.9446C3.3335 18.4478 5.60251 18.4478 8.47428 18.4478H11.5253C14.2845 18.4478 16.6668 18.4478 16.6668 16.9446V16.6352C16.6668 13.7611 14.3606 11.4223 11.5253 11.4223Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div className="text-14 font-500" onClick={() => {
                navigate("/system/profile")
                props.onClick()
            }}>{t('my_profile')}
            </div>
        </div>
        <div data-nav="/wallet/transaction"
             onClick={()=>{
                 navigate("/transaction")
                 props.onClick()
             }}
             className="flex items-center h-10 gap-3 px-3 py-2 text-primary hover:bg-layer5 rounded-2 hover:text-primary transition-colors cursor-pointer capitalize">
            <div className="text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M14.046 10.5087H5.86207C5.49425 10.5087 5.12644 10.2312 5.12644 9.76879C5.12644 9.39884 5.49425 9.0289 5.86207 9.0289H14.046C14.4138 9.0289 14.7816 9.30636 14.7816 9.6763C14.7816 10.0462 14.5057 10.5087 14.046 10.5087ZM14.046 14.0231H5.86207C5.49425 14.0231 5.12644 13.7457 5.12644 13.2832C5.12644 12.9133 5.49425 12.5434 5.86207 12.5434H14.046C14.4138 12.5434 14.7816 12.8208 14.7816 13.2832C14.7816 13.7457 14.5057 14.0231 14.046 14.0231ZM5.12644 6.34682C5.12644 5.97688 5.49425 5.60694 5.86207 5.60694H11.0115C11.3793 5.60694 11.7471 5.88439 11.7471 6.25434C11.7471 6.62428 11.4713 6.99422 11.1034 6.99422H5.86207C5.49425 7.0867 5.12644 6.71676 5.12644 6.34682ZM16.5287 2H3.47126C2.64368 2 2 2.6474 2 3.38728V16.6127C2 17.4451 2.64368 18 3.47126 18H16.5287C17.3563 18 18 17.3526 18 16.6127V3.38728C17.908 2.6474 17.2644 2 16.5287 2Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div className="text-14 font-500">{t('transaction')}</div>
        </div>
        <div
            data-nav="/settings"
            onClick={()=>{
                navigate("/system/setting")
                props.onClick()
            }}
             className="flex items-center h-10 gap-3 px-3 py-2 text-primary hover:bg-layer5 rounded-2 hover:text-primary transition-colors cursor-pointer capitalize">
            <div className="text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M11.3195 2.02346C13.0689 2.40497 14.6171 3.32522 15.7815 4.60165C15.3926 5.22663 15.3521 6.04018 15.7459 6.72221C16.14 7.40491 16.8659 7.77672 17.6023 7.75167C17.8607 8.56429 18 9.43002 18 10.3283C18 11.2265 17.8607 12.0922 17.6024 12.905C16.8659 12.8798 16.14 13.2516 15.7459 13.9343C15.3521 14.6163 15.3925 15.4299 15.7814 16.0549C14.6171 17.3313 13.0689 18.2516 11.3195 18.6331L11.3195 18.6329C10.9729 17.9828 10.2881 17.5404 9.5 17.5404C8.71191 17.5404 8.02714 17.9828 7.68052 18.6329L7.6805 18.6331C5.93105 18.2516 4.38288 17.3313 3.21854 16.0549C3.60744 15.4299 3.64787 14.6163 3.25411 13.9343C2.85996 13.2516 2.13413 12.8798 1.39767 12.9049C1.13933 12.0923 1 11.2265 1 10.3283C1 9.43 1.13933 8.56429 1.39756 7.75156C2.13413 7.77672 2.85996 7.40491 3.25411 6.72221C3.64788 6.04018 3.60746 5.22661 3.21859 4.60161C4.38288 3.32522 5.93105 2.40497 7.6805 2.02344L7.68054 2.0237C8.02716 2.67372 8.71191 3.11614 9.5 3.11614C10.2853 3.11614 10.9679 2.67691 11.3157 2.03073L11.3195 2.02346ZM9.5 8.26766C8.36196 8.26766 7.43939 9.19022 7.43939 10.3283C7.43939 11.4663 8.36196 12.3889 9.5 12.3889C10.638 12.3889 11.5606 11.4663 11.5606 10.3283C11.5606 9.19022 10.638 8.26766 9.5 8.26766Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div className="text-14 font-500">{t('settings')}</div>
        </div>
        <div data-nav="/support"
             className="flex items-center h-10 gap-3 px-3 py-2 text-primary hover:bg-layer5 rounded-2 hover:text-primary transition-colors cursor-pointer capitalize">
            <div className="text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M16.4 4H3.6C2.716 4 2.00801 4.716 2.00801 5.6L2 15.2C2 16.084 2.716 16.8 3.6 16.8H16.4C17.284 16.8 18 16.084 18 15.2V5.6C18 4.716 17.284 4 16.4 4ZM16.4 7.20001L9.99999 11.2L3.59998 7.20001V5.60002L9.99999 9.60001L16.4 5.60002V7.20001Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div className="text-14 font-500">{t('connect')}</div>
        </div>
        <div data-nav=""
             className="flex items-center h-10 gap-3 px-3 py-2 text-primary hover:bg-layer5 rounded-2 hover:text-primary transition-colors cursor-pointer capitalize">
            <div className="text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M17.6271 9.20609L12.1116 3.68747C11.7684 3.34535 11.3583 3.24335 10.9344 3.40166C10.4998 3.56528 10.2512 3.95416 10.2501 4.47053V6.96316L6.07238 6.96422C5.42213 6.96634 4.96845 7.4179 4.96632 8.0639C4.9642 9.36546 4.9642 10.666 4.96632 11.9665C4.96845 12.5923 5.42745 13.0502 6.05857 13.0566L10.2501 13.0576L10.2491 13.54C10.2469 14.2168 10.2448 14.8915 10.2586 15.5673C10.2629 15.8318 10.367 16.1198 10.5285 16.3174C10.7261 16.5607 10.9971 16.6946 11.2914 16.6946C11.5825 16.6946 11.8757 16.5618 12.1169 16.3216C13.9572 14.4835 15.7953 12.6443 17.6334 10.8041C18.1243 10.3143 18.1222 9.70121 17.6271 9.20609Z"
                        fill="currentColor"></path>
                    <path
                        d="M7.43455 14.8883H7.43348L6.22861 14.8904L4.50417 14.8894C3.63292 14.8894 3.13249 14.3889 3.13249 13.5166V6.50093C3.13249 5.63075 3.63292 5.13137 4.5063 5.13137L5.64848 5.13031L6.9033 5.13137L7.60136 5.13031C8.10073 5.13031 8.37379 4.87956 8.41311 4.38656C8.42798 4.20275 8.42373 4.01575 8.41736 3.8075C8.40461 3.28794 8.11348 3.00106 7.60029 3H6.33273C5.6538 3 4.97486 3 4.29699 3.00425C4.08874 3.00531 3.87517 3.02869 3.66161 3.07437C2.13055 3.40056 1.0128 4.77331 1.0043 6.33837C1.00005 7.18199 1.00112 8.02668 1.00218 8.86924L1.00324 9.9923L1.00112 11.1706C1.00005 12.0174 0.997928 12.8632 1.00537 13.711C1.02024 15.5003 2.47905 16.9825 4.25767 17.0154C4.69542 17.0239 5.13424 17.026 5.57199 17.026L7.65342 17.0218C8.06461 17.0218 8.35254 16.7721 8.40354 16.3705C8.42161 16.2239 8.42373 16.074 8.42479 15.9242V15.8499C8.42692 15.6289 8.42904 15.326 8.21654 15.1114C8.01148 14.9064 7.71611 14.8883 7.43455 14.8883Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div className="text-14 font-500" onClick={()=>{
                localStorage.removeItem("token")
                navigate("/login")
            }}>{t('log_out')}</div>
        </div>
    </div>
}
export default ProfilePanel
