import React from "react";
import {MenuIconProps} from "../constant/fields";

const TimeContactLogo = (props:MenuIconProps)=> {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                fill="none"
                className={`text-secondary group-hover:text-primary shrink-0 size-5 ${props.active ? '!text-brand' : ''}`}>
        <path
            d="M16.9061 18.162L3.30352 18.1624C2.97809 18.162 2.6798 17.827 2.67899 17.463V13.1454C2.67899 12.4441 2.99357 12.0771 3.32385 12.4287L5.16339 14.0499C5.41049 14.2868 5.78195 14.6092 5.98868 14.3282L8.09706 11.4618C8.30383 11.1807 8.67679 10.784 8.92591 11.0185L10.7403 12.7872C10.9893 13.0215 11.5048 13.4305 11.7074 13.1454L16.2218 7.05383C16.8315 6.27452 17.5115 6.45734 17.5115 7.41214V17.463C17.5115 17.827 17.2314 18.1624 16.906 18.162H16.9061ZM11.6121 10.6805C11.445 10.9203 11.1982 11.0731 10.9284 11.105C10.6579 11.1409 10.3886 11.0439 10.1811 10.8486L8.13463 8.91872L6.07915 11.9565C5.91491 12.1986 5.67033 12.3563 5.40072 12.3922C5.36162 12.3973 5.32226 12.3999 5.28287 12.4C5.05374 12.4 4.82988 12.3112 4.65055 12.1466L2.85674 10.5033C2.42967 10.1123 2.36672 9.40726 2.71597 8.92894C3.06506 8.45014 3.69453 8.38016 4.12151 8.77138L5.1131 9.6797L7.17726 6.62846C7.3429 6.38363 7.59043 6.2262 7.8625 6.19203C8.13651 6.15859 8.40746 6.25111 8.61695 6.44778L10.6746 8.38856L13.3333 4.65488L12.5923 3.96586C12.0665 3.47703 12.1834 2.94747 12.8518 2.78914L16.7047 1.87666C17.3731 1.71832 17.5798 2.08557 17.1642 2.69303L11.6121 10.6804V10.6805Z"
            fill="currentColor"></path>
    </svg>
}
export default TimeContactLogo
