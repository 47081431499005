import {TimeUint, timeUnitState} from "../store/game";
import {useSetRecoilState} from "recoil";

const TimeUnitSelect = ({onClose,configs}:{onClose:Function,configs:TimeUint[]}) => {
    const setTimeUnit = useSetRecoilState(timeUnitState)
    return <div className="fixed inset-0 z-modal" style={{left:' 0px', opacity: 1}}>
        <div className="fixed inset-0 bg-black/70"></div>
        <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
            <div className="relative mx-5 py-6 bg-layer2 rounded-3 overflow-hidden w-[400px]">
                <div className="px-6 pb-5 text-20 font-500 text-primary capitalize">Choose Pool</div>
                <svg
                    onClick={()=>onClose()}
                    className="absolute z-10 w-6 h-6 right-4 rounded-full text-secondary hover:text-primary transition-colors cursor-pointer top-6"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                        fill="currentColor"></path>
                </svg>
                <div
                    className="px-6 pb-2 max-h-[60vh] overflow-auto overscroll-none no-scrollbar text-secondary text-12 font-500 space-y-4">
                    {configs.map((item,index)=><div
                        className="p-6 rounded-4 bg-layer4" key={`config-${index}`}
                    >
                        <div
                            className="inline-flex items-center gap-2 px-2 py-1.5 mb-5 rounded-6 text-16 font-600 bg-layer4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none"
                                 className="w-4 h-4 s1366:w-6 s1366:h-6 s1440:w-8 s1440:h-8 text-[#F7931A] bg-white rounded-full">
                                <g clipPath="url(#clip0_5607_132248)">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M12 24C5.37225 24 0 18.6278 0 12C0 5.37225 5.37225 0 12 0C18.6278 0 24 5.37225 24 12C24 18.6278 18.6278 24 12 24ZM17.3918 10.515C17.6272 8.943 16.4295 8.09775 14.793 7.53375L15.324 5.40375L14.028 5.08125L13.5105 7.155C13.17 7.0695 12.8205 6.99 12.4718 6.9105L12.993 4.82325L11.697 4.5L11.166 6.62925C10.884 6.56475 10.6065 6.50175 10.338 6.43425L10.3395 6.4275L8.5515 5.98125L8.2065 7.36575C8.2065 7.36575 9.16875 7.58625 9.1485 7.59975C9.6735 7.731 9.768 8.07825 9.75225 8.35425L9.14775 10.7805C9.18375 10.7895 9.23025 10.803 9.28275 10.8232L9.1455 10.7895L8.298 14.1885C8.2335 14.3475 8.07075 14.5867 7.70325 14.496C7.71675 14.5147 6.76125 14.2612 6.76125 14.2612L6.11775 15.7448L7.80525 16.1655C8.11875 16.2443 8.42625 16.3267 8.7285 16.404L8.19225 18.558L9.4875 18.8805L10.0185 16.7505C10.3725 16.8457 10.716 16.9342 11.052 17.0182L10.5225 19.1392L11.8185 19.4618L12.3547 17.3123C14.5658 17.7308 16.2277 17.562 16.9275 15.5625C17.4915 13.953 16.8997 13.0238 15.7365 12.4185C16.584 12.2235 17.2215 11.6663 17.3918 10.515ZM14.4293 14.6685C14.0295 16.2788 11.3182 15.408 10.4392 15.1898L11.1518 12.336C12.0308 12.5557 14.8485 12.99 14.4293 14.6685ZM14.8305 10.4918C14.4653 11.9565 12.2092 11.2118 11.478 11.0295L12.123 8.442C12.8542 8.62425 15.2115 8.964 14.8305 10.4918Z"
                                          fill="currentColor"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_5607_132248">
                                        <rect width="24" height="24" fill="transparent"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>{item.symbol}</span><span>{item.unit}</span></div>
                        <div className="flex items-center mb-7 divide-x divide-primary text-14 font-400 text-secondary">
                            <div className="flex-1">
                                <div className="mb-1">MIN TRADE</div>
                                <div className="text-primary text-18 font-600">{item.minTrade} USDT</div>
                            </div>
                            <div className="flex-1 pl-10">
                                <div className="mb-1">POOL TIME</div>
                                <div className="text-primary text-18 font-600">{item.fullTime}/{item.unit} SEC</div>
                            </div>
                        </div>
                        <button
                            onClick={()=> {
                                setTimeUnit(item);
                                onClose()
                            }}
                            className="items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand h-12 px-4 py-3.5 text-14 flex w-full font-700">Play
                            {item.symbol} {item.unit} Sec
                        </button>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
}

export default TimeUnitSelect
